import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Stack, Tab, Tabs, useTheme } from '@mui/material';

import store from 'store';
import { programSelectors, programThunks } from 'store/ducks/program';

import { TabPanelsWrapper } from 'views/common/StyledComponents';
import AssignmentsList from 'views/common/AssignmentsList';
import LessonsList from 'views/common/LessonsList';
import ProgramModules from './programModules/ProgramModules';

import { TabPanel, a11yProps } from 'utils/tabsFunc';
import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';

const ProgramStructure = ({ id }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [tabsValue, setTabsValue] = useState(0);
  const programLessons = useSelector(programSelectors.getProgramLessons());
  const programLessonsLoading = useSelector(programSelectors.programLessonsLoading()) && !programLessons;

  const programAssignments = useSelector(programSelectors.getProgramAssignments());
  const programAssignmentsLoading = useSelector(programSelectors.programAssignmentsLoading()) && !programAssignments;

  const program = useSelector(programSelectors.getProgram(id));
  const programLoading = useSelector(programSelectors.programLoading()) && !program;

  const lessonPermission = hasPermission(PERMISSIONS.lesson.read);
  const assignmentPermission = hasPermission(PERMISSIONS.assignment.read);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };
  useEffect(() => {
    lessonPermission && store.dispatch(programThunks.fetchProgramLessons(id));
  }, [id, lessonPermission]);

  useEffect(() => {
    assignmentPermission && store.dispatch(programThunks.fetchProgramAssignments(id));
  }, [id, assignmentPermission]);

  return (
    <Stack
      sx={{
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
        <Tab
          sx={{ width: `calc((100% - ${theme.spacing(4)}) / 3)` }}
          label={t('base.labels.modules')}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ width: `calc((100% - ${theme.spacing(4)}) / 3)` }}
          label={t('types.lesson.namePlural')}
          {...a11yProps(1)}
          disabled={!lessonPermission}
        />
        <Tab
          sx={{ width: `calc((100% - ${theme.spacing(4)}) / 3)` }}
          label={t('types.assignment.namePlural')}
          {...a11yProps(2)}
          disabled={!assignmentPermission}
        />
      </Tabs>

      <TabPanelsWrapper>
        <TabPanel value={tabsValue} index={0}>
          <ProgramModules
            list={program.modules}
            totalElements={program.modules.length}
            isLoading={programLoading}
            programId={program.id}
            hideFooter
            isNested
          />
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <LessonsList
            list={programLessons}
            totalElements={programLessons.length}
            columnVisibilityModel={{
              program: false,
            }}
            isLoading={programLessonsLoading}
            hideFooter
            isNested
          />
        </TabPanel>
        <TabPanel value={tabsValue} index={2}>
          <AssignmentsList
            list={programAssignments}
            totalElements={programAssignments.length}
            isLoading={programAssignmentsLoading}
            columnVisibilityModel={{
              program: false,
            }}
            hideFooter
            isNested
          />
        </TabPanel>
      </TabPanelsWrapper>
    </Stack>
  );
};

export default memo(ProgramStructure);
