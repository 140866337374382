const getLessons = () => (state) => state.lesson.list.data;
const lessonsLoading = () => (state) => state.lesson.list.isLoading;
const getLessonsError = () => (state) => state.lesson.list.error;
const getLesson = () => (state) => state.lesson.item.data;
const lessonLoading = () => (state) => state.lesson.item.isLoading;
const getLessonError = () => (state) => state.lesson.item.error;
const getLessonAssignments = () => (state) => state.lesson.lessonAssignments.data;
const lessonAssignmentsLoading = () => (state) => state.lesson.lessonAssignments.isLoading;
const getLessonAssignmentsError = () => (state) => state.lesson.lessonAssignments.error;

export default {
  getLessons,
  lessonsLoading,
  getLessonsError,
  getLesson,
  lessonLoading,
  getLessonError,
  getLessonAssignments,
  lessonAssignmentsLoading,
  getLessonAssignmentsError,
};
