import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import store from 'store';
import { contractSelectors, contractThunks } from 'store/ducks/contract';

import ContractsList from '../../common/ContractsList';
import HeaderList from 'views/common/HeaderList';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { PageWrapper } from 'views/common/StyledComponents';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const ContractsPage = () => {
  const { t } = useTranslation();
  const contracts = useSelector(contractSelectors.getContracts());
  const contractsLoading = useSelector(contractSelectors.contractsLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(contractThunks.fetchContracts(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <HeaderList
          title={t('types.contract.namePlural')}
          to="/contracts/new"
          buttonTitle={'+ ' + t('base.buttons.createContract')}
          isSearchbarShow
          hasPermission={hasPermission(PERMISSIONS.contract.create)}
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <ContractsList
          list={contracts.content}
          isLoading={contractsLoading}
          totalElements={contracts.totalElements}
          totalPages={contracts.totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(ContractsPage);
