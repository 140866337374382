import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceContract } from './slice';

const fetchContracts = createAsyncThunk('contract/fetchContracts', async (query) => {
  return api.get(`/contracts${query}`);
});
const fetchContract = createAsyncThunk('contract/fetchContract', async (id) => {
  return api.get(`/contracts/${id}`);
});
const createContract = createAsyncThunk('contract/createContract', (data) => {
  return api.post('/contracts', data);
});

const updateContract = createAsyncThunk('contract/updateContract', (data, { dispatch }) => {
  return api.put(`/contracts/${data.id}`, data).then((res) => {
    dispatch(replaceContract({ data: res }));
    return res;
  });
});

const fetchContractInvoices = createAsyncThunk('contract/fetchContractInvoices', async (id) => {
  return api.get(`/contracts/${id}/invoices`);
});

const fetchContractTasks = createAsyncThunk('contract/fetchContractTasks', async (id) => {
  return api.get(`/contracts/${id}/tasks`);
});

export default {
  fetchContracts,
  createContract,
  fetchContract,
  updateContract,
  fetchContractInvoices,
  fetchContractTasks,
};
