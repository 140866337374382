import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import LessonForm from './components/LessonForm';
import Error from 'views/common/Error';

const NewLesson = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
      <LessonForm navigate={navigate} t={t} />;
    </ErrorBoundary>
  );
};

export default memo(NewLesson);
