import { Paragraph, AlignmentType, TextRun } from 'docx';

export const createPrincipalRegulations = (legalEntity) => {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [
      new TextRun({
        text: `9.3. Замовник погоджується з тим, що надані ним персональні дані, які зазначаються в Договорі є добровільним виявленням Замовника і Замовник надає дозвіл ${legalEntity?.name} (реєстраційний номер облікової картки платника податків та інших обов’язкових платежів ${legalEntity?.idCode}), на збір, обробку та використання його персональних даних, необхідних для ідентифікації останнього та такі персональні дані можуть бути використані з метою повідомлення Замовника про зміни, які можуть відбуватися в ході виконання цього Договору, `,
      }),
      new TextRun({
        text: `а також з метою забезпечення бухгалтерського обліку та реалізації інших питань господарської діяльності ${legalEntity?.name} (реєстраційний номер облікової картки платника податків та інших обов’язкових платежів ${legalEntity?.idCode})
          `,
      }),
      new TextRun({
        text: `Також Замовник надає згоду ${legalEntity?.address} (реєстраційний номер облікової картки платника податків та інших обов’язкових платежів ${legalEntity?.idCode}) на отримання повідомлень про різноманітні послуги, які можуть бути запропоновані Замовнику, в тому числі, але не виключно, про курси, тренінги.`,
      }),
    ],
  });
};
