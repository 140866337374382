import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';

const contactSlice = createSlice({
  name: 'contactSlice',
  initialState: {
    list: loadDataPaginatedState(),
  },
  reducers: {},
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchContacts, 'list');
  },
});

export const {} = contactSlice.actions;
export default contactSlice.reducer;
