import { memo, useEffect, useState } from 'react';
import { Form, withFormik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Stack, TextField } from '@mui/material';

import store from 'store';
import { contractActions, contractSelectors, contractThunks } from 'store/ducks/contract';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { invoiceThunks } from 'store/ducks/invoice';

import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FormikTextField from 'views/form/FormikTextField';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from 'views/common/StyledComponents';
import HeaderCard from 'views/common/HeaderCard';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';

import { getActiveContracts, getFullName, getShortName } from 'utils/dataTable';
import { validationInvoiceForm } from 'utils/schema';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const InvoiceForm = ({ invoice, modalOpen, openModal, closeModal, isSubmitting, values, cloneBtn, handleClone }) => {
  const { t } = useTranslation();

  const enums = useSelector(dictionarySelectors.getEnums());
  const contracts = useSelector(contractSelectors.getContracts());
  const contractsLoading = useSelector(contractSelectors.contractLoading()) && !contracts;

  const [paidInvoice, setPaidInvoice] = useState(null);

  const permission = invoice ? hasPermission(PERMISSIONS.invoice.update) : hasPermission(PERMISSIONS.invoice.create);

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setSearchValue] = useAutocompleteSearch(invoice, values?.program?.name, contractThunks.fetchContracts);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.contract, contractSelectors.getContracts(), contractActions.setContracts);

  /** --- Getting responsible manager from contract --- */
  const getResponsibleManager = () => {
    const contract = contracts.content.find((option) => values?.contract?.id === option.id);
    const responsible = contract?.responsible;
    return getShortName(responsible);
  };

  /** --- Getting contractor from contract --- */
  const getContractor = () => {
    const contract = contracts.content.find((option) => values?.contract?.id === option.id);
    const contractor = contract?.contractor;
    return getFullName(contractor);
  };

  useEffect(() => {
    values.paymentDate
      ? setPaidInvoice({
          label: 'Paid',
          name: 'PAID',
          type: 'InvoiceStatus',
        })
      : setPaidInvoice({
          label: 'Active',
          name: 'ACTIVE',
          type: 'InvoiceStatus',
        });
  }, [values.paymentDate]);

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="invoiceForm">
          <HeaderCard
            title={invoice ? t('types.invoice.name') : t('types.invoice.new')}
            data={invoice}
            isSubmitting={isSubmitting}
            formId="invoiceForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
            cloneBtn={cloneBtn}
            handleClone={handleClone}
          />
          <Stack height="calc(100% - 60px)" gap={1} sx={{ overflowY: 'auto' }}>
            <FormWrapper>
              <FormColumnWrapper sx={{ width: '50%' }}>
                <FormColumnTitle>{t('types.invoice.invoiceInfo')}</FormColumnTitle>
                <FormikTextField
                  name="number"
                  label={t('base.labels.invoiceNumber')}
                  placeholder={t('base.placeholders.invoiceNumber')}
                />
                <FormikAutocomplete
                  name="status"
                  label={t('base.labels.status')}
                  options={enums.invoiceStatuses}
                  defaultValue={paidInvoice}
                />
                <FormikAutocomplete
                  name="contract"
                  label={t('base.labels.contract')}
                  onInputChange={async (event, value) => {
                    setSearchValue(value);
                  }}
                  /** --- Checking if it is existing invoice or a new one. Depending on this condition we filter the options - Active contracts or all contracts --- */
                  options={invoice ? contracts.content : getActiveContracts(contracts.content)}
                  loading={contractsLoading}
                  getCustomLabel={(option) => option.number.toString()}
                  placeholder={t('base.placeholders.typeForSearch')}
                />
                <TextField
                  name="contractor"
                  label={t('base.labels.contractor')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getContractor()}
                />
                <TextField
                  name="responsible"
                  label={t('base.labels.responsible')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getResponsibleManager()}
                />
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: '50%' }}>
                <FormColumnTitle>{t('types.invoice.paymentInfo')}</FormColumnTitle>
                <FormikAutocomplete
                  name="paymentMethod"
                  label={t('base.labels.paymentMethod')}
                  options={enums.paymentMethods}
                />
                <FormikDatePicker name="dueDate" label={t('base.labels.dueDate')} />
                <FormikTextField
                  name="amountDue"
                  label={t('base.labels.amountDue')}
                  placeholder={t('base.placeholders.amountDue')}
                />
                <FormikDatePicker name="paymentDate" label={t('base.labels.paymentDate')} />
                <FormikTextField
                  name="amountPaid"
                  label={t('base.labels.amountPaid')}
                  placeholder={t('base.placeholders.amountPaid')}
                />
              </FormColumnWrapper>
            </FormWrapper>
            <FormColumnWrapper sx={{ width: '100%' }}>
              <FormikTextField
                name="comment"
                label={t('base.labels.comment')}
                placeholder={t('base.placeholders.comment')}
                multiline
                rows={2}
              />
            </FormColumnWrapper>
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};
export default memo(
  withFormik({
    mapPropsToValues: ({ invoice = {}, cloneData }) => ({
      ...invoice,
      id: invoice?.id,
      number: invoice?.number || '',
      status: invoice?.status || null,
      contract: invoice?.contract || cloneData?.contract || null,
      paymentMethod: invoice?.paymentMethod || cloneData?.paymentMethod || null,
      dueDate: invoice?.dueDate || null,
      amountDue: invoice?.amountDue || cloneData?.amountDue || '',
      paymentDate: invoice?.paymentDate || null,
      amountPaid: invoice?.amountPaid || '',
      comment: invoice?.comment || '',
    }),
    validationSchema: validationInvoiceForm,

    handleSubmit: async (values, { props, setSubmitting }) => {
      const res = props.invoice
        ? await store.dispatch(invoiceThunks.updateInvoice(values))
        : await store.dispatch(invoiceThunks.createInvoice(values));

      if (!res.error) {
        toast.success(
          props.invoice
            ? props.t('messages.success.toast.updateInvoice')
            : props.t('messages.success.toast.createInvoice')
        );
        setSubmitting(false);

        if (props.invoice) {
          props.closeModal();
        } else props.navigate(`/invoices/${res.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(InvoiceForm)
);
