import { Autocomplete, Chip, IconButton, Link, TextField, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { memo, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CloseIcon, LinkOpenIcon } from 'theme/icons';
import { useTranslation } from 'react-i18next';

const FormikAutocompleteMultipleFetch = ({
  name,
  options,
  label,
  multiple,
  getCustomLabel,
  loading,
  fetchOptions,
  fontSize = 16,
  pathForOpenEntity,
  ...rest
}) => {
  const { t } = useTranslation();
  
  const [field, meta, helpers] = useField(name);
  const [searchValue, setSearchValue] = useState('');
  const debouncedFetch = useRef(debounce(fetchOptions, 400)).current;
  const showError = meta.touched && Boolean(meta.error);

  useEffect(() => {
    if (searchValue) {
      debouncedFetch(searchValue);
    }
  }, [searchValue, debouncedFetch]);

  const handleTagDelete = (deletedOption) => {
    const updatedValue = field.value.filter((option) => option.id !== deletedOption.id);
    helpers.setValue(updatedValue);
  };

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      value={field.value || []}
      onChange={(_, value) => {
        helpers.setValue(value !== null ? value : []);
      }}
      onInputChange={(e, value) => {
        setSearchValue(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (getCustomLabel ? getCustomLabel(option) : option.label || option.name)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            deleteIcon={<CloseIcon width={20} height={20} />}
            sx={{
              fontSize: 14,
              m: 0,
              height: 0,
              '& .MuiChip-label': { pl: 0, pr: 1 },
              border: 'none',
            }}
            {...getTagProps({ index })}
            key={option.id}
            variant="outlined"
            label={
              <span>
                {getCustomLabel ? getCustomLabel(option) : option.label || option.name}
                <Tooltip title={t('base.tooltips.openLink')}>
                  <IconButton
                    sx={{ p: 0.5 }}
                    component={Link}
                    href={`${pathForOpenEntity}${option.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkOpenIcon width={18} height={18} />
                  </IconButton>
                </Tooltip>
              </span>
            }
            onDelete={() => handleTagDelete(option)}
          />
        ))
      }
      loading={loading}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: 'primary.lighter' }} />}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={rest.placeholder || 'Choose'}
          InputLabelProps={{ shrink: true }}
          label={label}
          name={name}
          error={showError}
          helperText={(meta.touched && meta.error) || ''}
          sx={{ '& input': { fontSize } }}
        />
      )}
    />
  );
};

export default memo(FormikAutocompleteMultipleFetch);
