import { Box, Button, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { dictionarySelectors } from 'store/ducks/dictionary';
import EducationForm from './EducationForm';
import Modal from 'views/common/modal/Modal';
import useModal from 'hooks/useModal';
import { memo } from 'react';
import EducationsList from './EducationsList';
import { useTranslation } from 'react-i18next';

const Educations = () => {
  const { t } = useTranslation();

  const educations = useSelector(dictionarySelectors.getEducations());
  const [modalOpen, openModal, closeModal] = useModal();

  return (
    <Stack sx={{ height: '100%', p: 2 }} justifyContent="space-between">
      <Box sx={{ maxHeight: '75%', overflow: 'auto' }}>
        <EducationsList list={educations} totalElements={educations.length} hideFooter />
      </Box>

      <Button onClick={openModal} sx={{ maxWidth: 150, alignSelf: 'center', m: 2 }} variant="contained">
        {t('base.buttons.addNew')}
      </Button>
      <Modal title={t("types.settings.dictionaries.listSingle.newEducation")} questionIcon open={modalOpen} onClose={closeModal}>
        <EducationForm closeModal={closeModal} t={t} />
      </Modal>
    </Stack>
  );
};

export default memo(Educations);
