import { DateTime } from 'luxon';

function convertToLuxonDate(date) {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }

  throw new Error('Invalid date provided');
}

export default function(date, format = 'dd.MM.yyyy') {
  if (!date) {
    return date;
  }

  const parsedDate = convertToLuxonDate(date);

  if (!format || !parsedDate.isValid) {
    throw new Error('Invalid date provided');
  }

  return parsedDate.toFormat(format);
}
