import { memo } from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';

const ModalActions = ({ onClose, submitForm, formId, submitTitle = 'Save', saveDisabled = false }) => {
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection={mobileView ? 'column' : 'row'}
      flexWrap="wrap"
      width="100%"
      gap={2}
    >
      <Button sx={{ px: 8 }} fullWidth={mobileView} onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        sx={{ px: 8 }}
        type={formId ? 'submit' : 'button'}
        form={formId}
        fullWidth={mobileView}
        onClick={submitForm}
        disabled={saveDisabled}
      >
        {submitTitle}
      </Button>
    </Box>
  );
};

export default memo(ModalActions);
