import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceInvoice } from './slice';

const fetchInvoices = createAsyncThunk('invoice/fetchInvoices', async (data) => {
  return api.get(`/invoices${data}`);
});
const fetchInvoice = createAsyncThunk('invoice/fetchInvoice', async (id) => {
  return api.get(`/invoices/${id}`);
});
const createInvoice = createAsyncThunk('invoice/createInvoice', (data) => {
  return api.post('/invoices', data);
});

const updateInvoice = createAsyncThunk('invoice/updateInvoice', (data, { dispatch }) => {
  return api.put(`/invoices/${data.id}`, data).then((res) => {
    dispatch(replaceInvoice({ data: res }));
    return res;
  });
});

export default {
  fetchInvoices,
  createInvoice,
  fetchInvoice,
  updateInvoice,
};
