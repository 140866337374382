import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const fetchGrades = createAsyncThunk('grade/fetchGrades', async (data) => {
  return api.get(`/grades${data}`);
});
const fetchGrade = createAsyncThunk('grade/fetchGrade', async (id) => {
  return api.get(`/grades/${id}`);
});

export default {
  fetchGrades,
  fetchGrade,
};
