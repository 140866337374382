import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';

import { ALLOWED_PERMISSION, PERMISSIONS } from './permissions';

export const SETTINGS_MENU = [
  {
    /* --- always available for reading */
    permission: ALLOWED_PERMISSION,
    icon: <PersonOutlinedIcon />,
    key: 'profile',
    to: 'profile',
  },
  {
    permission: PERMISSIONS.dictionary.create,
    icon: <AutoStoriesOutlinedIcon />,
    key: 'dictionaries',
    to: 'dictionaries',
  },
  {
    permission: PERMISSIONS.access,
    icon: <LockResetOutlinedIcon />,
    key: 'permissions',
    to: 'permissions',
  },
  { permission: PERMISSIONS.access, icon: <ManageHistoryOutlinedIcon />, key: 'logs', to: 'logs' },
];
