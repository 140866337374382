import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, useTheme } from '@mui/material';

import DataTable from 'views/common/DataTable';
import { DataTableWrapper } from 'views/common/StyledComponents';

import { STATUSES_COLOR } from 'utils/constants/constants';

const ProgramsList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      flex: 0.2,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/programs/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    { field: 'name', headerName: t('base.labels.name'), filterable: false, flex: 0.3 },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      flex: 0.2,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return <Typography color={statusColor}>{status.label}</Typography>;
      },
    },
    { field: 'hours', headerName: t('base.labels.hours'), filterable: false, flex: 0.2 },
    { field: 'description', headerName: t('base.labels.description'), filterable: false, flex: 0.5 },
  ];

  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
      />
    </DataTableWrapper>
  );
};

export default memo(ProgramsList);
