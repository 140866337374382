import { memo } from 'react';
import { Form, withFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from 'views/common/StyledComponents';
import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikTextField from 'views/form/FormikTextField';
import AssignmentDetails from './AssignmentDetails';
import { Stack } from '@mui/material';

const AssignmentForm = ({ assignment, values }) => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <Form id="assignmentForm" style={{ height: '100%' }}>
        <HeaderCard title={t('types.assignment.name')} />
        <Stack height="calc(100% - 60px)" gap={1} sx={{ overflowY: 'auto' }}>
          <FormWrapper>
            <FormColumnWrapper sx={{ width: '50%' }}>
              <FormColumnTitle>{t('types.assignment.info')}</FormColumnTitle>
              <FormikTextField
                name="status"
                label={t('base.labels.status')}
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormikTextField
                name="number"
                label={t('base.labels.number')}
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormikTextField
                name="name"
                label={t('base.labels.name')}
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormikTextField
                name="type"
                label={t('base.labels.type')}
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormikTextField
                name="requirement"
                label={t('base.labels.requirement')}
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormColumnWrapper>
            <FormColumnWrapper sx={{ width: '50%' }}>
              <FormColumnTitle>{t('types.assignment.details')}</FormColumnTitle>
              <FormikTextField
                name="program"
                label={t('base.labels.program')}
                InputProps={{
                  readOnly: true,
                }}
              />

              <FormikTextField
                name="module"
                label={t('base.labels.module')}
                InputProps={{
                  readOnly: true,
                }}
              />

              <FormikTextField
                name="lesson"
                label={t('base.labels.lesson')}
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormikTextField
                name="deadlineDays"
                label={t('base.labels.deadlineDays')}
                placeholder="Read only"
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormikArrayTextField
                name="documents"
                values={values.documents}
                label={t('base.labels.documents')}
                placeholder="Read only"
                copyInputAdornment
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormColumnWrapper>
          </FormWrapper>
          <AssignmentDetails assignment={assignment} />
        </Stack>
      </Form>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ assignment = {} }) => ({
      ...assignment,
      id: assignment?.id,
      number: assignment?.number || '',
      name: assignment?.name || '',
      program: assignment?.program?.name || '',
      module: assignment?.module?.name || '',
      lesson: assignment?.lesson?.name || '',
      status: assignment?.status?.label || '',
      documents: assignment?.documents || [],
      type: assignment?.type?.label || '',
      requirement: assignment?.requirement?.label || '',
      deadlineDays: assignment?.deadlineDays || '',
    }),

    handleSubmit: () => {},
    enableReinitialize: true,
  })(AssignmentForm)
);
