import { memo } from 'react';
import { useField } from 'formik';
import { DateTime } from 'luxon';
import { usePermissionContext } from 'services/context/permissionContext';
import { DateTimePicker } from '@mui/x-date-pickers';
import { CalendarIcon } from 'theme/icons';

const FormikDatePicker = ({ name, disableFuture, ...rest }) => {
  const [field, meta, helpers] = useField(name);
  const hasPermission  = usePermissionContext();

  const handleDateChange = (date) => {
    if (!date) {
      helpers.setValue(null);
      return;
    }
    helpers.setValue(date);
  };

  const error = !!meta.error && meta.touched;
  const helperText = !!meta.error && meta.touched && meta.error;
  
  return (
    <DateTimePicker
      {...field}
      {...rest}
      ampm={false}
      ampmInClock={false}
      disableFuture={disableFuture}
      readOnly={!hasPermission}
      format="dd.MM.yyyy HH:mm"
      value={field.value ? DateTime.fromISO(field.value) : null}
      onChange={handleDateChange}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        textField: {
          InputLabelProps: { shrink: true },
          error: error,
          helperText: helperText,
        },
      }}
    />
  );
};

export default memo(FormikDatePicker);
