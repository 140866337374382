import { forwardRef, memo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Menu, Fade } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';

import { hasPermission } from 'utils/roles';
import { QUICK_ADD_MENU } from 'utils/constants/menuQuickAdd';
import { t } from 'i18next';

const CustomLink = forwardRef((props, ref) => {
  return <Link ref={ref} {...props} />;
});
CustomLink.displayName = 'CustomLink';

const QuickAddMenu = ({ sidebarOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = QUICK_ADD_MENU.filter((menuItem) => hasPermission(menuItem.permission)).map((menuItem) => (
    <MenuItem
      key={menuItem.key}
      sx={{ color: 'primary.main', px: 3, '&:hover': {backgroundColor: 'primary.lighter'} }}
      component={CustomLink}
      to={menuItem.to}
      onClick={handleClose}
    >
      {menuItem.icon} {t(`quickAddMenu.${menuItem.key}`)}
    </MenuItem>
  ));

  return (
    <>
      {menuItems.length > 0 && (
        <>
          <ListItem disableGutters sx={{ p: 0, mt: 'auto' }}>
            <ListItemButton
              sx={{
                py: 0.5,
                borderRadius: 1.5,
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: 'secondary.light',
                  '& .MuiListItemIcon-root, & .MuiListItemText-root': { color: 'primary.main' },
                },
              }}
              aria-controls={open ? 'quickAdd-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              disableGutters
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  color: 'extra.white',
                }}
              >
                <AddTaskIcon />
              </ListItemIcon>
              {sidebarOpen && (
                <ListItemText
                  primary={t('quickAddMenu.title')}
                  sx={{
                    color: 'extra.white',
                    '&>.MuiTypography-root': {
                      fontSize: 18,
                    },
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>

          <Menu
            id="quickAdd-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            MenuListProps={{
              'aria-labelledby': 'quickAdd-button',
            }}
            anchorOrigin={{
              vertical: -340,
              horizontal: 180,
            }}
            transformOrigin={{
              vertical: -340,
              horizontal: -10,
            }}
          >
            {menuItems}
          </Menu>
        </>
      )}
    </>
  );
};
export default memo(QuickAddMenu);
