import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'theme/icons/plusIcon.svg';


const AddInputAdornment = ({ name, add }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Tooltip title={t('base.tooltips.addField') + ' ' + name}>
        <IconButton sx={{ p: 0.25 }} onClick={add}>
          <AddIcon stroke={theme.palette.primary.main} fill="none" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(AddInputAdornment);
