import { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

import { Button, Stack, Typography, useTheme } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import ConfirmModal from './modal/ConfirmModal';
import Modal from './modal/Modal';
import { exportToExcel } from 'utils/helpers';
import { ExportIcon } from 'theme/icons';
import CreateNewModule from 'views/pages/programs/components/programModules/CreateNewModule';

/** --- Header component of Forms ---  */

const HeaderCard = ({
  title,
  data,
  formId,
  modalOpen,
  closeModal,
  openModal,
  isSubmitting,
  hasPermission,
  cloneBtn,
  handleClone,
  doc,
  isDateChanged,
  handleUpdateSession,
  handleUpdateBulkSessions,
  setSubmitting,
  formValues,
  isCreateNewModule,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const goBack = () => navigate(-1);

  const handleExport = () => {
    exportToExcel(data);
  };

  const saveDocument = (doc, contract) => {
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Agreement-${contract.number}.docx`);
    });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      px={2}
      bgcolor={'extra.white'}
      sx={{ borderBottomLeftRadius: theme.spacing(2), borderBottomRightRadius: theme.spacing(2) }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Button variant="contained" startIcon={<ArrowBackIosNewOutlinedIcon />} LinkComponent={Link} onClick={goBack}>
          {t('base.buttons.back')}
        </Button>

        <Typography variant="h4" color="text.main">
          {title}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        {/* Check if we create new user or edit current one */}
        {data && hasPermission && (
          <Button variant="contained" onClick={() => openModal()}>
            {t('base.buttons.apply')}
          </Button>
        )}
        {!data && hasPermission && (
          <Button variant="contained" type="submit" disabled={isSubmitting} form={formId}>
            {t('base.buttons.add')}
          </Button>
        )}
        {cloneBtn && hasPermission && (
          <Button variant="contained" onClick={() => handleClone()}>
            {t('base.buttons.clone')} {cloneBtn}
          </Button>
        )}
        {isDateChanged ? (
          <Modal
            attentionIcon
            open={modalOpen}
            onClose={closeModal}
            title={t('messages.warning.changedDateSession')}
            actions={
              <>
                <Button onClick={() => handleUpdateSession(formValues, setSubmitting)}> {t('base.buttons.no')}</Button>
                <Button variant="contained" onClick={() => handleUpdateBulkSessions(formValues, setSubmitting)}>
                  {t('base.buttons.yes')}
                </Button>
              </>
            }
          >
            <Typography variant="h6" color="warning.main" mx={3} my={2}>
              {t('messages.warning.updateNextSessions')}
            </Typography>
          </Modal>
        ) : (
          data && (
            <ConfirmModal
              title="Save changes?"
              open={modalOpen}
              onClose={closeModal}
              formId={formId}
              saveDisabled={isSubmitting}
              confirmTitle={isSubmitting ? t('base.buttons.saving') : t('base.buttons.save')}
              questionIcon
            />
          )
        )}
        {doc && (
          <Button onClick={() => saveDocument(doc, data)}>
            <FileDownloadOutlinedIcon sx={{ color: 'primary.main' }} />
            {t('base.dictionary.contract')}
          </Button>
        )}
        {data && (
          <Button startIcon={<ExportIcon fill={theme.palette.primary.main} />} onClick={handleExport}>
            {t('base.buttons.export')}
          </Button>
        )}
        {isCreateNewModule && <CreateNewModule programId={data?.id} />}
      </Stack>
    </Stack>
  );
};

export default memo(HeaderCard);
