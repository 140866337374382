import { Button } from '@mui/material';
import { Form, withFormik } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import store from 'store';
import { dictionaryThunks } from 'store/ducks/dictionary';
import { FormColumnWrapper, FormWrapper } from 'views/common/StyledComponents';
import FormikTextField from 'views/form/FormikTextField';

const UniversityForm = ({ university }) => {
  const { t } = useTranslation();

  return (
    <Form id="UniversityForm">
      <FormWrapper>
        <FormColumnWrapper sx={{ minWidth: 350, backgroundColor: 'transparent', m: 2 }}>
          <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
          <Button type="submit" variant="contained" sx={{ maxWidth: 150, alignSelf: 'center', mt: 3 }}>
            {!university ? t('base.buttons.add') : t('base.buttons.update')}
          </Button>
        </FormColumnWrapper>
      </FormWrapper>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ university = {} }) => ({
      ...university,
      id: university?.id,
      name: university?.name || '',
    }),

    handleSubmit: (values, { setSubmitting, resetForm, props }) => {
      props.university
        ? store
            .dispatch(dictionaryThunks.updateUniversity(values))
            .then(
              (res) =>
                !res.error &&
                (toast.success(props.t("messages.success.toast.update")),
                setSubmitting(false),
                props.closeModal(),
                store.dispatch(dictionaryThunks.fetchUniversities()))
            )
        : store
            .dispatch(dictionaryThunks.createUniversity(values))
            .then(
              (res) =>
                !res.error &&
                (setSubmitting(false), toast.success(props.t("messages.success.toast.add")), resetForm(), props.closeModal())
            )
            .finally(setSubmitting(false));
    },
    enableReinitialize: true,
  })(UniversityForm)
);
