const getGroups = () => (state) => state.group.list.data;
const groupsLoading = () => (state) => state.group.list.isLoading;
const getGroupsError = () => (state) => state.group.list.error;
const getGroup = () => (state) => state.group.item.data;
const groupLoading = () => (state) => state.group.item.isLoading;
const getGroupError = () => (state) => state.group.item.error;
const getGroupMembers = () => (state) => state.group.groupMembers.data;
const groupMembersLoading = () => (state) => state.group.groupMembers.isLoading;
const getGroupMembersError = () => (state) => state.group.groupMembers.error;
const getGroupSessions = () => (state) => state.group.groupSessions.data;
const groupSessionsLoading = () => (state) => state.group.groupSessions.isLoading;
const getGroupSessionsError = () => (state) => state.group.groupSessions.error;
const getGroupHistory = () => (state) => state.group.groupHistory.data;
const groupHistoryLoading = () => (state) => state.group.groupHistory.isLoading;
const getGroupHistoryError = () => (state) => state.group.groupHistory.error;

export default {
  getGroups,
  groupsLoading,
  getGroupsError,
  getGroup,
  groupLoading,
  getGroupError,
  getGroupMembers,
  groupMembersLoading,
  getGroupMembersError,
  getGroupSessions,
  groupSessionsLoading,
  getGroupSessionsError,
  getGroupHistory,
  groupHistoryLoading,
  getGroupHistoryError,
};
