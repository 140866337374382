import { Paragraph, AlignmentType, TextRun, SectionType } from 'docx';
import { createPrincipalRegulations } from './createPrincipalRegulations';

export const createChapterNine = (contract) => {
  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children: [
      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.CENTER,
        children: [new TextRun({ text: 'Стаття 9. ПРИКІНЦЕВІ ПОЛОЖЕННЯ', bold: true })],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: '9.1. Всі правовідносини, які виникають у зв’язку з виникненням, дією, зміненням та припиненням дії цього Договору, але не врегульовані ним, регулюються чинним законодавством України.',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: '9.2. Цей Договір складено українською мовою у двох автентичних примірниках рівної юридичної сили – по одному для кожної зі Сторін.',
          }),
        ],
      }),
      createPrincipalRegulations(contract?.legalEntity),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: '9.4. Уся інформація доводиться до Замовника шляхом направлення sms-повідомлення на номер телефону, зазначений в ст. 10 цього Договору та/або шляхом надсилання повідомлення на адресу, зазначену в ст. 10 цього Договору.',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: '9.5. Замовник своїм підписом погоджується, що своєчасно та в повному обсязі отримав всю необхідну інформацію про послуги, які надаються за цим Договором.',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: '9.6. Визнання недійсним будь-якого з положень цього Договору не веде до недійсності всього Договору. В такому разі Сторони замінюють недійсне положення таким, що відповідає їх інтересам і вимогам законодавства.',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: '9.7. Умови даного Договору, навчальні матеріали є конфіденційними та не підлягають розголошенню без отримання попередньої письмової згоди Виконавця. Умови про конфіденційність є дійсними протягом строку дії Договору та протягом 3 (трьох) років після припинення дії цього Договору.',
          }),
        ],
      }),
    ],
  };
};
