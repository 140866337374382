import { Fragment, memo, useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import { Box, Stack, Typography, useTheme } from '@mui/material';

import store from 'store';
import { taskSelectors, taskThunks } from 'store/ducks/task';

import DataTable from 'views/common/DataTable';
import Error from 'views/common/Error';
import HeaderList from 'views/common/HeaderList';
import { DataTableWrapper, PageWrapper } from 'views/common/StyledComponents';

import { getLastNameAndNameLetter } from 'utils/dataTable';
import { STATUSES_COLOR } from 'utils/constants/constants';
import filters from 'filters';

const TasksList = ({
  handleFilterChange,
  filter,
  pageView,
  togglePageView,
  hasPermission,
  isNested,
  contractTasks,
  contractTasksLoading,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const tasks = useSelector(taskSelectors.getTasks());
  const tasksLoading = useSelector(taskSelectors.tasksLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(taskThunks.fetchTasks(`?${query}`));
  }, [query]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      flex: 0.15,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/tasks/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    {
      field: 'name',
      headerName: t('base.labels.name'),
      filterable: false,
      flex: 0.5,
    },
    {
      field: 'description',
      headerName: t('base.labels.description'),
      filterable: false,
      flex: 0.4,
    },
    {
      field: 'responsible',
      headerName: t('types.task.responsible'),
      filterable: false,
      renderCell: ({ row: { responsible } }) => (
        <Stack direction="row" spacing={0.5} flexWrap="nowrap">
          {responsible.map((option) => {
            return (
              <Fragment key={option.id}>
                <Typography>{getLastNameAndNameLetter(option)}</Typography>
              </Fragment>
            );
          })}
        </Stack>
      ),
      flex: 0.25,
    },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return <Typography color={statusColor}>{status.label}</Typography>;
      },
      flex: 0.2,
    },
    {
      field: 'dueDate',
      headerName: t('base.labels.dueDate'),
      filterable: false,
      renderCell: ({ row: { dueDate } }) => {
        const shortDate = filters.date(dueDate);
        return <Typography>{shortDate}</Typography>;
      },
      flex: 0.2,
    },
    {
      field: 'author',
      headerName: t('base.labels.author'),
      filterable: false,
      renderCell: ({ row: { author } }) => {
        return <Typography>{getLastNameAndNameLetter(author)}</Typography>;
      },
      flex: 0.3,
    },
    {
      field: 'createdAt',
      headerName: t('base.labels.date'),
      filterable: false,
      renderCell: ({ row: { createdAt } }) => {
        const parsedDate = DateTime.fromISO(createdAt);
        const formattedDate = filters.date(parsedDate.ts, 'dd.MM.yyyy');
        return <Typography>{formattedDate}</Typography>;
      },
      flex: 0.2,
    },
  ];
  return isNested ? (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 1,
        py: isNested ? 1 : 2,
        backgroundColor: 'extra.white',
        borderRadius: 2,
        overflowY: 'auto',
      }}
    >
      <DataTable
        list={contractTasks}
        totalElements={contractTasks.length}
        isLoading={contractTasksLoading}
        columns={columns}
        hideFooter={true}
      />
    </Box>
  ) : (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <HeaderList
          title={t('types.task.namePlural')}
          isSearchbarShow
          pageView={pageView}
          togglePageView={togglePageView}
          buttonTitle={'+ ' + t('base.buttons.createTask')}
          to="/tasks/new"
          filter={filter}
          handleFilter={handleFilterChange}
          hasPermission={hasPermission}
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <DataTableWrapper sx={{ height: 'calc(100% - 76px)' }}>
          <DataTable
            list={tasks.content}
            totalElements={tasks.totalElements}
            isLoading={tasksLoading}
            columns={columns}
            columnVisibilityModel={{ description: false, author: false}}
            totalPages={tasks.totalPages}
            setSearchParams={setSearchParams}
            pageQuery={pageQuery}
            pageSizeQuery={pageSizeQuery}
            searchQuery={searchQuery}
            sortByQuery={sortByQuery}
            sortDirectionQuery={sortDirectionQuery}
            filterByResponsible={filter.my}
            filterByAuthor={filter.byMe}
          />
        </DataTableWrapper>
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(TasksList);
