import { memo } from 'react';
import { Form, withFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Stack, TextField } from '@mui/material';

import store from 'store';
import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';
import { sessionThunks } from 'store/ducks/session';

import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from 'views/common/StyledComponents';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FormikTextField from 'views/form/FormikTextField';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { capitalizeFirstLetter } from 'utils/helpers';
import { validationBulkSessionsForm } from 'utils/schema';

const BulkSessionForm = ({ values, isSubmitting }) => {
  const { t } = useTranslation();
  const groups = useSelector(groupSelectors.getGroups());
  const groupsLoading = useSelector(groupSelectors.groupsLoading()) && !groups;

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setSearchValue] = useAutocompleteSearch(null, values?.group?.name, groupThunks.fetchGroups);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.group, groupSelectors.getGroups(), groupActions.setGroups);

  const permission = hasPermission(PERMISSIONS.session.create);
  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="bulkSessionsForm" style={{ height: '100%' }}>
          <HeaderCard
            title={t('types.session.newBulk')}
            isSubmitting={isSubmitting}
            formId="bulkSessionsForm"
            hasPermission={permission}
          />
          <FormWrapper sx={{ justifyContent: 'flex-start' }}>
            <FormColumnWrapper sx={{ width: values?.group ? '50%' : '100%' }}>
              <FormColumnTitle>{t('types.session.info')}</FormColumnTitle>
              <FormikAutocomplete
                name="group"
                label={t('base.labels.group')}
                onInputChange={(event, value) => {
                  setSearchValue(value);
                }}
                options={groups.content}
                loading={groupsLoading}
                placeholder={t('base.placeholders.typeForSearch')}
              />
              <FormikDatePicker name="startDate" label={t('base.labels.startDate')} />
              <FormikAutocomplete
                name="module"
                label={t('base.labels.module')}
                options={values?.group?.program?.modules || []}
                placeholder={t('base.placeholders.typeForSearch')}
              />
              <FormikTextField
                name="link"
                label={t('base.labels.sessionLink')}
                placeholder={t('base.placeholders.pasteLink')}
                InputLabelProps={{ shrink: true }}
              />
            </FormColumnWrapper>
            {values?.group && (
              <FormColumnWrapper sx={{ width: '50%' }}>
                <FormColumnTitle>{t('types.session.groupDetails')}</FormColumnTitle>
                <TextField
                  value={values?.group?.program?.name}
                  disabled
                  label={t('base.labels.program')}
                  InputLabelProps={{ shrink: true }}
                />

                {values?.group?.schedule?.map((item) => (
                  <Stack key={item.id} direction="row" width="100%">
                    <TextField
                      value={capitalizeFirstLetter(item.day.name.toLowerCase())}
                      disabled
                      label={t('base.labels.schedule') + '. ' + t('base.labels.day')}
                      sx={{ width: '100%' }}
                    />
                    <TextField value={item.time} disabled label={t('base.labels.time')} sx={{ width: '100%' }} />
                  </Stack>
                ))}
              </FormColumnWrapper>
            )}
          </FormWrapper>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      group: null,
      startDate: null,
      module: null,
      link: '',
    }),
    validationSchema: validationBulkSessionsForm,

    handleSubmit: async (values, { props, setSubmitting }) => {
      const startDate = `?startDate=${values?.startDate}`;
      const moduleId = `moduleId=${values?.module?.id}`;
      const link = `link=${encodeURIComponent(values?.link)}`;
      const query = values?.module ? `${startDate}&${link}&${moduleId}` : `${startDate}&${link}`;

      const data = { groupId: values?.group?.id, query: query };
      const res = await store.dispatch(sessionThunks.createBulkSessions(data));
      if (!res.error) {
        setSubmitting(false);
        toast.success(props.t('messages.success.toast.bulkCreate'));
        setTimeout(() => {
          props.navigate(`/sessions`);
        }, 1500);
      }
    },
    enableReinitialize: true,
  })(BulkSessionForm)
);
