import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

/**
 * Custom hook to add an entity from parent entity to the list if it doesn't exist there.
 * Used for Autocomplete options to display values in the form.
 * @param {object | object[]} data - A single entity or an array of entities from the parent entity.
 * @param {function} getEntitiesSelector - Selector function to get the list of entities from the store.
 * @param {function} setEntitiesAction - Action function to update the list of entities in the store.
 */

export function useAddEntityIfNotExists(data, getEntitiesSelector, setEntitiesAction) {
  const entities = useSelector(getEntitiesSelector);

  useEffect(() => {
    const existing = Array.isArray(data) ? data : [data];
    const newEntities = existing.filter((e) => e?.id && !entities.content.find((en) => en.id === e?.id));

    if (newEntities.length > 0) {
      const updatedEntities = [...entities.content, ...newEntities];
      store.dispatch(setEntitiesAction(updatedEntities));
    }
  }, [entities, data]);
}
