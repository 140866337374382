import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceSession } from './slice';

const fetchSessions = createAsyncThunk('session/fetchSessions', async (data) => {
  return api.get(`/sessions${data}`);
});
const fetchSession = createAsyncThunk('session/fetchSession', async (id) => {
  return api.get(`/sessions/${id}`);
});
const createSession = createAsyncThunk('session/createSession', (data) => {
  return api.post('/sessions', data);
});

const updateSession = createAsyncThunk('session/updateSession', (data, { dispatch }) => {
  return api.put(`/sessions/${data.id}`, data).then((res) => {
    dispatch(replaceSession({ data: res }));
    return res;
  });
});

const createBulkSessions = createAsyncThunk('session/createBulkSessions', (data) => {
  return api.post(`/sessions/bulk/groups/${data.groupId}${data.query}`);
});

const moveBulkSessions = createAsyncThunk('session/moveBulkSessions', (data) => {
  return api.post(`/sessions/bulk/move/${data.sessionId}${data.query}` );
});


export default {
  fetchSessions,
  createSession,
  fetchSession,
  updateSession,
  createBulkSessions,
  moveBulkSessions,
};
