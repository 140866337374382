import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';
import store from './store';
import { Provider } from 'react-redux';
import UserContainer from './views/containers/UserContainer';
import * as Sentry from '@sentry/react';
import ErrorPage from './views/pages/error/ErrorPage';
import { RouterProvider } from 'react-router-dom';
import router from './routes/router';
import AppDataContainer from './views/containers/AppDataContainer';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { PermissionContextProvider } from 'services/context/permissionContext';

import './i18n.js';
import CustomThemeProvider from 'theme/CustomThemeProvider';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: window.location.hostname,
    normalizeDepth: 10,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <CustomThemeProvider>
      <CssBaseline />
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <UserContainer>
          <AppDataContainer>
            <PermissionContextProvider value={true}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <RouterProvider router={router} />
              </LocalizationProvider>
            </PermissionContextProvider>
          </AppDataContainer>
          <ToastContainer
            position="top-center"
            transition={Zoom}
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{ minWidth: 'fit-content' }}
          />
        </UserContainer>
      </Sentry.ErrorBoundary>
    </CustomThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
