import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const fetchAccess = createAsyncThunk('access/fetchAccess', async () => {
  return api.get(`/access`);
});

const createAccess = createAsyncThunk('access/createAccess', (data) => {
  return api.post('/access', data);
});


export default {
  fetchAccess,
  createAccess,
};
