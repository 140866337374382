import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import store from 'store';
import { contractSelectors, contractThunks } from 'store/ducks/contract';
import { personSelectors, personThunks } from 'store/ducks/person';

import Loader from 'views/common/Loader';
import Error from 'views/common/Error';
import ContractForm from './components/ContractForm';

import generateContract from 'services/generateContract/generateContract';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import useModal from 'hooks/useModal';

const ContractCard = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();
  const contract = useSelector(contractSelectors.getContract());
  const isContractLoading = useSelector(contractSelectors.contractLoading()) && !contract;

  const customer = useSelector(personSelectors.getPerson());
  const isCustomerLoading = useSelector(personSelectors.personLoading()) && !customer;

  /* --- state to handle files uploading (file attachments to contract) --- */
  const [files, setFiles] = useState(['']);

  useEffect(() => {
    store.dispatch(contractThunks.fetchContract(id));
    hasPermission(PERMISSIONS.invoice.read) && store.dispatch(contractThunks.fetchContractInvoices(id));
  }, [id]);

  useEffect(() => {
    store.dispatch(contractThunks.fetchContractTasks(id));
  }, [id]);

  useEffect(() => {
    if (contract && contract.contractor && contract.contractor.id !== null) {
      store.dispatch(personThunks.fetchPerson(contract.contractor.id));
    }
  }, [contract]);

  const doc = contract && customer && generateContract(contract, customer);

  return (
    <Stack justifyContent="center">
      {isContractLoading && <Loader />}
      {!isContractLoading && !isCustomerLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <ContractForm
            doc={doc}
            contract={contract}
            modalOpen={modalOpen}
            closeModal={closeModal}
            openModal={openModal}
            files={files}
            setFiles={setFiles}
            t={t}
          />
        </ErrorBoundary>
      )}
    </Stack>
  );
};

export default memo(ContractCard);
