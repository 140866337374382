import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceGroup } from './slice';

const fetchGroups = createAsyncThunk('group/fetchGroups', async (data) => {
  return api.get(`/groups${data}`);
});
const fetchGroup = createAsyncThunk('group/fetchGroup', async (id) => {
  return api.get(`/groups/${id}`);
});
const createGroup = createAsyncThunk('group/createGroup', (data) => {
  return api.post('/groups', data);
});

const updateGroup = createAsyncThunk('group/updateGroup', (data, { dispatch }) => {
  return api.put(`/groups/${data.id}`, data).then((res) => {
    dispatch(replaceGroup({ data: res }));
    return res;
  });
});

const fetchGroupMembers = createAsyncThunk('group/fetchGroupMembers', async (id) => {
  return api.get(`/groups/${id}/members`);
});

const fetchGroupSessions = createAsyncThunk('group/fetchGroupSessions', async (id) => {
  return api.get(`/groups/${id}/sessions`);
});

const fetchGroupHistory = createAsyncThunk('group/fetchGroupHistory', async (id) => {
  return api.get(`/groups/${id}/history`);
});

export default {
  fetchGroups,
  createGroup,
  fetchGroup,
  updateGroup,
  fetchGroupMembers,
  fetchGroupSessions,
  fetchGroupHistory,
};
