import { Button } from '@mui/material';
import { Form, withFormik } from 'formik';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import store from 'store';
import { dictionaryThunks } from 'store/ducks/dictionary';
import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { getFullName } from 'utils/dataTable';
import { validationLegalEntityForm } from 'utils/schema';
import { FormColumnWrapper, FormWrapper } from 'views/common/StyledComponents';
import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';

const LegalEntityForm = ({ values, legalEntity }) => {
  const { t } = useTranslation();
  const persons = useSelector(personSelectors.getPersons());
  const personsLoading = useSelector(personSelectors.personsLoading()) && !persons;

  const [setStudentSearchValue] = useAutocompleteSearch(null, null, personThunks.fetchPersons);
  useAddEntityIfNotExists(values?.owner, personSelectors.getPersons(), personActions.setPersons);
  return (
    <Form id="LegalEntityForm">
      <FormWrapper>
        <FormColumnWrapper sx={{ minWidth: 350, backgroundColor: 'transparent', m: 2 }}>
          <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
          <FormikTextField name="idCode" label={t('base.labels.idCode')} placeholder={t('base.placeholders.idCode')} />
          <FormikTextField
            name="address"
            label={t('base.labels.address')}
            placeholder={t('base.placeholders.address')}
          />
          <FormikTextField name="iban" label={t('base.labels.iban')} placeholder={t('base.placeholders.iban')} />
          <FormikTextField name="bank" label={t('base.labels.bank')} placeholder={t('base.placeholders.bank')} />
          <FormikTextField
            name="taxSystem"
            label={t('base.labels.taxSystem')}
            placeholder={t('base.placeholders.taxSystem')}
          />

          <FormikAutocomplete
            name="owner"
            label={t('base.labels.owner')}
            placeholder={t('base.placeholders.typeForSearch')}
            onInputChange={(e, value) => {
              setStudentSearchValue(value);
            }}
            getCustomLabel={getFullName}
            options={persons.content}
            loading={personsLoading}
          />
          <FormikArrayTextField
            name="documents"
            values={values.documents}
            label={t('base.labels.documents')}
            placeholder={t('base.placeholders.pasteLink')}
            copyInputAdornment
          />

          <Button type="submit" variant="contained" sx={{ maxWidth: 150, alignSelf: 'center', mt: 3 }}>
            {!legalEntity ? t('base.buttons.add') : t('base.buttons.update')}
          </Button>
        </FormColumnWrapper>
      </FormWrapper>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ legalEntity = {} }) => ({
      ...legalEntity,
      id: legalEntity?.id,
      name: legalEntity?.name || '',
      idCode: legalEntity?.idCode || '',
      address: legalEntity?.address || '',
      iban: legalEntity?.iban || '',
      bank: legalEntity?.bank || '',
      owner: legalEntity?.owner || null,
      documents: legalEntity?.documents || [],
      taxSystem: legalEntity?.taxSystem || '',
    }),
    validationSchema: validationLegalEntityForm,

    handleSubmit: (values, { setSubmitting, resetForm, props }) => {
      props.legalEntity
        ? store
            .dispatch(dictionaryThunks.updateLegalEntity(values))
            .then(
              (res) =>
                !res.error &&
                (toast.success(props.t('messages.success.toast.update')),
                setSubmitting(false),
                props.closeModal(),
                store.dispatch(dictionaryThunks.fetchLegalEntities()))
            )
            .finally(setSubmitting(false))
        : store
            .dispatch(dictionaryThunks.createLegalEntity(values))
            .then(
              (res) =>
                !res.error &&
                (setSubmitting(false),
                toast.success(props.t('messages.success.toast.add')),
                resetForm(),
                props.closeModal())
            )
            .finally(setSubmitting(false));
    },
    enableReinitialize: true,
  })(LegalEntityForm)
);
