import { Box, Button } from '@mui/material';
import { memo, useState } from 'react';
import DataTable from 'views/common/DataTable';
import useModal from 'hooks/useModal';
import Modal from 'views/common/modal/Modal';
import EducationForm from './EducationForm';
import { useTranslation } from 'react-i18next';

const EducationsList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
}) => {
  const [modalOpen, openModal, closeModal] = useModal();
  const [entity, setEntity] = useState(null);
  const { t } = useTranslation();

  const handleClick = (id) => {
    const education = list.find((option) => option.id === id);
    education && setEntity(education);
    openModal();
  };

  const columns = [
    {
      field: 'name',
      headerName: t('base.labels.education'),
      filterable: false,
      renderCell: ({ row: { name, id } }) => {
        return (
          <Button
            variant="text"
            sx={{ p: 0, color: 'text.main', minWidth: 0, justifyContent: 'flex-start', overflow: 'auto' }}
            onClick={() => handleClick(id)}
          >
            {name}
          </Button>
        );
      },
      flex: 0.5,
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          px: 2,
          borderRadius: 1,
        }}
      >
        <DataTable
          list={list}
          totalElements={totalElements}
          isLoading={isLoading}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          totalPages={totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
          hideFooter={hideFooter}
        />
      </Box>
      <Modal title={t("types.settings.dictionaries.listSingle.education")} questionIcon open={modalOpen} onClose={closeModal}>
        <EducationForm closeModal={closeModal} education={entity} t={t} />
      </Modal>
    </Box>
  );
};

export default memo(EducationsList);
