import { Stack } from '@mui/material';
import filters from 'filters';
import { DateTime } from 'luxon';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import store from 'store';
import { reportActions, reportSelectors } from 'store/ducks/report';

import { PeriodDatePicker } from 'views/common/StyledComponents';

const FilterByPeriod = () => {
  const period = useSelector(reportSelectors.getFilterByPeriod());
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={2}>
      <PeriodDatePicker
        label={t('base.labels.from')}
        value={DateTime.fromISO(period?.from)}
        onChange={(newValue) =>
          store.dispatch(
            reportActions.addFilterByPeriod({
              from: filters.date(newValue.ts, 'yyyy-MM-dd'),
              to: filters.date(period?.to, 'yyyy-MM-dd'),
            })
          )
        }
      />
      <PeriodDatePicker
        label={t('base.labels.to')}
        value={DateTime.fromISO(period?.to)}
        onChange={(newValue) =>
          store.dispatch(
            reportActions.addFilterByPeriod({
              from: filters.date(period?.from, 'yyyy-MM-dd'),
              to: filters.date(newValue.ts, 'yyyy-MM-dd'),
            })
          )
        }
      />
    </Stack>
  );
};

export default memo(FilterByPeriod);
