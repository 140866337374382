import { Paragraph, AlignmentType, TextRun, SectionType } from 'docx';
import { DateTime } from 'luxon';
import { createContractDate } from './createContractDate';
import { createLegalEntity } from './createLegalEntity';
import { createCustomerPart } from './createCustomerPart';
import { createBankDetails } from './createBankDetails';
import { createPaymentSchedule } from './createPaymentSchedule';
import { DAYS_ENG_TO_UA } from 'utils/constants/constants';

export const createAppendixOne = (customer, contract) => {
  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children: [
      createContractDate(contract?.date),

      /** --- PREAMBLE --- */
      createLegalEntity(contract?.legalEntity),
      createCustomerPart(customer),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        indent: {
          firstLine: 500,
        },
        children: [
          new TextRun({
            text: `Виконавець та Замовник надалі разом іменовані як «Сторони», а кожен окремо – «Сторона», склали та підписали цей Додаток №1 (далі – «Додаток») до Договору № ${
              contract?.number
            } про надання послуг від ${DateTime.fromISO(contract?.createdAt).toFormat(
              'dd.MM.yyyy'
            )} (далі – «Договір»), про нижчевикладене:`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        indent: {
          firstLine: 500,
        },
        children: [
          new TextRun({
            text: `Всі визначені терміни, які використовуються у цьому Додатку, мають значення, присвоєні їм у Договорі, якщо інше прямо не передбачено у цьому Додатку.`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `1. Запланована програма занять: ${contract?.program?.name}`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `2. Строк надання Послуг за Договором становить ${
              contract?.program?.hours
            } академічних годин і розрахований на ${
              contract?.program?.months ? contract?.program?.months : '__ (____________)'
            } місяців.`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `3. Спосіб надання Послуг: онлайн, шляхом онлайн сесій на платформі Zoom та/або доступу до особистого кабінету на сайті Виконавця за адресою: `,
          }),
          new TextRun({
            text: 'https://i.dan-it.com.ua',
            style: 'Hyperlink',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `4. Дата початку надання Послуг: ${
              contract?.group ? contract?.group?.startDate : `___________ ${DateTime.now().year}`
            }`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `Дата початку надання Послуг, зазначена вище, може бути змінена Виконавцем на власний розсуд, про що Виконавець повідомляє Замовника не пізніше ніж за 2 (два) календарних дні до запланованої дати початку надання Послуг.`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `5. Графік надання послуг: щотижня, ${contract?.group?.schedule?.map(
              (day) => ` ${DAYS_ENG_TO_UA[day.name]}`
            )} за виключенням запланованих періодів виконання Замовником контрольних робіт. `,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `6. Вартість Послуг за Договором становить: ${contract?.amount} грн. (____________ гривень 00 коп.).`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `Замовник здійснює оплату зазначеної в цьому пункті вище вартості Послуг в порядку і в строки, зазначені в таблиці нижче:`,
          }),
        ],
      }),
      createPaymentSchedule(),

      /** --- BANK DETAILS --- */
      new Paragraph({
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
        children: [
          new TextRun({
            text: `АДРЕСИ, РЕКВІЗИТИ ТА ПІДПИСИ СТОРІН:`,
            bold: true,
          }),
        ],
      }),
      createBankDetails(customer, contract.legalEntity, false),
    ],
  };
};
