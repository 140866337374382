import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const fetchContacts = createAsyncThunk('person/fetchContacts', async (query) => {
  return api.get(`/contacts${query}`);
});

export default {
  fetchContacts,
};
