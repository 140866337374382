import { DateTime } from 'luxon';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import store from 'store';
import { reportActions, reportSelectors, reportThunks } from 'store/ducks/report';
import InvoicesList from 'views/common/InvoicesList';

const InvoicesForPeriodReport = () => {
  const invoices = useSelector(reportSelectors.getInvoicesForPeriodReport());
  const isLoading = useSelector(reportSelectors.invoicesForPeriodReportLoading()) && !invoices;
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const [_, setProps] = useOutletContext();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';
  const periodQuery = useSelector(reportSelectors.getFilterByPeriod());

  useEffect(() => {
    query && store.dispatch(reportThunks.fetchInvoicesForPeriod(`?${query}`));
  }, [query]);

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      invoicesForPeriod: true,
    }));

    return () => {
      setProps((prevProps) => ({ ...prevProps, invoicesForPeriod: false }));
      store.dispatch(
        reportActions.addFilterByPeriod({
          from: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
          to: DateTime.local().endOf('month').toFormat('yyyy-MM-dd'),
        })
      );
    };
  }, [setProps]);

  return (
    <InvoicesList
      list={invoices.content}
      isLoading={isLoading}
      totalElements={invoices.totalElements}
      totalPages={invoices.totalPages}
      setSearchParams={setSearchParams}
      pageQuery={pageQuery}
      pageSizeQuery={pageSizeQuery}
      searchQuery={''}
      sortByQuery={sortByQuery}
      sortDirectionQuery={sortDirectionQuery}
      periodQuery={periodQuery}
    />
  );
};

export default memo(InvoicesForPeriodReport);
