import { memo, useEffect, useState } from 'react';
import { Form, withFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Stack, TextField } from '@mui/material';

import store from 'store';
import { programActions, programSelectors, programThunks } from 'store/ducks/program';
import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';
import { contractThunks } from 'store/ducks/contract';

import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';
import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FilesUploader from 'views/form/FilesUploader';
import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from '../../../common/StyledComponents';
import ContractDetails from './ContractDetails';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';

import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';
import { validationContractForm } from '../../../../utils/schema';
import { uploadFilesAndReturnUploadedFiles } from 'utils/helpers';
import { getActiveGroups, getActivePrograms, getFullName, getProgramHours, getShortName } from 'utils/dataTable';


const ContractForm = ({
  contract,
  doc,
  modalOpen,
  openModal,
  closeModal,
  isSubmitting,
  values,
  files,
  setFiles,
  invoicesLoading,
}) => {
  const { t } = useTranslation();

  const clients = useSelector(personSelectors.getClients());
  const clientsLoading = useSelector(personSelectors.clientsLoading());
  const managers = useSelector(dictionarySelectors.getManagers());
  const legalEntities = useSelector(dictionarySelectors.getLegalEntities());
  const enums = useSelector(dictionarySelectors.getEnums());
  const programs = useSelector(programSelectors.getPrograms());
  const programsLoading = useSelector(programSelectors.programsLoading()) && !programs;
  const groups = useSelector(groupSelectors.getGroups());
  const groupsLoading = useSelector(groupSelectors.groupsLoading()) && !groups;

  const permission = contract ? hasPermission(PERMISSIONS.contract.update) : hasPermission(PERMISSIONS.contract.create);
  /** --- Default contractor value --- */
  const [defaultValue, setDefaultValue] = useState(null);

  /** --- Create the full name in order to compare with searchValue in Autocomplete search and avoid unnecessary fetch --- */
  const valueSearchStudent = values.student ? getFullName(values.student) : '';
  const valueSearchContractor = values.contractor ? getFullName(values.contractor) : '';

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setStudentSearchValue] = useAutocompleteSearch(contract, valueSearchStudent, personThunks.fetchClients);
  const [setContractorSearchValue] = useAutocompleteSearch(contract, valueSearchContractor, personThunks.fetchClients);
  const [setProgramSearchValue] = useAutocompleteSearch(contract, values?.program?.name, programThunks.fetchPrograms);
  const [setGroupSearchValue] = useAutocompleteSearch(contract, values?.group?.name, groupThunks.fetchGroups);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.student, personSelectors.getClients(), personActions.setClients);
  useAddEntityIfNotExists(values?.contractor, personSelectors.getClients(), personActions.setClients);
  useAddEntityIfNotExists(values?.program, programSelectors.getPrograms(), programActions.setPrograms);
  useAddEntityIfNotExists(values?.group, groupSelectors.getGroups(), groupActions.setGroups);

  const getGroupCoordinator = () => {
    const coordinator = values?.group?.coordinator || '';
    return getShortName(coordinator);
  };

  /** --- Set the contractor value when we set or change student --- */
  useEffect(() => {
    if (!contract && values.student) {
      setDefaultValue(values.student);
    }
  }, [contract, values.student]);

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="contractForm" style={{ height: '100%' }}>
          {/* --- HEADER OF FORM WITH BUTTONS --- */}
          {/* --- passing props in Header component for opening popUp and render buttons depending on new card or current one --- */}
          <HeaderCard
            title={contract ? t('types.contract.name') : t('types.contract.new')}
            data={contract}
            isSubmitting={isSubmitting}
            formId="contractForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
            doc={doc}
          />
          <Stack height="calc(100% - 60px)" gap={1} sx={{ overflowY: 'auto' }}>
            <FormWrapper>
              {/* --- PERSONAL DATA COLUMN ---*/}
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.contract.personalInfo')}</FormColumnTitle>
                <FormikAutocomplete
                  name="student"
                  label={t('base.labels.student')}
                  onInputChange={(e, value) => {
                    setStudentSearchValue(value);
                  }}
                  getCustomLabel={getFullName}
                  options={clients.content}
                  loading={clientsLoading}
                  placeholder={t('base.placeholders.typeForSearch')}
                />
                <FormikAutocomplete
                  name="contractor"
                  label={t('base.labels.contractor')}
                  onInputChange={(e, value) => {
                    setContractorSearchValue(value);
                  }}
                  getCustomLabel={getFullName}
                  options={clients.content}
                  loading={clientsLoading}
                  placeholder={t('base.placeholders.typeForSearch')}
                  defaultValue={defaultValue}
                />
                <FormikAutocomplete
                  name="responsible"
                  label={t('base.labels.responsible')}
                  getCustomLabel={getShortName}
                  options={managers}
                />
                <FormikAutocomplete name="legalEntity" label={t('base.labels.legalEntity')} options={legalEntities} />
                <FormikArrayTextField
                  name="documents"
                  values={values.documents}
                  label={t('base.labels.documents')}
                  placeholder={t('base.placeholders.pasteLink')}
                  copyInputAdornment
                />
              </FormColumnWrapper>
              {/* --- CONTRACT INFO COLUMN ---*/}
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.contract.contractInfo')}</FormColumnTitle>
                <FormikTextField
                  name="number"
                  label={t('base.labels.number')}
                  placeholder={t('base.placeholders.number')}
                />
                <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.contractStatuses} />
                <FormikAutocomplete
                  name="paymentMethod"
                  label={t('base.labels.paymentMethod')}
                  options={enums.paymentMethods}
                />
                <FormikTextField
                  name="amount"
                  label={t('base.labels.contractAmount')}
                  placeholder={t('base.placeholders.contractAmount')}
                />
                <FormikDatePicker name="date" label={t('base.labels.contractDate')} />
                <FilesUploader
                  files={files}
                  setFiles={setFiles}
                  fileSize="15000000"
                  filesAccept=".pdf, .ppt, .pptx, .txt, .xls, .xlsx, .doc, .docx, .zip, .mp4, .mpeg, .avi, .jpeg, .jpg, .png"
                  isLoading={isSubmitting}
                  uploadedFiles={values?.files}
                />
              </FormColumnWrapper>
              {/* --- EDUCATION DATA COLUMN ---*/}
              <FormColumnWrapper
                sx={{
                  width: '33.33%',
                }}
              >
                <FormColumnTitle>{t('types.contract.education')}</FormColumnTitle>
                <FormikAutocomplete
                  name="program"
                  label={t('base.labels.program')}
                  loading={programsLoading}
                  options={contract ? programs.content : getActivePrograms(programs.content)}
                  onInputChange={(e, value) => {
                    setProgramSearchValue(value);
                  }}
                  placeholder={t('base.placeholders.typeForSearch')}
                />
                <FormikAutocomplete
                  name="modules"
                  label={t('base.labels.modules')}
                  options={contract?.program?.modules || []}
                  limitTags={1}
                  multiple
                />
                <FormikAutocomplete
                  name="group"
                  label={t('base.labels.group')}
                  loading={groupsLoading}
                  options={contract ? groups.content : getActiveGroups(groups.content)}
                  onInputChange={(e, value) => {
                    setGroupSearchValue(value);
                  }}
                  placeholder={t('base.placeholders.typeForSearch')}
                />
                <TextField
                  name="coordinator"
                  label={t('base.labels.coordinator')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getGroupCoordinator()}
                />
                <TextField
                  name="startDate"
                  label={t('base.labels.startDate')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={values?.group?.startDate || ''}
                />
                <TextField
                  name="hours"
                  label={t('base.labels.programHours')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getProgramHours(programs, values)}
                />
              </FormColumnWrapper>
            </FormWrapper>
            <FormColumnWrapper sx={{ width: '100%', py: 1 }}>
              <FormikTextField
                name="comment"
                label={t('base.labels.comment')}
                placeholder={t('base.placeholders.comment')}
                multiline
                rows={1}
              />
            </FormColumnWrapper>
            {contract && hasPermission(PERMISSIONS.invoice.read) && !invoicesLoading && (
              <ContractDetails contractId={contract?.id} />
            )}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};
export default memo(
  withFormik({
    mapPropsToValues: ({ contract = {} }) => ({
      ...contract,
      id: contract?.id,
      student: contract?.student || null,
      contractor: contract?.contractor || null,
      number: contract?.number || '',
      responsible: contract?.responsible || null,
      legalEntity: contract?.legalEntity || null,
      documents: contract?.documents || [],
      status: contract?.status || null,
      paymentMethod: contract?.paymentMethod || null,
      amount: contract?.amount || '',
      date: contract?.date || null,
      program: contract?.program || null,
      group: contract?.group || null,
      comment: contract?.comment || '',
      modules: contract?.modules || [],
      files: contract?.files || [],
    }),
    validationSchema: validationContractForm,
    handleSubmit: async (values, { props, setSubmitting }) => {
      /** -- upload files and waiting response with uploaded files -- */
      const uploadedFiles = await uploadFilesAndReturnUploadedFiles(props.files, values);
      /**  -- update values with uploaded files data ---- */
      const updatedValues = { ...values, files: uploadedFiles.filter((file) => file !== '') }; // remove empty items from array

      const response = props.contract
        ? await store.dispatch(contractThunks.updateContract(updatedValues))
        : await store.dispatch(contractThunks.createContract(updatedValues));

      if (!response.error) {
        toast.success(
          props.contract
            ? props.t('messages.success.toast.updateContract')
            : props.t('messages.success.toast.createContract')
        );
        setSubmitting(false);

        if (props.contract) {
          props.closeModal(),
            response.meta.requestStatus === 'fulfilled' &&
              (await store.dispatch(contractThunks.fetchContractInvoices(values.id)));
        } else props.navigate(`/contracts/${response.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(ContractForm)
);
