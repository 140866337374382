import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const programSlice = createSlice({
  name: 'programSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
    programLessons: loadDataInitialState([]),
    programAssignments: loadDataInitialState([]),
  },
  reducers: {
    replaceProgram(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
    /**  --- Add contract to contracts list if the contract is out loaded contract list with pagination.
     *  Just for Autocomplete --- */
    setPrograms(state, action) {
      state.list.data.content = action.payload;
    },

    clearProgramLessons(state) {
      state.programLessons = loadDataInitialState([]);
    },
    clearProgramAssignments(state) {
      state.programAssignments = loadDataInitialState([]);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchPrograms, 'list');
    loadDataExtraReducer(builder, thunks.fetchProgramLessons, 'programLessons');
    loadDataExtraReducer(builder, thunks.fetchProgramAssignments, 'programAssignments');
    loadDataExtraReducer(builder, thunks.fetchProgram, 'item');
    loadDataExtraReducer(builder, thunks.createProgram, 'item');
    loadDataExtraReducer(builder, thunks.updateProgram, 'item');
    loadDataExtraReducer(builder, thunks.createProgramModule, 'item');
    loadDataExtraReducer(builder, thunks.updateProgramModule, 'item');
  },
});

export const { replaceProgram, setPrograms, clearProgramLessons, clearProgramAssignments } = programSlice.actions;
export default programSlice.reducer;
