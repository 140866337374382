import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const invoiceSlice = createSlice({
  name: 'invoiceSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
  },
  reducers: {
    replaceInvoice(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchInvoices, 'list');
    loadDataExtraReducer(builder, thunks.fetchInvoice, 'item');
    loadDataExtraReducer(builder, thunks.createInvoice, 'item');
    loadDataExtraReducer(builder, thunks.updateInvoice, 'item');
      },
});

export const { replaceInvoice } = invoiceSlice.actions;
export default invoiceSlice.reducer;
