const getGrades = () => (state) => state.grade.list.data;
const gradesLoading = () => (state) => state.grade.list.isLoading;
const getGradesError = () => (state) => state.grade.list.error;
const getGrade = () => (state) => state.grade.item.data;
const gradeLoading = () => (state) => state.grade.item.isLoading;
const getGradeError = () => (state) => state.grade.item.error;

export default {
  getGrades,
  gradesLoading,
  getGradesError,
  getGrade,
  gradeLoading,
  getGradeError,
};
