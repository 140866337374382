
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PageWrapper } from 'views/common/StyledComponents';

import HeaderReports from 'views/pages/reports/components/HeaderReports';

const ReportsLayout = () => {
  const [props, setProps] = useState({
    overdueInvoicesReport: false,
    sessionsDurationReport: false,
    invoicesForPeriod: false,
    handleGenerateReport: null,
  });

  return (
    <PageWrapper>
      <HeaderReports
        overdueInvoicesReport={props.overdueInvoicesReport}
        sessionsDurationReport={props.sessionsDurationReport}
        invoicesForPeriod={props.invoicesForPeriod}
        handleGenerateReport={props.handleGenerateReport}
      />
      <Outlet context={[props, setProps]} />
    </PageWrapper>
  );
};

export default ReportsLayout;
