import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import { Stack } from '@mui/material';

import store from 'store';
import { personSelectors, personThunks } from 'store/ducks/person';

import Loader from 'views/common/Loader';
import Error from 'views/common/Error';
import PersonForm from './components/PersonForm';

import useModal from 'hooks/useModal';

const PersonCard = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();
  const person = useSelector(personSelectors.getPerson());
  const isLoading = useSelector(personSelectors.personLoading()) && !person;

  const isClient = person?.roles.find((option) => option.name === 'ROLE_CLIENT');

  useEffect(() => {
    store.dispatch(personThunks.fetchPerson(id));
  }, [id]);

  return (
    <Stack justifyContent="center">
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <PersonForm
              person={person}
              modalOpen={modalOpen}
              openModal={openModal}
              closeModal={closeModal}
              isClient={isClient}
              personId={id}
              t={t}
            />
          </ErrorBoundary>
        </>
      )}
    </Stack>
  );
};

export default memo(PersonCard);
