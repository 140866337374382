import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceItem } from './slice';

const fetchTasks = createAsyncThunk('task/fetchTasks', async (data) => {
  return api.get(`/tasks${data}`);
});

const fetchActiveTasks = createAsyncThunk('task/fetchActiveTasks', async (data) => {
  return api.get(`/tasks/active${data}`);
});

const fetchTask = createAsyncThunk('task/fetchTask', async (id) => {
  return api.get(`/tasks/${id}`);
});
const createTask = createAsyncThunk('task/createTask', (data) => {
  return api.post('/tasks', data);
});

const updateTask = createAsyncThunk('task/updateTask', (data, { dispatch }) => {
  return api.put(`/tasks/${data.id}`, data).then((res) => {
      dispatch(replaceItem({ collection: 'active', data: res }));
    return res;
  });
});

export default {
  fetchTasks,
  createTask,
  fetchTask,
  updateTask,
  fetchActiveTasks,
};
