import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import { Stack } from '@mui/material';

import store from 'store';
import { groupSelectors, groupThunks } from 'store/ducks/group';

import Loader from 'views/common/Loader';
import Error from 'views/common/Error';
import GroupForm from './components/GroupForm';

import useModal from 'hooks/useModal';

const GroupCard = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [modalOpen, openModal, closeModal] = useModal();
  const group = useSelector(groupSelectors.getGroup());
  const isLoading = useSelector(groupSelectors.groupLoading()) && !group;

  useEffect(() => {
    store.dispatch(groupThunks.fetchGroup(id));
  }, [id]);

  return (
    <Stack justifyContent="center">
      {isLoading && <Loader />}
      {!isLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <GroupForm
            group={group}
            modalOpen={modalOpen}
            closeModal={closeModal}
            openModal={openModal}
            t={t}
            isLoading={isLoading}
          />
        </ErrorBoundary>
      )}
    </Stack>
  );
};
export default memo(GroupCard);
