const getAssignments = () => (state) => state.assignment.list.data;
const assignmentsLoading = () => (state) => state.assignment.list.isLoading;
const getAssignmentsError = () => (state) => state.assignment.list.error;
const getAssignment = () => (state) => state.assignment.item.data;
const assignmentLoading = () => (state) => state.assignment.item.isLoading;
const getAssignmentError = () => (state) => state.assignment.item.error;

export default {
  getAssignments,
  assignmentsLoading,
  getAssignmentsError,
  getAssignment,
  assignmentLoading,
  getAssignmentError,
};
