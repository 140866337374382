import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Menu, Stack, Tooltip, useTheme } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TasksFilter = ({ filter, handleFilter }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t('base.tooltips.taskFilter')}>
        <IconButton
          id="tasks-filter-button"
          aria-controls={open ? 'tasks-filter-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickOpen}
        >
          {filter.my || filter.byMe ? (
            <FilterAltOffOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          ) : (
            <FilterAltOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="tasks-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filter-tasks',
          role: 'listbox',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: 'extra.white',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'extra.white',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <FormGroup>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" px={2}>
            <FormControlLabel
              control={
                <Checkbox sx={{ color: 'primary.main' }} checked={filter.my} onChange={handleFilter} name="my" />
              }
              label={t('types.task.myTasks')}
            />
            <FormControlLabel
              control={
                <Checkbox sx={{ color: 'primary.main' }} checked={filter.byMe} onChange={handleFilter} name="byMe" />
              }
              label={t('types.task.createdByMe')}
            />
          </Stack>
        </FormGroup>
      </Menu>
    </>
  );
};

export default memo(TasksFilter);
