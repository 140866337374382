import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, withFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Autocomplete, debounce, Stack, TextField } from '@mui/material';

import store from 'store';
import { dictionarySelectors, dictionaryThunks } from 'store/ducks/dictionary';
import { personThunks } from 'store/ducks/person';

import { validationPersonForm } from '../../../../utils/schema';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from '../../../common/StyledComponents';

import FormikTextField from 'views/form/FormikTextField';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FormikArrayPhone from 'views/form/FormikArrayPhone';

import HeaderCard from '../../../common/HeaderCard';
import PersonDetails from './PersonDetails';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { DICTIONARY_UPDATE } from 'utils/constants/constants';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { contactSelectors, contactThunks } from 'store/ducks/contact';

const PersonForm = ({
  person,
  modalOpen,
  closeModal,
  openModal,
  values,
  isSubmitting,
  isClient,
  personId,
  setFieldValue,
  selectedContact,
  setSelectedContact,
}) => {
  const { t } = useTranslation();

  const educations = useSelector(dictionarySelectors.getEducations());
  const universities = useSelector(dictionarySelectors.getUniversities());
  const professions = useSelector(dictionarySelectors.getProfessions());
  const roles = useSelector(dictionarySelectors.getRoles());
  const enums = useSelector(dictionarySelectors.getEnums());

  const contacts = useSelector(contactSelectors.getContacts());
  const contactsLoading = useSelector(contactSelectors.contactsLoading());

  const [contactSearchValue, setContactSearchValue] = useState('');

  useEffect(() => {
    if (contactSearchValue) {
      debounce(store.dispatch(contactThunks.fetchContacts(`?q=${contactSearchValue}`)), 400);
    }
  }, [contactSearchValue]);

  useEffect(() => {
    if (selectedContact) {
      setFieldValue('name', selectedContact.name || '');
      setFieldValue('lastName', selectedContact.lastName || '');
      setFieldValue('secondName', selectedContact.secondName || '');
      setFieldValue('phones', selectedContact.phones || []);
      setFieldValue('emails', selectedContact.emails || []);
      setFieldValue('profession', selectedContact.profession || null);
      setFieldValue('education', selectedContact.education || null);
      setFieldValue('university', selectedContact.university || null);
    }
    if (selectedContact === null) {
      setFieldValue('name', '');
      setFieldValue('lastName', '');
      setFieldValue('secondName', '');
      setFieldValue('phones', []);
      setFieldValue('emails', []);
      setFieldValue('profession', null);
      setFieldValue('education', null);
      setFieldValue('university', null);
    }
  }, [selectedContact, setFieldValue]);

  const permission = person ? hasPermission(PERMISSIONS.person.update) : hasPermission(PERMISSIONS.person.create);

  const getCustomContactLabel = (option) => {
    const { name, lastName, secondName } = option;

    const labelParts = [lastName, name, secondName].filter(Boolean);
    return labelParts.join(' ');
  };

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="personForm" style={{ height: '100%' }}>
          {/* --- HEADER OF FORM WITH BUTTONS --- */}
          {/* --- Passing props in Header component for opening PopUp and render buttons depending on new card or current one --- */}
          <HeaderCard
            title={person ? t('types.person.name') : t('types.person.new')}
            data={person}
            isSubmitting={isSubmitting}
            formId="personForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
          />
          {/* --- Wrapper for 3 columns of the form --- */}
          <Stack height="calc(100% - 68px)" gap={1}>
            <FormWrapper>
              {/* --- User column --- */}
              <FormColumnWrapper sx={{ width: '25%' }}>
                <FormColumnTitle>{t('types.person.user')}</FormColumnTitle>

                {!person && (
                  <Autocomplete
                    options={contacts.content}
                    loading={contactsLoading}
                    value={selectedContact}
                    filterOptions={(x) => x}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id || option.name}>
                          {getCustomContactLabel(option)}
                        </li>
                      );
                    }}
                    getOptionLabel={getCustomContactLabel}
                    onInputChange={(e, value) => setContactSearchValue(value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, value) => setSelectedContact(value)}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label={t('base.labels.contact')}
                        placeholder={t('base.placeholders.typeForSearch')}
                      />
                    )}
                  />
                )}
                <FormikAutocomplete
                  name="roles"
                  label={t('base.labels.rolePlural')}
                  options={roles}
                  multiple
                  limitTags={1}
                  // disableCloseOnSelect
                />
                <FormikTextField
                  name="lastName"
                  placeholder={t('base.placeholders.lastName')}
                  label={t('base.labels.lastName')}
                />
                <FormikTextField name="name" placeholder={t('base.placeholders.name')} label={t('base.labels.name')} />
                <FormikTextField
                  name="secondName"
                  placeholder={t('base.placeholders.secondName')}
                  label={t('base.labels.secondName')}
                />
                <FormikAutocomplete name="gender" label={t('base.labels.gender')} options={enums.genders} />
              </FormColumnWrapper>
              {/* --- Personal data column ---*/}
              <FormColumnWrapper sx={{ width: '50%' }}>
                <FormColumnTitle>{t('types.person.personalInfo')}</FormColumnTitle>

                {/* --- Two columns ---  */}
                <Stack direction="row" justifyContent="center" alignItems="flex-start" sx={{ gap: 5 }}>
                  {/* --- first subColumn of personal data column --- */}
                  <Stack
                    sx={{
                      gap: 1.5,
                      width: '50%',
                    }}
                  >
                    <FormikDatePicker name="birthDate" label={t('base.labels.dateOfBirth')} />
                    <FormikTextField
                      name="idCode"
                      label={t('base.labels.idCode')}
                      placeholder={t('base.placeholders.idCode')}
                    />
                    <FormikTextField
                      name="passport"
                      label={t('base.labels.passport')}
                      placeholder={t('base.placeholders.passport')}
                    />
                  </Stack>

                  {/* --- Second subColumn of personal data column ---  */}

                  <Stack sx={{ gap: 1.5, width: '50%' }}>
                    <FormikAutocomplete name="profession" label={t('base.labels.profession')} options={professions} />
                    <FormikAutocomplete name="education" label={t('base.labels.education')} options={educations} />
                    <FormikAutocomplete name="university" label={t('base.labels.university')} options={universities} />

                    <FormikArrayTextField
                      name="documents"
                      values={values.documents}
                      label={t('base.labels.documents')}
                      placeholder={t('base.placeholders.pasteLink')}
                      copyInputAdornment
                    />
                  </Stack>
                </Stack>
              </FormColumnWrapper>
              {/* --- Contact Data column --- */}
              <FormColumnWrapper sx={{ width: '25%' }}>
                <FormColumnTitle>{t('types.person.contact')}</FormColumnTitle>
                <FormikArrayPhone name="phones" label={t('base.labels.phone')} values={values.phones} />
                <FormikArrayTextField
                  name="emails"
                  label={t('base.labels.email')}
                  placeholder={t('base.placeholders.email')}
                  values={values.emails}
                />
                <FormikTextField
                  name="messenger"
                  label={t('base.labels.messenger')}
                  placeholder={t('base.placeholders.messenger')}
                />
                {!person && (
                  <FormikTextField
                    name="password"
                    label={t('base.labels.password')}
                    placeholder={t('base.placeholders.password')}
                  />
                )}
              </FormColumnWrapper>
            </FormWrapper>
            {isClient && <PersonDetails personId={personId} />}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ person = {} }) => ({
      ...person,
      id: person?.id,
      roles: person?.roles || [],
      lastName: person?.contact?.lastName || '',
      name: person?.contact?.name || '',
      secondName: person?.contact?.secondName || '',
      gender: person?.gender || null,
      birthDate: person?.birthDate || null,
      idCode: person?.idCode || '',
      passport: person?.passport || '',
      profession: person?.contact?.profession || null,
      education: person?.contact?.education || null,
      university: person?.contact?.university || null,
      documents: person?.documents || [],
      emails: person?.contact?.emails || [],
      phones: person?.contact?.phones || [],
      messenger: person?.contact?.messenger || '',
      password: '',
    }),
    validationSchema: validationPersonForm,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const valuesForCreatePerson = props.selectedContact
        ? {
            ...values,
            contact: props.selectedContact,
          }
        : { ...values };

      const response = props.person
        ? await store.dispatch(personThunks.updatePerson(values))
        : await store.dispatch(personThunks.createPerson(valuesForCreatePerson));

      if (!response.error) {
        toast.success(
          props.person ? props.t('messages.success.toast.updatePerson') : props.t('messages.success.toast.createPerson')
        );
        setSubmitting(false);

        if (props.person) {
          props.closeModal();
        } else {
          props.navigate(`/persons/${response.payload.id}`);
          //  It allows us to avoid making fetch managers in every case when Contract card is opening.
          //  And it provides us with up to dated list of dictionary --- */
          if (response.meta.requestStatus === 'fulfilled') {
            Object.keys(DICTIONARY_UPDATE).forEach((option) => {
              const thunk = DICTIONARY_UPDATE[option];
              const hasRole = values.roles.some((role) => role.name === option);

              hasRole && store.dispatch(dictionaryThunks[thunk]());
            });
          }
        }
      }
    },
    enableReinitialize: true,
  })(PersonForm)
);
