import { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Stack,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  IconButton,
  Box,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';

import store from 'store';
import { notificationSelectors, notificationThunks } from 'store/ducks/notification';
import { userSelectors } from 'store/ducks/user';

import { MenuClosedIcon, MenuOpenIcon } from 'theme/icons';
import defaultLogo from '../../../theme/icons/logoWhiteDatEducation.svg';
import QuickAddMenu from './QuickAddMenu';
import NotificationsPopUp from 'views/pages/notifications/NotificationsPopUp';

import { getLastNameAndNameLetter } from 'utils/dataTable';
import { hasPermission } from 'utils/roles';
import { SIDEBAR_MENU } from 'utils/constants/menuSidebar';

const DRAWER_WIDTH = 224;

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(10),
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const theme = useTheme();
  const user = useSelector(userSelectors.getCurrentUser());
  const [open, setOpen] = useState(true);

  const rolesList = user.roles
    .map((role) => role.label)
    .slice(0, -1)
    .join(' | ');

  const location = useLocation();
  const path = location.pathname.split('/');
  const rootPath = '/' + path[1];

  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const notifications = useSelector(notificationSelectors.getNotifications());
  const [isNewUnread, setIsNewUnread] = useState(false);

  useEffect(() => {
    store.dispatch(notificationThunks.fetchNotifications(`?&size=30`));
  }, [user]);

  useEffect(() => {
    let interval = setInterval(() => {
      store.dispatch(notificationThunks.fetchUnreadNotifications()).then((res) => {
        setIsNewUnread(res.payload);
      });
    }, 60000);

    isNewUnread && store.dispatch(notificationThunks.fetchNotifications(`?&size=30`));

    return () => clearInterval(interval);
  }, [user, isNewUnread]);

  return (
    <Drawer variant="permanent" open={open}>
      <Stack direction="row" alignItems="center" justifyContent={open ? 'space-between' : 'center'}>
        {open && <img style={{ width: 'calc(100% - 60px)' }} src={theme.logo || defaultLogo} />}
        <IconButton onClick={handleDrawerOpen} sx={{ color: 'extra.white' }}>
          {open ? <MenuOpenIcon /> : <MenuClosedIcon />}
        </IconButton>
      </Stack>
      {/* --- AVATAR --- */}
      <Stack flexDirection={open ? 'row' : 'column'} gap={open ? 1 : 0.5} alignItems="center" my={5} ml={open ? 2 : 0}>
        <NotificationsPopUp notifications={notifications} user={user} />
        <Box>
          <Typography component={'span'} fontSize={18} color="extra.white" display={open ? 'flex' : 'none'}>
            {getLastNameAndNameLetter(user)}
          </Typography>
          {user.roles.length < 1 ? (
            <Typography variant="capture" color="extra.white" display={open ? 'flex' : 'none'}>
              {user.roles?.at(0).label}
            </Typography>
          ) : (
            <Tooltip title={rolesList}>
              <Typography
                sx={{ cursor: 'pointer' }}
                variant="capture"
                color="extra.white"
                display={open ? 'flex' : 'none'}
              >
                {user.roles?.at(-1).label}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Stack>
      <Stack display="flex" direction="column" alignItems="center" justifyContent="flex-start" height="100%">
        {SIDEBAR_MENU.map((menuItem, index) => {
          const menuItemColor = menuItem.to === rootPath ? 'primary.main' : 'extra.white';
          const menuItemBgColor = menuItem.to === rootPath ? 'extra.white' : 'transparent';

          return (
            <Fragment key={menuItem.key}>
              {hasPermission(menuItem.permission) && (
                <ListItem disableGutters sx={{ p: 0, '&:last-child': { mb: 3 } }}>
                  <ListItemButton
                    sx={{
                      py: 0.5,
                      backgroundColor: menuItemBgColor,
                      borderRadius: 1.5,
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: 'secondary.light',
                        '& .MuiListItemIcon-root, & .MuiListItemText-root': { color: 'primary.main' },
                      },
                    }}
                    component={Link}
                    to={menuItem.to}
                    disableGutters
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: 'center',
                        color: menuItemColor,
                      }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={t(`mainMenu.${menuItem.key}`)}
                        sx={{
                          color: menuItemColor,
                          '&>.MuiTypography-root': {
                            fontSize: 18,
                          },
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              )}
              {index === SIDEBAR_MENU.length - 2 && <QuickAddMenu sidebarOpen={open} />}
            </Fragment>
          );
        })}
      </Stack>
    </Drawer>
  );
};

export default memo(Sidebar);
