import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import store from 'store';
import { reportSelectors, reportThunks } from 'store/ducks/report';
import ContractsList from 'views/common/ContractsList';

const CancelledWithActiveInvoicesReport = () => {
  const contracts = useSelector(reportSelectors.getCancelledContractsActiveInvoices());
  const isLoading = useSelector(reportSelectors.cancelledContractsReportActiveInvoicesLoading());
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(reportThunks.fetchCancelledContractsActiveInvoices(`?${query}`));
  }, [query]);

  return (
    <ContractsList
      list={contracts.content}
      isLoading={isLoading}
      totalElements={contracts.totalElements}
      totalPages={contracts.totalPages}
      setSearchParams={setSearchParams}
      pageQuery={pageQuery}
      pageSizeQuery={pageSizeQuery}
      searchQuery={''}
      sortByQuery={sortByQuery}
      sortDirectionQuery={sortDirectionQuery}
    />
  );
};

export default memo(CancelledWithActiveInvoicesReport);
