import { memo } from "react";
import { useSelector } from "react-redux";

import { Autocomplete, Button, Stack, TextField, Typography } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import store from "store";
import { userSelectors, userThunks } from "store/ducks/user";

import UserAvatar from "./UserAvatar";
import { useTranslation } from "react-i18next";

const Account = () => {
  const user = useSelector(userSelectors.getCurrentUser());
  const { t } = useTranslation();
  const { roles } = user;

  return (
    <Stack justifyContent="space-evenly" alignItems="center" height="100%">
      <UserAvatar />
      <Autocomplete
        readOnly
        multiple
        options={roles.map((role) => role.label)}
        defaultValue={roles.map((role) => role.label)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Typography
              sx={{ "&.MuiAutocomplete-tag": { m: 0, ml: "3px" } }}
              variant="capture"
              {...getTagProps({ index })}
              key={index}
            >
              {option}
            </Typography>
          ))
        }
        freeSolo
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps, endAdornment: null }}
              label={roles.length > 1 ? t("base.labels.rolePlural") : t("base.labels.role")}
            />
          );
        }}
      />

      <Button
        variant="text"
        sx={{ color: "primary.main" }}
        startIcon={<LogoutOutlinedIcon />}
        onClick={() => store.dispatch(userThunks.logout())}
      >
        {t("base.dictionary.logout")}
      </Button>
    </Stack>
  );
};

export default memo(Account);
