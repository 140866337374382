const getPersons = () => (state) => state.person.list.data;
const personsLoading = () => (state) => state.person.list.isLoading;
const getPersonsError = () => (state) => state.person.list.error;
const getPerson = () => (state) => state.person.item.data;
const personLoading = () => (state) => state.person.item.isLoading;
const getPersonError = () => (state) => state.person.item.error;
const getPersonContracts = () => (state) => state.person.userContracts.data;
const personContractsLoading = () => (state) => state.person.userContracts.isLoading;
const getPersonContractsError = () => (state) => state.person.userContracts.error;
const getPersonInvoices = () => (state) => state.person.userInvoices.data;
const personInvoicesLoading = () => (state) => state.person.userInvoices.isLoading;
const getPersonInvoicesError = () => (state) => state.person.userInvoices.error;
const getPersonGroups = () => (state) => state.person.userGroups.data;
const personGroupsLoading = () => (state) => state.person.userGroups.isLoading;
const getPersonGroupsError = () => (state) => state.person.userGroups.error;
const getPersonGrades = () => (state) => state.person.userGrades.data;
const personGradesLoading = () => (state) => state.person.userGrades.isLoading;
const getPersonGradesError = () => (state) => state.person.userGrades.error;
const getClients = () => (state) => state.person.clients.data;
const clientsLoading = () => (state) => state.person.clients.isLoading;
const getClientsError = () => (state) => state.person.clients.error;

export default {
  getPersons,
  personsLoading,
  getPersonsError,
  getPerson,
  personLoading,
  getPersonError,
  getPersonContracts,
  personContractsLoading,
  getPersonContractsError,
  getPersonInvoices,
  personInvoicesLoading,
  getPersonInvoicesError,
  getPersonGroups,
  personGroupsLoading,
  getPersonGroupsError,
  getPersonGrades,
  personGradesLoading,
  getPersonGradesError,
  getClients,
  clientsLoading,
  getClientsError,
};
