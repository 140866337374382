import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ContractForm from './components/ContractForm';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';

const NewContract = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [files, setFiles] = useState(['']);

  return (
    <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
      <ContractForm navigate={navigate} files={files} setFiles={setFiles} t={t} />
    </ErrorBoundary>
  );
};

export default memo(NewContract);
