import { createContext, useContext } from 'react';

const PermissionContext = createContext();

export const usePermissionContext = () => {
  return useContext(PermissionContext);
};

export const PermissionContextProvider = ({ children, value }) => {
   return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};
