import { memo } from 'react';
import { useField } from 'formik';
import { DateTime } from 'luxon';
import { usePermissionContext } from 'services/context/permissionContext';
import { TimePicker } from '@mui/x-date-pickers';
import { ClockIcon } from 'theme/icons';

function convertToUserLocalTime(date, time, specifiedTimezone) {
  // Combine date and time into a DateTime object with specified timezone
  const dateTime = DateTime.fromISO(`${date}T${time}`, { zone: specifiedTimezone });

  // Convert to the user's local time zone
  const userLocalDateTime = dateTime.setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);

  // Format the time according to the user's local timezone
  const userLocalTime = userLocalDateTime.toLocaleString(DateTime.TIME_WITH_SECONDS);

  return userLocalTime;
}

const FormikTimePicker = ({ name, label, useTimezone, timeZone, date, ...rest }) => {
  const [field, meta, helpers] = useField(name);
  const hasPermission = usePermissionContext();

  const handleTimeChange = (time) => {
    if (!time) {
      helpers.setValue(null);
      return;
    }

    const formattedTime = time.set({ second: 0 }).toFormat('HH:mm:ss');
    helpers.setValue(formattedTime);
  };

  const defineValue = () => {

    /** --- define the value depending of the 
     * necessity to convert to local timezone or not */
    if (!field.value) return null;

    if (useTimezone) {
      const userLocalTime = convertToUserLocalTime(date, field.value, timeZone);
      return DateTime.fromFormat(userLocalTime, 'HH:mm:ss');
    }

    return DateTime.fromFormat(field.value, 'HH:mm:ss');
  };

  const time = defineValue();

  const error = !!meta.error && meta.touched;
  const helperText = !!meta.error && meta.touched && meta.error;

  return (
    <TimePicker
      sx={{ flex: 2 }}
      {...field}
      {...rest}
      readOnly={!hasPermission}
      ampm={false}
      closeOnSelect
      value={time}
      label={label}
      onChange={handleTimeChange}
      slots={{
        openPickerIcon: ClockIcon,
      }}
      slotProps={{
        textField: {
          InputLabelProps: { shrink: true },
          error: error,
          helperText: helperText,
        },
        actionBar: {
          sx: { display: 'none' },
        },
      }}
    />
  );
};

export default memo(FormikTimePicker);
