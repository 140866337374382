import { addMethod, number, object, array, ref, string, date, lazy, mixed } from 'yup';
import { REG_EXP } from './constants/constants';
import { isValidPhoneNumber } from 'libphonenumber-js';
import i18next from 'i18next';

const t = i18next.t;

const getValidationMessage = (key) => {
  return t(`messages.errors.validation.${key}`);
};
/** --- Custom Yup validator for parsing the date ---  */
const parseDate = (value, originalValue) => {
  const parsedDate = new Date(originalValue);
  return isNaN(parsedDate) ? originalValue : parsedDate;
};

/* --- Strong password validation  --- */

addMethod(string, 'strongPassword', strongPassword);
function strongPassword() {
  return this.test('strongPasswordTest', function (value) {
    const { path, createError } = this;

    if (value === null || value === '' || value === undefined) {
      return true;
    }

    switch (Boolean(value)) {
      case !REG_EXP.lower.test(value):
        return createError({
          path,
          message: getValidationMessage('lowerCase'),
        });
      case !REG_EXP.upper.test(value):
        return createError({
          path,
          message: getValidationMessage('upperCase'),
        });
      case !REG_EXP.digit.test(value):
        return createError({ path, message: getValidationMessage('digit') });
      case !REG_EXP.specChar.test(value):
        return createError({ path, message: getValidationMessage('specChar') });
      default:
        return true;
    }
  });
}

/* --- Phone validation  --- */

addMethod(string, 'phone', phone);

function phone() {
  return this.test('phoneTest', function (value) {
    const { path, createError } = this;
    const isValid = value && isValidPhoneNumber(value);

    switch (Boolean(value)) {
      case !isValid:
        return createError({
          path,
          message: () => getValidationMessage('phone'),
        });

      default:
        return true;
    }
  });
}

/* --- LOGIN FORM --- */

export const logInFormSchema = object({
  email: string(),
  password: string().required(() => getValidationMessage('required')),
});

/* --- FORGOT PASSWORD FORM --- */

export const forgotPasswordSchema = object({
  email: string()
    .email(() => getValidationMessage('email'))
    .required(() => getValidationMessage('required')),
});

/* --- RESET PASSWORD FORM --- */

export const resetPasswordSchema = object({
  token: string().required(() => getValidationMessage('required')),
  password: string()
    .required(() => getValidationMessage('required'))
    .strongPassword()
    .min(8, () => getValidationMessage('passwordMinLength')),

  confirmPassword: string()
    .required(() => getValidationMessage('required'))
    .oneOf([ref('password')], () => getValidationMessage('confirmPassword')),
});

/* --- PERSON FORM --- */

export const validationPersonForm = object({
  roles: array()
    .of(object().shape({ id: number(), name: string(), label: string() }))
    .min(1, () => getValidationMessage('required')),
  lastName: string()
    .min(2, () => getValidationMessage('min2Length'))
    .max(40, () => getValidationMessage('max40Length'))
    .required(() => getValidationMessage('required'))
    .matches(REG_EXP.string, () => getValidationMessage('stringMatch')),
  name: string()
    .min(2, () => getValidationMessage('min2Length'))
    .max(20, () => getValidationMessage('max20Length'))
    .required(() => getValidationMessage('required'))
    .matches(REG_EXP.string, () => getValidationMessage('stringMatch')),
  secondName: string()
    .min(2, () => getValidationMessage('min2Length'))
    .max(20, () => getValidationMessage('max20Length'))
    .matches(REG_EXP.string, () => getValidationMessage('stringMatch')),
  idCode: string().required(() => getValidationMessage('required')),
  passport: string(),
  documents: array().of(string()),
  emails: array().of(string().email(() => getValidationMessage('email'))),
  phones: array().of(string().phone()),
  gender: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  password: string()
    .strongPassword()
    .min(8, () => getValidationMessage('passwordMinLength')),
});

/*  ---------- CONTRACT FORM ------------------- */

export const validationContractForm = object({
  student: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  contractor: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  responsible: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  legalEntity: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  documents: array().of(string()),
  number: string().required(() => getValidationMessage('required')),
  status: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  paymentMethod: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  amount: number()
    .typeError(() => getValidationMessage('numberMatch'))
    .required(() => getValidationMessage('required')),
  program: object().nullable(),
  group: object().nullable(),
  date: mixed()
    .test({
      name: 'parseDate',
      message: 'Invalid date',
      exclusive: true,
      test: (value) => {
        return !isNaN(new Date(value));
      },
      parse: (value) => parseDate(value, value),
    })
    .required(() => getValidationMessage('required')),
});

/*  ---------- INVOICE FORM ------------------- */

export const validationInvoiceForm = object({
  number: string(),
  status: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  contract: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  paymentMethod: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  amountDue: number()
    .typeError(() => getValidationMessage('required'))
    .required(() => getValidationMessage('required')),
  dueDate: mixed()
    .test({
      name: 'parseDate',
      message: 'Invalid date',
      exclusive: true,
      test: (value) => {
        return !isNaN(new Date(value));
      },
      parse: (value) => parseDate(value, value),
    })
    .required(() => getValidationMessage('required')),
  amountPaid: number()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .typeError(() => getValidationMessage('numberMatch')),
});

/*  ---------- GROUP FORM ------------------- */

export const validationGroupForm = object({
  name: string().required(() => getValidationMessage('required')),
  program: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  status: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  format: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  schedule: array().of(object()),
  trainer: object().nullable(),
  mentors: array().nullable(),
  coordinator: object().nullable(),
  startDate: mixed().test({
    name: 'parseDate',
    message: () => getValidationMessage('invalidDate'),
    exclusive: true,
    test: (value) => {
      return !isNaN(new Date(value));
    },
    parse: (value) => parseDate(value, value).nullable(),
  }),
  graduationDate: mixed()
    .test({
      name: 'parseDate',
      message: () => getValidationMessage('invalidDate'),
      exclusive: true,
      test: (value) => {
        return !isNaN(new Date(value));
      },
      parse: (value) => parseDate(value, value),
    })
    .nullable(),
});

/*  ---------- PROGRAM FORM ------------------- */

export const validationProgramForm = object({
  name: string().required(() => getValidationMessage('required')),
  hours: number()
    .typeError(() => getValidationMessage('numberMatch'))
    .required(() => getValidationMessage('required')),
  months: number()
    .typeError(() => getValidationMessage('numberMatch'))
    .transform((cv, ov) => (ov === '' ? undefined : cv)), // allow empty field for number type
  status: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  description: string(),
  image: string(),
});

/*  ---------- LESSON FORM ------------------- */

export const validationLessonForm = object({
  number: number()
    .typeError(() => getValidationMessage('numberMatch'))
    .required(() => getValidationMessage('required')),
  name: string().required(() => getValidationMessage('required')),
  type: object().required(() => getValidationMessage('required')),
  program: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  module: object().nullable(),
  status: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  plannedDuration: number()
    .typeError(() => getValidationMessage('numberMatch'))
    .required(() => getValidationMessage('required')),
});

/*  ---------- SESSION FORM ------------------- */

export const validationSessionForm = object({
  group: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  trainer: object().nullable(),
  lesson: object().nullable()  .required(() => getValidationMessage('required')),
  link: string(),
  date: mixed()
    .test({
      name: 'parseDate',
      message: () => getValidationMessage('invalidDate'),
      exclusive: true,
      test: (value) => {
        return !isNaN(new Date(value));
      },
      parse: (value) => parseDate(value, value),
    })
    .nullable(),
  duration: lazy((value) => (value === '' ? string() : number().typeError(() => getValidationMessage('numberMatch')))),
  record: string(),
});

/*  ---------- TASK FORM ------------------- */

export const validationTaskForm = object({
  name: string().required(() => getValidationMessage('required')),
  description: string(),
  responsible: array()
    .min(1, () => getValidationMessage('required'))
    .of(object())
    .required(() => getValidationMessage('required')),
  status: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  date: date().nullable(),
  dueDate: mixed()
    .test({
      name: 'parseDate',
      message: getValidationMessage('invalidDate'),
      exclusive: true,
      test: (value) => {
        return !isNaN(new Date(value));
      },
      parse: (value) => parseDate(value, value),
    })
    .nullable()
    .required(() => getValidationMessage('required')),
  lead: object().nullable(),
});

/*  ---------- LEGAL ENTITY DICTIONARY FORM ------------------- */

export const validationLegalEntityForm = object({
  name: string().required(() => getValidationMessage('required')),
  idCode: string().required(() => getValidationMessage('required')),
  address: string().required(() => getValidationMessage('required')),
  iban: string().required(() => getValidationMessage('required')),
  bank: string().required(() => getValidationMessage('required')),
  owner: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  taxSystem: string().required(() => getValidationMessage('required')),
  documents: array().of(string()),
});

/* --- CHANGE PASSWORD FORM --- */

export const validationChangePasswordForm = object({
  newPassword: string()
    .required(() => getValidationMessage('required'))
    .strongPassword()
    .min(8, () => getValidationMessage('passwordMinLength')),

  confirmPassword: string()
    .required(() => getValidationMessage('required'))
    .oneOf([ref('newPassword')], () => getValidationMessage('confirmPassword')),
});

/** --- BULK SESSIONS --- */

export const validationBulkSessionsForm = object({
  group: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  startDate: mixed()
    .test({
      name: 'parseDate',
      message: () => getValidationMessage('invalidDate'),
      exclusive: true,
      test: (value) => {
        return !isNaN(new Date(value));
      },
      parse: (value) => parseDate(value, value),
    })
    .required(() => getValidationMessage('required')),
  module: object().nullable(),
});
