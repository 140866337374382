import { memo, useEffect } from 'react';
import { userThunks } from 'store/ducks/user';
import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { Form, withFormik } from 'formik';
import store from 'store';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { resetPasswordSchema } from 'utils/schema';
import PasswordInput from 'views/form/PasswordInput';
import { useTranslation } from 'react-i18next';

import defaultLogo from '../../../theme/icons/dan-logo-color.svg';
import { ErpTextIcon } from 'theme/icons';

const ResetPassword = ({ setFieldValue, isSubmitting }) => {
  const { token } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setFieldValue('token', token);
  }, [setFieldValue, token]);

  return (
    <Stack
      justifyContent="center"
      direction="column"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: '30%',
        left: '50%',
        transform: ' translate(-50%, -30%)',
      }}
    >
      <Stack sx={{ mb: 6 }} alignItems="center" gap={1} width={'100%'}>
        <img style={{ width: 300 }} src={theme.logo || defaultLogo} />
        <ErpTextIcon  width={280} height={16} />
      </Stack>

      {/* --- RESET PASSWORD FORM --- */}

      <Stack
        alignItems="center"
        boxShadow="0px 0px 12px rgba(0, 0, 0, 0.1)"
        sx={{
          width: '400px',
          backgroundColor: 'extra.white',
          borderRadius: 2,
        }}
      >
        <Typography variant="h1" color={'text.dark'} pt={5} pb={5}>
          {t('base.buttons.setNewPassword')}
        </Typography>
        <Box component={Form}>
          <Stack direction="column" gap={3} width={340}>
            <PasswordInput name="password" placeholder="Password" />
            <PasswordInput name="confirmPassword" placeholder="Confirm Password" />
          </Stack>
          <Stack justifyContent="center" alignItems="center" flexDirection="column" sx={{ p: 0, mt: 3 }}>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              sx={{
                backgroundColor: 'primary.light',
                width: '100%',
              }}
            >
              {t('base.buttons.resetPassword')}
            </Button>

            <Typography p="32px 0" color="text.dark">
              {t('auth.backTo')}{' '}
              <Link
                component={RouterLink}
                to="/login"
                underline="hover"
                sx={{
                  color: 'primary.main',
                  fontFamily: 'inherit',
                }}
              >
                {t('auth.loginLink')}
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      password: '',
      confirmPassword: '',
      token: '',
    }),
    validationSchema: resetPasswordSchema,
    handleSubmit: (data, helpers) => {
      const resetPassword = { token: data.token, password: data.password };
      store.dispatch(userThunks.resetPassword(resetPassword)).then((res) => {
        res && helpers.setSubmitting(false);
      });
    },
  })(ResetPassword)
);
