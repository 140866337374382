import { Fragment } from 'react';
import { FieldArray } from 'formik';
import FormikAutocomplete from './FormikAutocomplete';
import FormikTimePicker from './FormikTimePicker';

import { usePermissionContext } from 'services/context/permissionContext';

import { IconButton, Stack, Tooltip, useTheme } from '@mui/material';

import { ReactComponent as AddIcon } from 'theme/icons/plusIcon.svg';
import { ReactComponent as RemoveIcon } from 'theme/icons/minusIcon.svg';
import FormikTextField from './FormikTextField';
import { useTranslation } from 'react-i18next';

const FormikArrayDateTime = ({ name, values, label, daysOptions }) => {
  const hasPermission = usePermissionContext();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values && values.length > 0 ? (
            values.map((option, index) => (
              <Fragment key={index}>
                <Stack direction="row" gap={theme.spacing(2)} alignItems="flex-end">
                  <FormikAutocomplete
                    name={`${name}.${index}.day`}
                    label="Day of week"
                    options={daysOptions}
                    sx={{ flex: 2 }}
                  />
                  <FormikTimePicker name={`${name}.${index}.time`} label="Time" />
                  <Stack direction="row" sx={{ flex: 1 }}>
                    {hasPermission && (
                      <Tooltip title={t('base.tooltips.addField') + ' ' + name}>
                        <IconButton sx={{ pb: 0.5 }} onClick={() => arrayHelpers.push('')}>
                          <AddIcon stroke={theme.palette.primary.main} fill="none" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {values.length > 1 && (
                      <Tooltip title={t('base.tooltips.removeField') + ' ' + name}>
                        <IconButton sx={{ pb: 0.5 }} onClick={() => arrayHelpers.remove(index)}>
                          <RemoveIcon stroke={theme.palette.primary.main} fill="none" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              </Fragment>
            ))
          ) : (
            <FormikTextField
              label={label}
              name={`${name}`}
              placeholder={t('base.placeholders.addSchedule')}
              onFocus={() => arrayHelpers.push('')}
            />
          )}
        </>
      )}
    />
  );
};

export default FormikArrayDateTime;
