import { memo } from 'react';
import { Stack, Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <Stack sx={{ width: '100%' }} justifyContent="center" alignItems="center">
      <Typography color="warning.main" sx={{ mb: 2, fontSize: 42, fontWeight: 700 }}>
        Dashboard is Coming soon...
      </Typography>
      <Typography variant="h1" color="text.dark" sx={{ mb: 5 }}>
        But for now, please choose a report
      </Typography>
      <img src="/construction.png" alt="under construction" style={{ width: '260px', height: 'auto' }} />
    </Stack>
  );
};

export default memo(Dashboard);
