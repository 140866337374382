import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, useTheme } from '@mui/material';

import DataTable from './DataTable';
import { DataTableWrapper } from './StyledComponents';

import { getShortName } from 'utils/dataTable';


const GradesList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  autoPageSize,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      flex: 0.2,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/grades/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    {
      field: 'student',
      headerName: t('base.labels.student'),
      filterable: false,
      renderCell: ({ row: { student } }) => {
        return <Typography>{getShortName(student)}</Typography>;
      },
      flex: 0.4,
    },
    {
      field: 'assignment',
      headerName: t('base.labels.assignment'),
      filterable: false,
      renderCell: ({ row: { assignment } }) => {
        return <Typography>{assignment?.name}</Typography>;
      },
      flex: 0.3,
    },
    {
      field: 'program',
      headerName: t('base.labels.program'),
      filterable: false,
      flex: 0.4,
      renderCell: ({ row: { group } }) => {
        return <Typography>{group.program.name}</Typography>;
      },
    },
    {
      field: 'group',
      headerName: t('base.labels.group'),
      filterable: false,
      flex: 0.2,
      renderCell: ({ row: { group } }) => {
        return <Typography>{group.name}</Typography>;
      },
    },
    {
      field: 'deadline',
      headerName: t('base.labels.deadline'),
      filterable: false,
      flex: 0.2,
    },
    {
      field: 'submissionDate',
      headerName: t('base.labels.submissionDate'),
      filterable: false,
      flex: 0.3,
    },
    {
      field: 'grade',
      headerName: t('base.labels.grade'),
      filterable: false,
      flex: 0.2,
    },
  ];
  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
        autoPageSize={autoPageSize}
      />
    </DataTableWrapper>
  );
};

export default memo(GradesList);
