import { Paragraph, AlignmentType, TextRun, SectionType } from 'docx';
import {
  APPENDIX_TWO_CHAPTER_ONE,
  APPENDIX_TWO_CHAPTER_THREE,
  APPENDIX_TWO_CHAPTER_TWO,
} from 'services/generateContract/contractConditions';
import { createParagraph } from './createParagraph';

export const createAppendixTwo = (contract) => {
  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children: [
      /** --- CHAPTER 1 DYNAMIC --- */
      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.CENTER,
        children: [new TextRun({ text: 'ПРАВИЛА НАДАННЯ ПОСЛУГ', bold: true })],
      }),
      new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [new TextRun({ text: 'І. ЗАГАЛЬНІ ПОЛОЖЕННЯ', bold: true })],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: '1.1. Терміни, використані у тексті цих Правил надання послуг (далі – «Правила»), вживаються у наступному значенні:',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `1.1.1. Виконавець – ${contract?.legalEntity?.name}.`,
          }),
        ],
      }),
      /** --- CHAPTER 1 STATIC --- */
      ...APPENDIX_TWO_CHAPTER_ONE.paragraphs
        .map((paragraph) => {
          const arr = [];

          arr.push(createParagraph(paragraph));
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),

      /** --- CHAPTER 2  --- */
      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.CENTER,
        children: [new TextRun({ text: APPENDIX_TWO_CHAPTER_TWO.chapter, bold: true })],
      }),
      ...APPENDIX_TWO_CHAPTER_TWO.paragraphs
        .map((paragraph) => {
          const arr = [];

          arr.push(createParagraph(paragraph));
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),

      /** --- CHAPTER 3  --- */

      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.CENTER,
        children: [new TextRun({ text: APPENDIX_TWO_CHAPTER_THREE.chapter, bold: true })],
      }),
      ...APPENDIX_TWO_CHAPTER_THREE.paragraphs
        .map((paragraph) => {
          const arr = [];

          arr.push(createParagraph(paragraph));
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),

      /** ---  SIGNING FIELD  ---  */
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          before: 350,
        },
        children: [
          new TextRun({
            text: 'З правилами надання послуг ознайомлений (а) __________________/',
          }),
        ],
      }),
    ],
  };
};
