import { Table, TableRow, TableCell, Paragraph, WidthType, AlignmentType, TextRun, VerticalAlign } from 'docx';

export const createBankDetails = (customer, legalEntity, isRules = true) => {
  return new Table({
    columnWidths: [4505, 4505],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [new TextRun({ text: 'Замовник', bold: true })],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [new TextRun({ text: 'Виконавець', bold: true })],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `${customer?.contact?.lastName} ${customer?.contact?.name} ${customer?.contact?.secondName}`,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `паспорт ${customer?.passport}`,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `iдентифікаційний номер ${customer?.idCode}`,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [new TextRun({ text: `Контактний телефон: ${customer?.contact?.phones?.[0]}` })],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [new TextRun({ text: `E-mail: ${customer?.contact?.emails?.[0]}` })],
              }),

              isRules &&
                new Paragraph({
                  spacing: {
                    before: 500,
                  },
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `З запланованою програмою занять, з Правилами надання Послуг за цим Договором ознайомлений(а).`,
                    }),
                  ],
                }),
              new Paragraph({
                spacing: {
                  before: 500,
                  after: 500,
                },

                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `${customer?.contact?.lastName} ${customer?.contact?.name[0]}.${customer?.contact?.secondName?.[0]}. ______________ `,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.TOP,
          }),
          new TableCell({
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `${legalEntity?.name}`,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `код ЄДРПОУ ${legalEntity?.idCode},`,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `Адреса: ${legalEntity?.address}`,
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [new TextRun({ text: `рах № ${legalEntity?.iban}` })],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [new TextRun({ text: `в ${legalEntity?.bank}, м.Київ` })],
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [new TextRun({ text: `${legalEntity?.taxSystem}` })],
              }),
              new Paragraph({
                spacing: {
                  after: 500,
                  before: 500,
                },
                alignment: AlignmentType.LEFT,
                children: [new TextRun({ text: `${legalEntity?.name} ______________ ` })],
              }),
            ],
            verticalAlign: VerticalAlign.TOP,
          }),
        ],
      }),
    ],
  });
};
