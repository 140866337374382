import { Autocomplete, Chip, TextField } from '@mui/material';
import { useField } from 'formik';
import { memo, useEffect } from 'react';
import { usePermissionContext } from 'services/context/permissionContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CloseIcon } from 'theme/icons';

const FormikAutocomplete = ({
  name,
  options,
  label,
  multiple,
  getCustomLabel,
  forReadOnly,
  loading,
  defaultValue,
  limitTags = 2,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const showError = meta.touched && Boolean(meta.error);
  const hasPermission = usePermissionContext();

  const getValuesFromOptions = (value) => {
    return (
      options?.filter((option) =>
        option.id ? value.some(({ id }) => option.id === id) : value.some(({ name }) => option.name === name)
      ) || []
    );
  };

  const getValueFromOptions = (value) => {
    return options?.find((option) => (option.id ? option.id === value?.id : option.name === value?.name)) || null;
  };

  useEffect(() => {
    if (defaultValue) {
      const defaultValueOption = getValueFromOptions(defaultValue);
      helpers.setValue(defaultValueOption);
    }
  }, [defaultValue, helpers]);

  const handleTagDelete = (deletedOption) => {
    const updatedValue = field.value.filter((option) => option.id !== deletedOption.id);
    helpers.setValue(updatedValue);
  };

  return (
    <Autocomplete
      {...field}
      {...rest}
      onChange={(_, value) => {
        helpers.setValue(value !== null ? value : null);
      }}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: 'primary.lighter' }} />}
      multiple={multiple}
      limitTags={limitTags}
      readOnly={!hasPermission || forReadOnly}
      renderTags={(value, getTagProps) =>
        value.map((option) => {
          return (
            <Chip
              deleteIcon={<CloseIcon width={20} height={20} />}
              sx={{
                fontSize: 14,
                height: 24,
                '& .MuiChip-label': { pl: 0, pr: 1 },
                border: 'none',
              }}
              {...getTagProps({
                label: getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name,
              })}
              key={option.name}
              variant="outlined"
              label={getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name}
              onDelete={() => handleTagDelete(option)}
            />
          );
        })
      }
      loading={loading}
      options={options}
      value={multiple ? getValuesFromOptions(field.value) : getValueFromOptions(field.value)}
      getOptionLabel={(option) => {
        return getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id || option.name}>
            {getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={rest.placeholder ? rest.placeholder : 'Choose'}
            InputLabelProps={{ shrink: true }}
            label={label}
            name={name}
            error={showError}
            helperText={(meta.touched && meta.error?.id) || (meta.touched && meta.error)}
          />
        );
      }}
    />
  );
};

export default memo(FormikAutocomplete);
