import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataInitialState from 'store/utils/loadDataInitialState';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';

const userSlice = createSlice({
  name: 'dictionarySlice',
  initialState: {
    enums: loadDataInitialState({
      contractStatuses: [],
      groupStatuses: [],
      activeStatuses: [],
      invoiceStatuses: [],
      paymentMethods: [],
      groupFormats: [],
      daysOfWeek: [],
      lessonTypes: [],
      genders: [],
      assignmentRequirements: [],
      assignmentTypes: [],
    }),
    educations: loadDataInitialState([]),
    universities: loadDataInitialState([]),
    professions: loadDataInitialState([]),
    roles: loadDataInitialState([]),
    managers: loadDataInitialState([]),
    legalEntities: loadDataInitialState([]),
    trainers: loadDataInitialState([]),
    mentors: loadDataInitialState([]),
    coordinators: loadDataInitialState([]),
    employees: loadDataInitialState([]),
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchEnums, 'enums');
    loadDataExtraReducer(builder, thunks.fetchEducations, 'educations');
    loadDataExtraReducer(builder, thunks.fetchUniversities, 'universities');
    loadDataExtraReducer(builder, thunks.fetchProfessions, 'professions');
    loadDataExtraReducer(builder, thunks.fetchRoles, 'roles');
    loadDataExtraReducer(builder, thunks.fetchManagers, 'managers');
    loadDataExtraReducer(builder, thunks.fetchLegalEntities, 'legalEntities');
    loadDataExtraReducer(builder, thunks.fetchTrainers, 'trainers');
    loadDataExtraReducer(builder, thunks.fetchMentors, 'mentors');
    loadDataExtraReducer(builder, thunks.fetchCoordinators, 'coordinators');
    loadDataExtraReducer(builder, thunks.fetchEmployees, 'employees');
  },
});

export const { addItem, replaceItem, deleteItem } = userSlice.actions;
export default userSlice.reducer;
