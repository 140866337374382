import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import { DateTime } from 'luxon';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const reportSlice = createSlice({
  name: 'reportSlice',
  initialState: {
    contractReport: loadDataPaginatedState(),
    invoiceReport: loadDataPaginatedState(),
    sessionReport: loadDataPaginatedState(),
    filterByManager: null,
    filterByTrainer: null,
    filterByPeriod: {
      from: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
      to: DateTime.local().endOf('month').toFormat('yyyy-MM-dd'),
    },
    query: null, // state is using for generating report by button click
    sessionsDuration: loadDataInitialState(),
  },
  reducers: {
    addFilterByManager: (state, action) => {
      state.filterByManager = action.payload;
    },
    addFilterByTrainer: (state, action) => {
      state.filterByTrainer = action.payload;
    },
    addFilterByPeriod: (state, action) => {
      state.filterByPeriod = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    clearSessionsDuration: (state) => {
      state.sessionsDuration = loadDataInitialState();
    },
    clearSessionReport: (state) => {
      state.sessionReport = loadDataPaginatedState();
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchContractsNoGroup, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchPausedContractsActiveInvoices, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchCancelledContractsActiveInvoices, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchContractsNoDocs, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchInvoicesForPeriod, 'invoiceReport');
    loadDataExtraReducer(builder, thunks.fetchOverdueInvoices, 'invoiceReport');
    loadDataExtraReducer(builder, thunks.fetchSessionsWithFilters, 'sessionReport');
    loadDataExtraReducer(builder, thunks.fetchSessionsDuration, 'sessionsDuration');
  },
});

export const {
  addFilterByTrainer,
  addFilterByManager,
  addFilterByPeriod,
  setQuery,
  clearSessionsDuration,
  clearSessionReport,
} = reportSlice.actions;
export default reportSlice.reducer;
