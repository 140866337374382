const getEnums = () => (state) => state.dictionary.enums.data;
const enumsLoading = () => (state) => state.dictionary.enums.isLoading;
const getEducations = () => (state) => state.dictionary.educations.data;
const getUniversities = () => (state) => state.dictionary.universities.data;
const getProfessions = () => (state) => state.dictionary.professions.data;
const getRoles = () => (state) => state.dictionary.roles.data;
const getManagers = () => (state) => state.dictionary.managers.data;
const getLegalEntities = () => (state) => state.dictionary.legalEntities.data;
const getTrainers = () => (state) => state.dictionary.trainers.data;
const getMentors = () => (state) => state.dictionary.mentors.data;
const mentorsLoading = () => (state) => state.dictionary.mentors.isLoading;
const getCoordinators = () => (state) => state.dictionary.coordinators.data;
const getEmployees = () => (state) => state.dictionary.employees.data;
const employeesLoading = () => (state) => state.dictionary.employees.isLoading;

export default {
  getEnums,
  enumsLoading,
  getEducations,
  getUniversities,
  getProfessions,
  getRoles,
  getManagers,
  getLegalEntities,
  getTrainers,
  getMentors,
  mentorsLoading,
  getCoordinators,
  getEmployees,
  employeesLoading,
};
