import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import TaskForm from './components/TaskForm';
import Error from 'views/common/Error';

const NewTask = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState(['']);
  const { t } = useTranslation();

  return (
    <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
      <TaskForm navigate={navigate} files={files} setFiles={setFiles} t={t} />
    </ErrorBoundary>
  );
};

export default memo(NewTask);
