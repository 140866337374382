import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replacePerson } from './slice';

const fetchPersons = createAsyncThunk('person/fetchPersons', async (data) => {
  return api.get(`/users${data}`);
});
const fetchPerson = createAsyncThunk('person/fetchPerson', async (id) => {
  return api.get(`/users/${id}`);
});
const createPerson = createAsyncThunk('person/createPerson', (data) => {
  return api.post('/users', data);
});

const updatePerson = createAsyncThunk('person/updatePerson', (data, { dispatch }) => {
  return api.put(`/users/${data.id}`, data).then((res) => {
    dispatch(replacePerson({ data: res }));
    return res;
  });
});

const fetchPersonContracts = createAsyncThunk('person/fetchPersonContracts', async (id) => {
  return api.get(`/users/${id}/contracts`);
});

const fetchPersonInvoices = createAsyncThunk('person/fetchPersonInvoices', async (id) => {
  return api.get(`/users/${id}/invoices`);
});

const fetchPersonGroups = createAsyncThunk('person/fetchPersonGroups', async (id) => {
  return api.get(`/users/${id}/groups`);
});

const fetchPersonGrades = createAsyncThunk('person/fetchPersonGrades', async (id) => {
  return api.get(`/users/${id}/grades`);
});

const fetchClients = createAsyncThunk('person/fetchClients', async (data) => {
  return api.get(`/users/clients${data}`);
});


export default {
  fetchPersons,
  createPerson,
  fetchPerson,
  updatePerson,
  fetchPersonContracts,
  fetchPersonInvoices,
  fetchPersonGroups,
  fetchPersonGrades,
  fetchClients,
};
