import { Button, Stack, useTheme } from '@mui/material';
import SettingsMenu from './SettingsMenu';
import SearchBar from 'views/common/SearchBar';
import { memo } from 'react';

const HeaderSettings = ({ search, handleButtonClick, buttonTitle }) => {
  const theme = useTheme();
  
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      px={2}
      bgcolor={'extra.white'}
      sx={{ borderBottomLeftRadius: theme.spacing(2), borderBottomRightRadius: theme.spacing(2) }}
    >
      <SettingsMenu />

      <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
        {buttonTitle && (
          <Button onClick={handleButtonClick} variant="contained" sx={{ width: 'fit-content' }}>
            {buttonTitle}
          </Button>
        )}
        {search && <SearchBar />}
      </Stack>
    </Stack>
  );
};

export default memo(HeaderSettings);
