const getPrograms = () => (state) => state.program.list.data;
const programsLoading = () => (state) => state.program.list.isLoading;
const getProgramsError = () => (state) => state.program.list.error;
const getProgram = () => (state) => state.program.item.data;
const programLoading = () => (state) => state.program.item.isLoading;
const getProgramError = () => (state) => state.program.item.error;
const getProgramLessons = () => (state) => state.program.programLessons.data;
const programLessonsLoading = () => (state) => state.program.programLessons.isLoading;
const getProgramLessonsError = () => (state) => state.program.programLessons.error;
const getProgramAssignments = () => (state) => state.program.programAssignments.data;
const programAssignmentsLoading = () => (state) => state.program.programAssignments.isLoading;
const getProgramAssignmentsError = () => (state) => state.program.programAssignments.error;

export default {
  getPrograms,
  programsLoading,
  getProgramsError,
  getProgram,
  programLoading,
  getProgramError,
  getProgramLessons,
  programLessonsLoading,
  getProgramLessonsError,
  getProgramAssignments,
  programAssignmentsLoading,
  getProgramAssignmentsError,
};
