import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack, TextField, useTheme } from '@mui/material';

const ImageUploader = ({ file, setFile, isSubmitting }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChooseFile = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <>
      <Stack direction="row" gap={theme.spacing(2)} width="100%" alignItems="center">
        <TextField
          label="Image"
          value={file?.name || ''}
          placeholder={t('base.placeholders.chooseFile')}
          sx={{ flex: 1 }}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
        />
        <label htmlFor="upload-image">
          <Button sx={{ minWidth: 0 }} variant="contained" component="span" disabled={isSubmitting}>
            {t('base.buttons.browse')}
          </Button>
          <input
            id="upload-image"
            hidden
            accept="image/*"
            type="file"
            onChange={handleChooseFile}
            disabled={isSubmitting}
          />
        </label>
      </Stack>
    </>
  );
};

export default memo(ImageUploader);
