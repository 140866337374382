const getInvoices = () => (state) => state.invoice.list.data;
const invoicesLoading = () => (state) => state.invoice.list.isLoading;
const getInvoicesError = () => (state) => state.invoice.list.error;
const getInvoice = () => (state) => state.invoice.item.data;
const invoiceLoading = () => (state) => state.invoice.item.isLoading;
const getInvoiceError = () => (state) => state.invoice.item.error;


export default {
  getInvoices,
  invoicesLoading,
  getInvoicesError,
  getInvoice,
  invoiceLoading,
  getInvoiceError,
};
