import React, { memo, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Stack,
  useTheme,
} from '@mui/material';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useSelector } from 'react-redux';
import { accessSelectors, accessThunks } from 'store/ducks/access';
import store from 'store';
import Loader from 'views/common/Loader';
import { toast } from 'react-toastify';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { PERMISSION_DEPENDENCIES } from 'utils/constants/permissions';
import { splitByUnderScoreAndCheckForSecondWordInEntity, validatePermissionDependencies } from 'utils/roles';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PermissionsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const accessTable = useSelector(accessSelectors.getAccess());
  const accessLoading = useSelector(accessSelectors.accessLoading());
  const enums = useSelector(dictionarySelectors.getEnums());
  const [data, setData] = useState([]);
  const [missingAuth, setMissingAuth] = useState([]);
  const [, setProps] = useOutletContext();

  useEffect(() => {
    store.dispatch(accessThunks.fetchAccess());
  }, []);

  useEffect(() => {
    accessTable && !accessLoading && setData(accessTable.filter((item) => item.role.name !== 'ROLE_CLIENT'));
  }, [accessTable, accessLoading]);

  const handleCheckboxChange = (event, roleName, entity, property) => {
    /** --- We join the Athority with underscore in order to pass it to backEnd and handle  permissions change --- */
    const authority = `${property.toUpperCase()}_${entity.split(' ').join('_').toUpperCase()}`;
    setData((prevData) =>
      prevData.map((item) => {
        if (item.role.name === roleName) {
          return {
            ...item,
            authorities: event.target.checked
              ? [...item.authorities, authority]
              : item.authorities.filter((auth) => auth !== authority),
          };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    /** --- Looking for missing Authorities and show authorities dependencies for user
     *  in order to provide the correct access for different connected entities  */
    const missingAuthorities = validatePermissionDependencies(data, PERMISSION_DEPENDENCIES);
    if (missingAuthorities) {
      const missingAuthoritiesArray = [];

      for (const auth of missingAuthorities) {
        const permissions = auth?.missing.map((el) => {
          const arr = el.split('_');
          return `${arr[0]} ${arr[1]}`;
        });

        const existingEntry = missingAuthoritiesArray.find((item) => item.keyAuth === auth.authorityKey);

        if (existingEntry) {
          existingEntry.permissions.push(...permissions);
        } else {
          missingAuthoritiesArray.push({
            keyAuth: auth.authorityKey,
            permissions,
            role: auth.role,
          });
        }
      }
      setMissingAuth(missingAuthoritiesArray);
    }
  }, [data]);

  const handleSubmit = () => {
    store
      .dispatch(accessThunks.createAccess(data))
      .then((res) => !res.error && toast.success(t('messages.success.toast.permissionUpdate')));
  };

  useEffect(() => {
    data &&
      setProps((prevProps) => ({
        ...prevProps,
        buttonTitle: t('base.buttons.updatePermissions'),
        handleButtonClick: handleSubmit,
      }));

    return () => {
      setProps((prevProps) => ({ ...prevProps, buttonTitle: '', handleButtonClick: null }));
    };
  }, [data]);

  /** Create an unique entities in order to show column with entities without underscore in cell names of table */

  const uniqueEntities = enums.authorities
    ? Array.from(
        new Set(enums?.authorities?.map((authority) => splitByUnderScoreAndCheckForSecondWordInEntity(authority)))
      )
    : [];

  return (
    <Stack height="calc(100% - 76px)" sx={{ width: '100%', p: 2, backgroundColor: 'extra.white', borderRadius: 2 }}>
      {accessLoading && <Loader />}
      {!accessLoading && (
        <>
          <TableContainer sx={{ height: '100%', overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell size="small" align="center">
                    {t('types.settings.permission.entity')}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {t('types.settings.permission.name')}
                  </TableCell>
                  {data.map((item) => (
                    <TableCell sx={{ py: 1 }} size="small" key={item.role.name} align="center">
                      {item.role.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueEntities.map((entity) => {
                  const entityAuthorities = enums.authorities.filter(
                    (authority) => splitByUnderScoreAndCheckForSecondWordInEntity(authority) === entity
                  );
                  return (
                    <React.Fragment key={entity}>
                      <TableRow>
                        <TableCell size="small" align="center" rowSpan={entityAuthorities.length + 1}>
                          {entity}
                        </TableCell>
                      </TableRow>
                      {entityAuthorities.map((authority) => {
                        const property = authority.split('_')[0];
                        return (
                          <TableRow key={property}>
                            <TableCell size="small" align="center">
                              {property}
                            </TableCell>
                            {data.map((item) => (
                              <TableCell size="small" key={item.role.name} align="center">
                                <Checkbox
                                  size="small"
                                  checked={item.authorities.includes(authority)}
                                  onChange={(e) => handleCheckboxChange(e, item.role.name, entity, property)}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {missingAuth && (
            <Box sx={{ width: '100%' }}>
              <List>
                {missingAuth.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>{<ReportGmailerrorredOutlinedIcon sx={{ color: 'warning.main' }} />}</ListItemIcon>
                    <ListItemText
                      primary={`${t('types.settings.permission.role')} ${item.role} ${t(
                        'types.settings.permission.for'
                      ).toLowerCase()} ${item.keyAuth.split('_').join(' ')} ${t(
                        'types.settings.permission.isRequired'
                      )}:`}
                      primaryTypographyProps={{
                        component: 'span',
                        style: { fontWeight: 600, color: theme.palette.text.dark, fontSize: 14 },
                      }}
                      secondary={` ${item.permissions.join(', ')}`}
                      secondaryTypographyProps={{
                        component: 'span',
                        style: { color: theme.palette.text.main },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default memo(PermissionsPage);
