import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RemoveIcon } from 'theme/icons/minusIcon.svg';

const AddRemoveInputAdornment = ({ name, remove }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  return (
    <Box>
      <Tooltip title={t('base.tooltips.removeField') + ' ' + name}>
        <IconButton sx={{ p: 0.25 }} onClick={remove}>
          <RemoveIcon stroke={theme.palette.primary.main} fill="none" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(AddRemoveInputAdornment);
