import { Document, Paragraph, TextRun, AlignmentType, SectionType } from 'docx';
import { createContractNumber } from './createContractNumber';
import { createContractDate } from './createContractDate';
import { createLegalEntity } from './createLegalEntity';
import { createCustomerPart } from './createCustomerPart';
import { createBankDetails } from './createBankDetails';

import {
  CHAPTER_ONE,
  CHAPTER_THREE,
  CHAPTER_TWO,
  CHAPTER_FOUR,
  CHAPTER_FIVE,
  CHAPTER_SIX,
  CHAPTER_SEVEN,
  CHAPTER_EIGHT,
} from 'services/generateContract/contractConditions';
import { createAppendixThree } from './createAppendixThree';
import { createAppendixHeader } from './createAppendixHeader';
import { createAppendixTwo } from './createAppendixTwo';
import { createAppendixOne } from './createAppendixOne';
import { createChapterNine } from './createChapterNine';

const generateContract = (contract, customer) => {
  const doc = new Document({
    styles: {
      default: {
        document: {
          run: {
            size: '11pt',
            font: 'Calibri',
          },
        },
      },
    },
    sections: [
      /** --- CONTRACT HEADER --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          createContractNumber(contract?.number),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: 'ПРО НАДАННЯ ПОСЛУГ', bold: true })],
          }),
          createContractDate(contract?.createdAt),
          createLegalEntity(contract?.legalEntity),
          createCustomerPart(customer),
          new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            indent: {
              firstLine: 500,
            },
            children: [
              new TextRun({
                text: 'Виконавець та Замовник надалі разом іменовані як «Сторони», а кожен окремо – «Сторона», уклали цей Договір про надання послуг (далі – «Договір») про нижчевикладене:',
              }),
            ],
          }),
        ],
      },
      /** --- CHAPTER 1 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_ONE.chapter, bold: true })],
          }),
          ...CHAPTER_ONE.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 2 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_TWO.chapter, bold: true })],
          }),
          ...CHAPTER_TWO.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 3 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_THREE.chapter, bold: true })],
          }),
          ...CHAPTER_THREE.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 4 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_FOUR.chapter, bold: true })],
          }),
          ...CHAPTER_FOUR.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 5 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_FIVE.chapter, bold: true })],
          }),
          ...CHAPTER_FIVE.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 6 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_SIX.chapter, bold: true })],
          }),
          ...CHAPTER_SIX.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 7 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_SEVEN.chapter, bold: true })],
          }),
          ...CHAPTER_SEVEN.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 8 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: CHAPTER_EIGHT.chapter, bold: true })],
          }),
          ...CHAPTER_EIGHT.paragraphs
            .map((paragraph) => {
              const arr = [];

              arr.push(createParagraph(paragraph));
              return arr;
            })
            .reduce((prev, curr) => prev.concat(curr), []),
        ],
      },
      /** --- CHAPTER 9 --- */
      createChapterNine(contract),

      /** --- CHAPTER 10 --- */
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: 'Стаття 10. АДРЕСИ, РЕКВІЗИТИ ТА ПІДПИСИ СТОРІН:', bold: true })],
          }),
          createBankDetails(customer, contract.legalEntity, true),
        ],
      },
      /** --- APPENDIX 1 --- */
      createAppendixHeader('ДОДАТОК №1 ', contract),
      createAppendixOne(customer, contract),

      /** --- APENDIX 2 ---  */
      createAppendixHeader('ДОДАТОК №2', contract),
      createAppendixTwo(contract),

      /** --- APENDIX 3 ---  */
      createAppendixHeader('ДОДАТОК №3', contract),
      createAppendixThree(customer, contract),
    ],
  });

  return doc;
};

const createParagraph = (text) => {
  return new Paragraph({
    text: text,
  });
};

export default generateContract;
