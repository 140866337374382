import { Box, Button, Stack, Typography } from '@mui/material';
import { memo, useState } from 'react';
import DataTable from 'views/common/DataTable';
import useModal from 'hooks/useModal';
import Modal from 'views/common/modal/Modal';
import LegalEntityForm from './LegalEntityForm';
import CopyInputAdornment from 'views/form/CopyInputAdornment';
import { useTranslation } from 'react-i18next';

const LegalEntitiesList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
}) => {
  const [modalOpen, openModal, closeModal] = useModal();
  const [entity, setEntity] = useState(null);
  const { t } = useTranslation();

  const handleClick = (id) => {
    const legalEntity = list.find((option) => option.id === id);
    legalEntity && setEntity(legalEntity);
    openModal();
  };

  const columns = [
    {
      field: 'name',
      headerName: t('base.labels.name'),
      filterable: false,
      renderCell: ({ row: { name, id } }) => {
        return (
          <Button
            variant="text"
            sx={{ p: 0, color: 'text.main', minWidth: 0, justifyContent: 'flex-start', overflow: 'auto' }}
            onClick={() => handleClick(id)}
          >
            {name}
          </Button>
        );
      },
      flex: 0.5,
    },
    {
      field: 'bank',
      headerName: t('base.labels.bank'),
      filterable: false,
      flex: 0.3,
    },
    {
      field: 'iban',
      headerName: t('base.labels.iban'),
      filterable: false,
      flex: 0.4,
    },
    {
      field: 'documents',
      headerName: t('base.labels.documents'),
      filterable: false,
      renderCell: ({ row: { documents } }) => {
        return (
          <Stack justifyContent="space-between" direction="row" spacing={2}>
            {documents.map((doc) => {
              return (
                <Typography key={doc} color={'info.main'}>
                  {doc}{' '}
                </Typography>
              );
            })}
          </Stack>
        );
      },
      flex: 0.3,
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      flex: 0.3,
      renderCell: (params) => {
        return <CopyInputAdornment value={params.row.documents} tooltipTitle={t('base.tooltips.copyLink')} />;
      },
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          px: 2,
          borderRadius: 1,
        }}
      >
        <DataTable
          list={list}
          totalElements={totalElements}
          isLoading={isLoading}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          totalPages={totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
          hideFooter={hideFooter}
        />
      </Box>
      <Modal
        title={t('types.settings.dictionaries.listSingle.legalEntity')}
        questionIcon
        open={modalOpen}
        onClose={closeModal}
      >
        <LegalEntityForm closeModal={closeModal} legalEntity={entity} t={t} />
      </Modal>
    </Box>
  );
};

export default memo(LegalEntitiesList);
