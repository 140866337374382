import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';

import { PERMISSIONS } from 'utils/constants/permissions';

export const QUICK_ADD_MENU = [
  {
    permission: PERMISSIONS.person.create,
    key: 'person',
    to: '/persons/new',
    icon: <PersonOutlineOutlinedIcon sx={{ mr: 1 }} />,
  },
  {
    permission: PERMISSIONS.contract.create,
    key: 'contract',
    to: '/contracts/new',
    icon: <NoteAltOutlinedIcon sx={{ mr: 1 }} />,
  },
  {
    permission: PERMISSIONS.invoice.create,
    key: 'invoice',
    to: '/invoices/new',
    icon: <RequestQuoteOutlinedIcon sx={{ mr: 1 }} />,
  },
  {
    permission: PERMISSIONS.group.create,
    key: 'group',
    to: '/groups/new',
    icon: <GroupsOutlinedIcon sx={{ mr: 1 }} />,
  },
  {
    permission: PERMISSIONS.session.create,
    key: 'session',
    to: '/sessions/new',
    icon: <HistoryEduOutlinedIcon sx={{ mr: 1 }} />,
  },
  {
    permission: PERMISSIONS.program.create,
    key: 'program',
    to: '/programs/new',
    icon: <MenuBookOutlinedIcon sx={{ mr: 1 }} />,
  },
  {
    permission: PERMISSIONS.lesson.create,
    key: 'lesson',
    to: '/lessons/new',
    icon: <CastForEducationOutlinedIcon sx={{ mr: 1 }} />,
  },
  {
    permission: PERMISSIONS.task.create,
    key: 'task',
    to: '/tasks/new',
    icon: <PlaylistAddCheckOutlinedIcon sx={{ mr: 1 }} />,
  },
];
