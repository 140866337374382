import { AlignmentType, Paragraph, TextRun } from 'docx';

export const createCustomerPart = (customer) => {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      before: 50,
    },
    indent: {
      firstLine: 500,
    },
    children: [
      new TextRun(`та `),
      new TextRun({
        text: `${customer?.contact?.lastName} ${customer?.contact?.name} ${customer?.contact?.secondName}, `,
        bold: true,
      }),
      new TextRun({ text: `паспорт ${customer?.passport}, ` }),
      new TextRun({
        text: `ідентифікаційний номер платника податків за даними ДРФО-платників податків ${customer?.idCode}, далі - `,
      }),
      new TextRun({ text: `"Замовник", `, bold: true }),
      new TextRun({ text: `з іншої сторони, ` }),
    ],
  });
};
