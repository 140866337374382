import { memo, useEffect, useState } from 'react';

import TasksGridView from './components/TasksGridView';
import TasksList from './components/TasksList';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { getDataFromLS } from 'utils/helpers';

const TasksPage = () => {
  const tasksView = getDataFromLS('tasksPageView');

  const [pageView, setPageView] = useState(tasksView || 'col');

  const [filter, setFilter] = useState({
    my: true,
    byMe: false,
  });

  const handleFilterChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    localStorage.setItem('tasksPageView', JSON.stringify(pageView));
  }, [pageView]);

  const togglePageView = () => {
    pageView === 'list' ? setPageView('col') : setPageView('list');
  };

  return pageView === 'list' ? (
    <TasksList
      handleFilterChange={handleFilterChange}
      filter={filter}
      pageView={pageView}
      togglePageView={togglePageView}
      hasPermission={hasPermission(PERMISSIONS.task.create)}
    />
  ) : (
    <TasksGridView
      handleFilterChange={handleFilterChange}
      filter={filter}
      pageView={pageView}
      togglePageView={togglePageView}
      hasPermission={hasPermission(PERMISSIONS.task.create)}
    />
  );
};

export default memo(TasksPage);
