import { memo, useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import store from 'store';
import { programSelectors, programThunks } from 'store/ducks/program';

import HeaderList from 'views/common/HeaderList';
import Error from 'views/common/Error';
import ProgramsList from './components/ProgramsList';
import { PageWrapper } from 'views/common/StyledComponents';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const ProgramsPage = () => {
  const { t } = useTranslation();

  const programs = useSelector(programSelectors.getPrograms());
  const programsLoading = useSelector(programSelectors.programsLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(programThunks.fetchPrograms(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <HeaderList
          title={t('types.program.namePlural')}
          to="/programs/new"
          buttonTitle={'+ ' + t('base.buttons.createProgram')}
          hasPermission={hasPermission(PERMISSIONS.program.create)}
          isSearchbarShow
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <ProgramsList
          list={programs.content}
          isLoading={programsLoading}
          totalElements={programs.totalElements}
          totalPages={programs.totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(ProgramsPage);
