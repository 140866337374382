import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

import store from 'store';
import { notificationThunks } from 'store/ducks/notification';
import { userSelectors } from 'store/ducks/user';

const ITEM_HEIGHT = 48;

const NotificationsPopUp = ({ notifications }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(userSelectors.getCurrentUser());
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const unreadMessages = notifications.content.reduce((acc, item) => (item.status === 'UNREAD' ? acc + 1 : acc), 0);

  const handleNotificationsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMessageClick = (task) => {
    navigate(`/tasks/${task}`);
    handleClose();
  };

  const handleNavigateToTasks = () => {
    navigate(`/tasks/`);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);

    unreadMessages !== 0 &&
      store
        .dispatch(notificationThunks.resetNotifications())
        .then(() => store.dispatch(notificationThunks.fetchNotifications(`?&size=30`)));
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="notification-button"
        aria-controls={open ? 'notifications-list' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleNotificationsOpen}
        sx={{ p: 0 }}
      >
        <Badge
          badgeContent={unreadMessages < 30 ? unreadMessages : '30+'}
          color="error"
          invisible={unreadMessages === 0}
        >
          <Avatar variant="rounded" sx={{ width: 56, height: 56 }} alt={user.name} src={user.avatar} />
        </Badge>
      </IconButton>
      <Menu
        id="notifications-list"
        MenuListProps={{
          'aria-labelledby': 'notification-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            maxHeight: ITEM_HEIGHT * 6.5,
          },
          sx: {
            overflow: 'scroll',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: 'extra.white',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <List style={{ maxHeight: ITEM_HEIGHT * 4.5, overflowY: 'auto' }}>
          {notifications.content?.map((option) => (
            <MenuItem
              key={option.id}
              sx={{ mx: 2, py: 0.5, borderRadius: 2, '&:hover': { backgroundColor: 'primary.lighter' } }}
            >
              <Badge variant="dot" color="primary" invisible={option.status === 'READ' ? true : false}>
                <ListItemButton
                  onClick={() => handleMessageClick(option.relatedId)}
                  sx={{
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <ListItemText
                    sx={{ width: '100%', overflowX: 'hidden', border: 'none' }}
                    primary={`${option.status === 'UNREAD' ? option.message : 'Task'} ${option.relatedId}`}
                  />
                </ListItemButton>
              </Badge>
            </MenuItem>
          ))}
        </List>

        <Divider sx={{ mx: 1.5 }} />
        <Button
          onClick={handleNavigateToTasks}
          variant="text"
          sx={{
            mt: 1.5,
            mx: 2,
          }}
        >
          {t('types.notification.openTasks')}
        </Button>
      </Menu>
    </>
  );
};

export default memo(NotificationsPopUp);
