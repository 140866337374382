import FormikTextField from './FormikTextField';
import { FieldArray } from 'formik';
import { Fragment, memo } from 'react';
import FormikPhone from './FormikPhone';
import AddInputAdornment from './AddInputAdornment';
import RemoveInputAdornment from './RemoveInputAdornment';
import { usePermissionContext } from 'services/context/permissionContext';

const FormikArrayTextField = ({ name, values, label, readOnly }) => {
  const hasPermission = usePermissionContext();

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values && values.length > 0 ? (
            values.map((option, index) => (
              <Fragment key={index}>
                <MemoizedFormikPhone
                  name={`${name}.${index}`}
                  label={label}
                  value={option}
                  readOnly={readOnly}
                  adornmentProps={
                    <>
                      {hasPermission && !readOnly && (
                        <AddInputAdornment add={() => arrayHelpers.push('')} name={name} />
                      )}
                      {values.length > 1 && (
                        <RemoveInputAdornment remove={() => arrayHelpers.remove(index)} name={name} />
                      )}
                    </>
                  }
                />
              </Fragment>
            ))
          ) : (
            <FormikTextField
              label={label}
              name={`${name}`}
              placeholder={'+380 (50) 555 55 55'}
              onFocus={() => arrayHelpers.push('')}
            />
          )}
        </>
      )}
    />
  );
};

const MemoizedFormikPhone = memo(FormikPhone, () => true);

export default memo(FormikArrayTextField);
