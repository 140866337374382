import { memo, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormikTextField from 'views/form/FormikTextField';

const PasswordInput = ({ name, variant = 'outlined', inputLabelProps, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormikTextField
      {...rest}
      name={name}
      type={showPassword ? 'text' : 'password'}
      variant={variant}
      InputLabelProps={inputLabelProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff sx={{ color: 'primary.light', fontSize: 20 }} />
              ) : (
                <Visibility sx={{ color: 'primary.light', fontSize: 20 }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default memo(PasswordInput);
