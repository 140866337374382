import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import i18n from 'i18n';

import { Autocomplete, Stack, TextField } from '@mui/material';

import { userActions, userSelectors } from 'store/ducks/user';
import { useTranslation } from 'react-i18next';
import { dictionarySelectors } from 'store/ducks/dictionary';
import api from 'services/api';
import store from 'store';

const General = () => {
  const user = useSelector(userSelectors.getCurrentUser());
  const { t } = useTranslation();
  const { language } = user;
  const enums = useSelector(dictionarySelectors.getEnums());
  const enumsLoading = useSelector(dictionarySelectors.enumsLoading());

  const [selectedLng, setSelectedLng] = useState(language);

  const changeLanguage = async (lng) => {
    const resp = await api.put('/users/language', lng);

    if (!resp.error) {
      i18n.changeLanguage(lng.name.toLowerCase());
      setSelectedLng(lng);
      store.dispatch(
        userActions.updateUser({
          collection: 'current',
          data: {
            ...user,
            language: lng,
          },
        })
      );
    }
  };

  return (
    <Stack gap={1}>
      <TextField
        label={t('base.labels.timeZone')}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
        value={DateTime.local().zoneName}
      />
      <Autocomplete
        options={enums.languages || []}
        clearOnBlur={false}
        loading={enumsLoading}
        onChange={(event, value) => changeLanguage(value)}
        value={selectedLng}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label={t('base.labels.language')} />}
      />
    </Stack>
  );
};

export default memo(General);
