const getContracts = () => (state) => state.contract.list.data;
const contractsLoading = () => (state) => state.contract.list.isLoading;
const getContractsError = () => (state) => state.contract.list.error;
const getContract = () => (state) => state.contract.item.data;
const contractLoading = () => (state) => state.contract.item.isLoading;
const getContractError = () => (state) => state.contract.item.error;

const getContractInvoices = () => (state) => state.contract.contractInvoices.data;
const contractInvoicesLoading = () => (state) => state.contract.contractInvoices.isLoading;
const getContractInvoicesError = () => (state) => state.contract.contractInvoices.error;

const getContractTasks = () => (state) => state.contract.contractTasks.data;
const contractTasksLoading = () => (state) => state.contract.contractTasks.isLoading;
const getContractTasksError = () => (state) => state.contract.contractTasks.error;

export default {
  getContracts,
  contractsLoading,
  getContractsError,
  getContract,
  contractLoading,
  getContractError,
  getContractInvoices,
  contractInvoicesLoading,
  getContractInvoicesError,
  getContractTasks,
  contractTasksLoading,
  getContractTasksError,
};
