import { memo } from 'react';
import { Form, withFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { programThunks } from 'store/ducks/program';
import { uploadThunks } from 'store/ducks/upload';

import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from 'views/common/StyledComponents';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';
import ImageUploader from 'views/common/ImageUploader';
import ProgramStructure from './ProgramStructure';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { validationProgramForm } from 'utils/schema';

const ProgramForm = ({ program, modalOpen, openModal, closeModal, isSubmitting, file, setFile }) => {
  const { t } = useTranslation();
  const enums = useSelector(dictionarySelectors.getEnums());

  const permission = program ? hasPermission(PERMISSIONS.program.update) : hasPermission(PERMISSIONS.program.create);

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="programForm" style={{ height: '100%' }}>
          <HeaderCard
            title={program ? t('types.program.name') : t('types.program.new')}
            data={program}
            isSubmitting={isSubmitting}
            formId="programForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
            isCreateNewModule
          />
          <Stack height="calc(100% - 60px)" gap={1} sx={{ overflowY: 'auto' }}>
            <FormWrapper>
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.program.info')}</FormColumnTitle>
                <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
                <FormikTextField
                  name="hours"
                  label={t('base.labels.hours')}
                  placeholder={t('base.placeholders.hours')}
                />
                <FormikTextField
                  name="months"
                  label={t('base.labels.months')}
                  placeholder={t('base.placeholders.months')}
                />
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.program.details')}</FormColumnTitle>
                <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.activeStatuses} />
                <FormikTextField
                  name="description"
                  label={t('base.labels.description')}
                  placeholder={t('base.placeholders.description')}
                  rows={3}
                  multiline
                />
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.program.image')}</FormColumnTitle>
                <Card sx={{ backgroundColor: 'extra.white', mt: 1 }}>
                  {program?.image ? (
                    <CardMedia sx={{ height: 140 }} image={program?.image} />
                  ) : (
                    <CardContent>
                      <ImageOutlinedIcon sx={{ color: 'text.light' }} />
                      <Typography variant="body2" color="text.light">
                        {t('messages.warning.noImage')}
                      </Typography>
                    </CardContent>
                  )}
                </Card>

                <ImageUploader file={file} setFile={setFile} isSubmitting={isSubmitting} />
              </FormColumnWrapper>
            </FormWrapper>
            {program && <ProgramStructure id={program.id} />}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ program = {} }) => ({
      ...program,
      id: program?.id,
      name: program?.name || '',
      hours: program?.hours || '',
      months: program?.months || '',
      status: program?.status || null,
      description: program?.description || '',
      image: program?.image || '',
    }),
    validationSchema: validationProgramForm,

    handleSubmit: async (values, { props, setSubmitting }) => {
      const imageUrlRes = props.file ? await store.dispatch(uploadThunks.uploadImage(props.file)) : null;

      const imageUrl = imageUrlRes ? imageUrlRes.payload : '';

      const updatedValues = values.image && !imageUrl ? { ...values } : { ...values, image: imageUrl };

      const res = props.program
        ? store.dispatch(programThunks.updateProgram(updatedValues))
        : store.dispatch(programThunks.createProgram(updatedValues));

      if (!res.error) {
        props.file && props.setFile(null);
        toast.success(
          props.program
            ? props.t('messages.success.toast.updateProgram')
            : props.t('messages.success.toast.createProgram')
        );
        setSubmitting(false);

        if (props.program) {
          props.closeModal();
        } else props.navigate(`/programs/${res.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(ProgramForm)
);
