import { AlignmentType, Paragraph, TextRun } from 'docx';

export const createLegalEntity = (legalEntity) => {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    spacing: {
      before: 200,
    },
    indent: {
      firstLine: 500,
    },
    children: [
      new TextRun({ text: `${legalEntity?.name}, `, bold: true }),
      new TextRun({
        text: `реєстраційний номер облікової картки платника податків та інших обов’язкових платежів ${legalEntity?.idCode}, `,
      }),
      new TextRun({ text: `адреса реєстрації якої: ${legalEntity?.address}, далі - ` }),
      new TextRun({ text: `"Виконавець", `, bold: true }),
      new TextRun({ text: `з однієї сторони, ` }),
    ],
  });
};
