import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { Stack } from '@mui/material';

import store from 'store';
import { gradeSelectors, gradeThunks } from 'store/ducks/grade';

import Loader from 'views/common/Loader';
import GradeForm from './components/GradeForm';
import Error from 'views/common/Error';
import { useTranslation } from 'react-i18next';

const GradeCard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const grade = useSelector(gradeSelectors.getGrade());
  const isLoading = useSelector(gradeSelectors.gradeLoading()) && !grade;

  useEffect(() => {
    store.dispatch(gradeThunks.fetchGrade(id));
  }, [id]);

  return (
    <Stack justifyContent="center">
      {isLoading && <Loader />}
      {!isLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <GradeForm grade={grade} />
        </ErrorBoundary>
      )}
    </Stack>
  );
};

export default memo(GradeCard);
