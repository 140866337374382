import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import Modal from 'views/common/modal/Modal';
import ProgramModulesForm from './ProgramModulesForm';

import useModal from 'hooks/useModal';
import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';

const CreateNewModule = ({ programId }) => {
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();
  return (
    hasPermission(PERMISSIONS.contract.create) && (
      <>
        <Button variant="contained" onClick={openModal}>
          {'+ ' + t('base.buttons.createModule')}
        </Button>

        <Modal title={t('base.buttons.createModule')} questionIcon open={modalOpen} onClose={closeModal}>
          <ProgramModulesForm closeModal={closeModal} programId={programId} t={t} />
        </Modal>
      </>
    )
  );
};

export default memo(CreateNewModule);
