import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PageWrapper } from 'views/common/StyledComponents';
import HeaderSettings from 'views/pages/settings/HeaderSettings';

const SettingsLayout = () => {
  const [props, setProps] = useState({
    search: false,
    buttonTitle: '',
    handleButtonClick: null,
  });

  return (
    <PageWrapper>
      <HeaderSettings
        search={props.search}
        buttonTitle={props.buttonTitle}
        handleButtonClick={props.handleButtonClick}
      />
      <Outlet context={[props, setProps]} />
    </PageWrapper>
  );
};

export default SettingsLayout;
