import { Box, Button, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { dictionarySelectors } from 'store/ducks/dictionary';
import UniversityForm from './UniversityForm';
import useModal from 'hooks/useModal';
import Modal from 'views/common/modal/Modal';
import { memo } from 'react';
import UniversitiesList from './UniversitiesList';
import { useTranslation } from 'react-i18next';

const Universities = () => {
  const universities = useSelector(dictionarySelectors.getUniversities());
  const [modalOpen, openModal, closeModal] = useModal();
  const { t } = useTranslation();

  return (
    <Stack sx={{ height: '100%', p: 2 }} justifyContent="space-between">
      <Box sx={{ height: '80%' }}>
        <Box sx={{ maxHeight: '75%', overflow: 'auto' }}>
          <UniversitiesList list={universities} totalElements={universities.length} hideFooter />
        </Box>
      </Box>
      <Button onClick={openModal} sx={{ maxWidth: 150, alignSelf: 'center', m: 2 }} variant="contained">
        {t('base.buttons.addNew')}
      </Button>
      <Modal
        title={t('types.settings.dictionaries.listSingle.newUniversity')}
        questionIcon
        open={modalOpen}
        onClose={closeModal}
      >
        <UniversityForm closeModal={closeModal} t={t} />
      </Modal>
    </Stack>
  );
};

export default memo(Universities);
