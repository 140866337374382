export const API_TOKEN = 'token';
export const AUTH_HEADER = 'authorization';

export const REG_EXP = {
  string: /^[a-zA-ZА-ЩЬЮЯҐЄІЇа-щьюяґєії'ʼ-]+$/,
  phone: /((\+38)?\(?\d{3}\)?[\s/.]?(\d{7}|\d{3}[\s/.]\d{2}[\s/.]\d{2}|\d{3}\d{4}))/,
  lower: /^(?=.*[a-z])/,
  upper: /^(?=.*[A-Z])/,
  digit: /^(?=.*[0-9])/,
  specChar: /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
};

export const DICTIONARY_UPDATE = {
  ROLE_SALES_MANAGER: 'fetchManagers',
  ROLE_TRAINER: 'fetchTrainers',
  ROLE_MENTOR: 'fetchMentors',
  ROLE_COORDINATOR: 'fetchCoordinators',
};

export const STATUSES_COLOR = {
  // tasks
  NEW: 'primary.main', // contracts, groups
  PENDING: 'warning.main',
  COMPLETED: 'success.main', // contracts
  CANCELLED: 'error', // contracts, invoices
  // contracts
  ACTIVE: 'info.main', //invoices, groups, programs,
  PAUSED: 'warning.main',
  //invoices
  PAID: 'success.main',
  // groups
  GRADUATED: 'success.main',
  DISBANDED: 'error',
  // programs, lessons, assignments:
  ARCHIVED: 'warning.main',
};

export const DAYS_ENG_TO_UA = {
  MONDAY: 'Пн',
  TUESDAY: 'Вт',
  WEDNESDAY: 'Ср',
  THURSDAY: 'Чт',
  FRIDAY: 'Пт',
  SATURDAY: 'Сб',
  SUNDAY: 'Нд',
};


export const DESKTOP_HEIGHT = "100dvh"; // 16px bottom indent
export const MOBILE_HEIGHT = "calc(100dvh - 72px)"; // 72px top bar height
export const TABLET_HEIGHT = "calc(100dvh - 88px)"; // 88px top bar height