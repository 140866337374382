export const CHAPTER_ONE = {
  chapter: 'Стаття 1. ПРЕДМЕТ ДОГОВОРУ',
  paragraphs: [
    '1.1 В порядку та на умовах, визначених цим Договором, Виконавець зобов’язується надати Замовнику послуги (далі – «Послуги»), передбачені Додатком №1 до цього Договору, а Замовник зобов’язується оплатити Послуги.',
    '1.2 В Додатку №1 зазначаються строки та місце надання Послуг, а також інші умови надання та оплати Послуг.',
    '1.3 Підписаний Сторонами Додаток №1, Додаток №2 та Додаток №3 є невід’ємною частиною цього Договору.',
    '1.4 Виконавець заявляє, а Замовник підтверджує свою обізнаність та погоджується з тим, що виконання запланованої програми занять, передбаченої Додатком №1 до цього Договору, не веде до присвоєння Замовнику будь-якого освітньо-кваліфікаційного рівня, кваліфікації, категорії, педагогічного звання, надання будь-яких академічних прав.',
    '1.5 Дата початку надання Послуг за цим Договором зазначена в Додатку №1. При цьому дата початку надання Послуг, зазначена в Додатку № 1, може бути змінена Виконавцем на власний розсуд, про що Виконавець повідомляє Замовника не пізніше ніж за 2 (два) календарних дні до запланованої дати початку надання Послуг. ',
    '1.6 Графік надання Послуг за цим Договором зазначений в  Додатку №1.  При цьому графік надання Послуг, зазначений в Додатку № 1, може бути змінений Виконавцем на власний розсуд, про що Виконавець повідомляє Замовника не пізніше ніж за 7 (сім) календарних днів до запланованої зміни графіку надання Послуг. ',
  ],
};

export const CHAPTER_TWO = {
  chapter: "Стаття 2. ПРАВА І ОБОВ'ЯЗКИ СТОРІН",
  paragraphs: [
    "2.1. Виконавець зобов'язується:",
    '2.1.1. надавати Послуги відповідно до вимог, які звичайно ставляться до послуг такого виду;',
    '2.1.2. надавати Послуги своєчасно, відповідно до строків та/або термінів, передбачених цим Договором, в повному обсязі та належним чином;',
    '2.1.3. ознайомлювати Замовника зі змінами до запланованої програми занять.',
    // bold
    '2.2. Виконавець має право:',
    '2.2.1. вносити зміни та доповнення до запланованої програми занять щодо структури, порядку викладання матеріалу для покращення його засвоєння, форми проведення занять,  в односторонньому порядку за умови завчасного попередження Замовника про такі зміни не менше ніж за 1 (один) календарний день до запровадження змін/доповнень;',
    '2.2.2. змінювати та/або коригувати процес надання Послуг з метою його оптимізації, підвищення якості Послуг, що надаються, включаючи, але не обмежуючись, вносити зміни до складу викладачів, методів та способів надання Послуг, в односторонньому порядку без попередніх повідомлень та/або погоджень з Замовником; ',
    '2.2.3. розірвати цей Договір в односторонньому порядку:',
    // indent left 250
    'a) у випадку одноразового порушення Замовником умов цього Договору щодо оплати Послуг, зазначених в Додатку № 1;',
    'б) у випадку порушення Замовником Правил надання Послуг, зазначених у Додатку  №2;',
    'в) у випадку невиконання Замовником контрольних завдань, передбачених запланованою програмою занять.',
    '2.2.4. вносити зміни та доповнювати Правила надання Послуг з попереднім погодженням із Замовником;',
    '2.2.5. вимагати від Замовника сплати вартості Послуг відповідно до умов цього Договору.',
    // bold
    "2.3. Замовник зобов'язується:",
    '2.3.1. своєчасно та в повному обсязі здійснювати оплату Послуг в порядку та на умовах, визначених цим Договором; ',
    '2.3.2. повідомити Виконавця про зміну своїх реквізитів, номерів контактних телефонів та іншої інформації, зазначеної в ст. 10 цього Договору протягом 2 (двох) календарних днів з дати таких змін. В разі неповідомлення та/або несвоєчасного повідомлення про зміну вказаних в цьому Договорі реквізитів, Замовник приймає на себе ризики настання негативних наслідків, пов`язаних з таким не повідомленням/несвоєчасним повідомленням;',
    '2.3.3. дотримуватись Правил надання Послуг.',
    //bold
    '2.3. Замовник має право:',
    '2.4.1. отримувати Послуги на умовах та в порядку, передбачених цим Договором;',
    '2.4.2. виконати програму занять до закінчення терміну, передбаченого на виконання програми занять, тобто до дати закінчення Строку надання Послуг за цим Договором, передбаченого в п. 2 Додатку № 1. Ця ситуація ні в якому випадку не звільняє Замовника від обов‘язку своєчасно оплатити вартість Послуг згідно умов Договору.',
  ],
};

export const CHAPTER_THREE = {
  chapter: 'Стаття 3.  ЦІНА ДОГОВОРУ ТА ПОРЯДОК РОЗРАХУНКІВ',
  paragraphs: [
    '3.1. Вартість Послуг, що надаються Виконавцем Замовнику за цим Договором, зазначена в Додатку № 1 до цього Договору. ',
    '3.2. Замовник здійснює оплату Послуг в гривнях шляхом безготівкового перерахунку коштів на розрахунковий рахунок Виконавця, вказаний в цьому Договорі, або шляхом внесення готівкових грошових коштів в касу Виконавця.  ',
    '3.3. Порядок і строки оплати Послуг зазначені в Додатку № 1 до цього Договору.',
    '3.4. Якщо Замовник з власної ініціативи та/або через хворобу та/або через відрядження та/або через зміну місця проживання та/або з будь-яких інших причин не отримує Послуги за цим Договором або отримує їх не в повному обсязі, строки надання Послуг не змінюються і не переносяться, якщо Сторонами не буде погоджено інше.',
  ],
};

export const CHAPTER_FOUR = {
  chapter: 'Стаття 4. ФОРС-МАЖОРНІ ОБСТАВИНИ',
  paragraphs: [
    "4.1. Сторони звільняються від відповідальності за часткове чи повне невиконання та/або неналежне виконання зобов'язань за цим Договором у випадку, якщо таке невиконання та/або неналежне виконання було спричинене дією форс-мажорних обставин, які виникли після підписання Договору, в тому числі, але не виключно: пожеж, землетрусів, інших стихійних лих, воєнних дій, страйків, прийняття органами влади законодавчих та підзаконних актів, що перешкоджають виконанню Сторонами своїх зобов'язань за цим Договором, за умови надання іншій Стороні сертифікату про засвідчення форс-мажорних обставин, виданого Торгово-промисловою палатою України протягом 20 (двадцяти) календарних днів з дати настання таких обставин, в порядку, визначеному чинним законодавством України.",
    '4.2. Термін виконання зобов’язань за цим Договором продовжується на термін дії форс-мажорних обставин та усунення їх наслідків.',
    '4.3. У разі, якщо термін дії форс-мажорних обставин перевищує 3 місяці, це може бути підставою для припинення дії цього Договору з ініціативи будь-якої Сторони.',
  ],
};

export const CHAPTER_FIVE = {
  chapter: 'Стаття 5.  ПОРЯДОК ТА ТЕРМІНИ НАДАННЯ ПОСЛУГ',
  paragraphs: [
    '5.1	Послуги надаються шляхом безпосереднього консультування та інформування Замовника спеціалістами згідно встановленого Виконавцем Графіку надання Послуг.',
    '5.2.	 Початок надання кожної послуги визначається Виконавцем і може змінюватися в залежності від операційної діяльності Виконавця. ',
    '5.3.	 Послуги вважаються наданими у повному обсязі Виконавцем з моменту підписання Акту здачі-приймання наданих послуг. Якщо Замовник не підписав Акт здачі-прийняття наданих послуг протягом 5 (п‘яти) робочих днів з дати передачі/направлення йому цього документу Виконавцем або не надав письмову мотивовану відмову від підписання в зазначений в цьому пункті строк, то послуги вважаються прийнятими згідно з Актом здачі-приймання наданих послуг, який підписаний Виконавцем в односторонньому порядку.',
  ],
};

export const CHAPTER_SIX = {
  chapter: 'Стаття 6. ВІДПОВІДАЛЬНІСТЬ СТОРІН',
  paragraphs: [
    '6.1. За невиконання та/або неналежне виконання зобов’язань за цим Договором Сторони несуть відповідальність, передбачену цим Договором та чинним законодавством України. ',
    '6.2. У разі порушення Замовником строків оплати послуг Замовник зобов’язується сплатити Виконавцю пеню у розмірі подвійної облікової ставки НБУ, що діє в період прострочення, від суми заборгованості (несвоєчасно сплачених послуг) за кожен день прострочення. Пеня нараховується за весь період прострочення. Виконавець має право обмежити доступ Замовника до навчальних ресурів до моменту сплати заборгованності.',
  ],
};

export const CHAPTER_SEVEN = {
  chapter: 'Стаття 7. СТРОК ДІЇ, РОЗІРВАННЯ ДОГОВОРУ',
  paragraphs: [
    '7.1. Договір вступає в силу з моменту його підписання обома Сторонами та діє до закінчення Строку надання Послуг, зазначеного в п. 2 Додатку №1 до цього Договору, а якщо Замовник виконає програму занять до дати закінчення Строку надання Послуг, передбаченого в п. 2 Додатку № 1, то в такому разі Договір діє до дати дострокового виконання Замовником програми занять, а по фінансовим розрахункам – до повного виконання. ',
    '7.2. Цей Договір може бути достроково розірваний Виконавцем в односторонньому порядку шляхом надсилання письмового повідомлення Замовнику не менше ніж за 5 (п‘ять) календарних днів до запланованої дати розірвання у випадках, передбачених п. 2.2.3 цього Договору. При цьому Виконавець не повертає Замовнику грошові кошти, за період, протягом якого Замовнику надавались послуги. Повернення грошових коштів здійснюється лише за період, коли послуги надаватись не будуть згідно умов розірвання Договору.',
    '7.3. Цей Договір може бути достроково розірваний Замовником в односторонньому порядку шляхом надсилання письмового повідомлення Виконавцю не менше ніж за 5 (п‘ять) календарних днів до запланованої дати розірвання. При цьому Замовник зобов’язується здійснити погашення заборгованості за період, в якому послуги надавались, у  випадку наявності такої заборгованості. У випадку одностороннього розірвання Договору з ініціативи Замовника платежі за період, в якому послуги не будуть надаватись,  не сплачуються, а сплачені передоплатою кошти за такий період повертаються Замовнику.',
  ],
};

export const CHAPTER_EIGHT = {
  chapter: 'Стаття 8. ПОРЯДОК ВИРІШЕННЯ СПОРІВ',
  paragraphs: [
    '8.1. Спори, що виникають у зв’язку з укладенням, дією, зміною та припиненням дії цього Договору вирішуються шляхом переговорів між Сторонами. У випадку неможливості досягнення згоди шляхом переговорів, відповідний спір підлягає передачі на розгляд суду в порядку, передбаченому чинним законодавством України.',
  ],
};

/**
 *  Here is the part of static terms & conditions of Appendix 2 to Agreement starting from 1.1.2 point.
 *  The header and points 1.1 - 1.1.1 are described directly in GenerateAgreement
 * */

export const APPENDIX_TWO_CHAPTER_ONE = {
  paragraphs: [
    '1.1.2. Замовник – фізична особа, яка уклала договір про надання освітніх послуг з Виконавцем або третьою особою, яка діє від його імені, або фізична особа, на користь якої укладено договір про надання освітніх послуг з Виконавцем або третьою особою, яка діє від його імені.',
    '1.1.3. Договір – договір про надання освітніх послуг між Виконавцем та фізичною особою або юридичною особою на користь фізичної особи. Договором в розумінні цих Правил слід також вважати договір про надання освітніх послуг, укладений третьою особою, що діє від імені Виконавця.',
    '1.1.4. Послуги – послуги, які надаються Замовнику з метою сприяння набуттю або поглибленню знань Замовника у сфері інформаційних технологій.',
    '1.1.5. Спосіб надання послуг – онлайн, шляхом онлайн сесій на платформі Zoom та/або доступу до особистого кабінету на сайті Виконавця за адресою: https://i.dan-it.com.ua ',
    '1.2. Ці Правила затверджені та діють відповідно до рішення Виконавця. З  моменту ознайомлення Замовника зі змістом цих Правил, Замовник зобов’язується безумовно виконувати всі їх приписи та положення.',
    '1.3. Внесення змін та/або доповнень до цих Правил може бути здійснене за рішенням Виконавця в односторонньому порядку в будь-який час шляхом інформування Замовника за 7 робочих днів.',
  ],
};
export const APPENDIX_TWO_CHAPTER_TWO = {
  chapter: 'ІІ. ПРАВА ТА ОБОВ’ЯЗКИ ЗАМОВНИКА',
  paragraphs: [
    '2.1. Замовник визнає та погоджується з тим, що ефективне надання Послуг за Договором можливе лише за умови неухильного виконання Замовником наступних обов’язків:',
    '2.1.1. неухильно дотримуватися запланованої програми занять;',
    '2.1.2. відвідувати заняття (онлайн сесій та/або особистого кабінету на сайті Виконавця)  відповідно до запланованої програми занять;',
    '2.1.3. складати всі тестування, самостійні, контрольні роботи та інші форми контролю за успішністю в межах запланованої програми занять та згідно встановлених строків, передбачених програмою для виконання таких робіт;',
    '2.1.4. старанно, сумлінно та виключно самостійно виконувати запропоновані викладачами завдання, в тому числі завдання для самопідготовки;',
    '2.1.5. не з’являтися на заняття (онлайн сесії) в стані алкогольного та/або наркотичного сп’яніння; ',
    '2.1.6. виконувати рекомендації викладачів щодо виконання завдань, запропонованих на заняттях;',
    '2.1.7. не вдаватися до плагіату при виконанні будь-яких запропонованих завдань;',
    '2.1.8. з повагою ставитися до викладачів та будь-яких третіх осіб, що перебувають на занятті (онлайн-сесії);',
    '2.1.9. не вступати в конфлікти з викладачами та будь-якими третіми особами, які знаходяться на занятті (онлайн-сесії);',
    '2.1.10. своєчасно та в повному обсязі здійснювати оплату наданих Послуг в порядку та на умовах, визначених Договором.',
    '2.1.11. дотримуватись умов Договору, додатків до нього;',
    '2.2. Замовник поінформований та погоджується з тим, що у випадку порушення принаймні однієї з вимог, передбачених пунктами 2.1.3, 2.1.6, 2.1.11, 2.1.12 цих Правил, Виконавець матиме право не допустити та/або відсторонити Замовника від занять без компенсації вартості оплачених навчальних годин.',
    '2.3. Замовник проінформований про те, що має право на академічну відпустку один раз за весь період навчання, за умови, що оплата по договору здійснена в повному обсязі, тривалість академічної відпустки узгоджується в індивідуальному порядку з координатором курсу. Надання академічної відпустки можливе на підставі написаної Заяви Замовника. Датою початку академічної відпустки вважається дата вказана Замовником в заповненій Заяві та не може починатися раніше дати її заповнення. Відновлення навчання після академічної відпустки починається з теми, з якої Замовник призупинив прослуховування курсу. Під час академічної відпустки послуги менторської підтримки не надаються.',
    '2.4. Повторне прослуховування курсу або окремого модуля навчальної програми Замовником можливе у випадку наявності вільного місця в іншій групі. Вартість повторного прослуховування для Замовника буде встановлено зі знижкою 50% від розрахункової вартості курсу або його частини.',
  ],
};

export const APPENDIX_TWO_CHAPTER_THREE = {
  chapter: 'ІІ. РЕЗУЛЬТАТИ НАВЧАННЯ',
  paragraphs: [
    '3.1. Видача Замовникові письмового підтвердження успішного виконання запланованої програми занять, здійснюється виключно за умови захисту фінального проекту, передбаченого навчальною програмою.',
    '3.2 Сприяння у працевлаштуванні можливе виключно у випадку успішного виконання запланованої програми занять, а саме:',
    '     - складання всіх самостійних, контрольних та інших робіт з середнім значенням результату не нижче ніж 80 відсотків від максимального результату, встановленого Виконавцем.',
    '3.3. Положення п. 3.1. та п. 3.2 в частині відвідування занять не застосовуються у випадку, якщо відсутність Замовника на заняттях мала місце за наявності поважних причин, передбачених законодавством України про працю, за умови складання всіх самостійних, контрольних та інших робіт, призначених для контролю успішності Замовника, з оцінкою не менш як 70 (сімдесят) відсотків від максимального результату, встановленого Виконавцем.',
    '3.4. Виконавець заявляє, а Замовник підтверджує свою обізнаність та погоджується з тим, що виконання запланованої програми занять, передбаченої Договором, не веде до присвоєння Замовникові будь-якого освітньо-кваліфікаційного рівня, кваліфікації, категорії, педагогічного звання, надання будь-яких академічних прав.',
    '3.5. Замовник визнає, що Виконавець за жодних обставин не несе відповідальності за результати застосування Замовником знань, навичок та практичного досвіду, набутих в результаті надання Послуг за цим Договором, в тому числі за збитки, завдані таким застосуванням, ані перед Замовником, ані перед будь-якими третіми особами.',
  ],
};

export const APPENDIX_THREE_POINT_TWO = {
  title:
    '2. При виконанні Замовником сукупності умов, зазначених в п.1 даного додатку, Замовник має право на наступні послуги кар’єрного супроводу:',
  subPoints: [
    '·    допомога у підготовці резюме',
    '·    допомога у написанні супровідного листа',
    '·    допомога у оформленні профілю в Linkedin (треба посилання на ресурс)',
    '·    допомога у оформленні портфоліо',
    '·    підготовка до проходження співбесіди, пробні співбесіди з HR спеціалістом та технічним експертом',
    '·    підбір вакансій',
    '·    фідбек після проходження співбесід та робота над помилками',
    '·    надання пропозицій щодо стажування в ІТ компаніях, відправка резюме на вакансії партнерів',
    '·    супровід при узгодженні оффера та фіналізації умов праці',
  ],
};

export const APPENDIX_THREE_POINT_THREE = {
  title:
    '3. В разі, якщо Замовник не виконав сукупність умов, зазначених в п.1 даного додатку, Замовник має право на  наступні послуги кар’єрного супроводу:',
  subPoints: [
    '·    допомога у підготовці резюме',
    '·    допомога у написанні супровідного листа',
    '·    допомога у оформленні профілю в Linkedin (треба посилання на ресурс)',
  ],
};

export const APPENDIX_THREE_POINT_FOUR = {
  title:
    '4. Замовник має право звернутись щодо отримання послуг, зазначених у п. 2 цього Додатку в будь- який момент протягом періоду надання освітніх послуг Виконавцем згідно програми занять.',
  subPoints: [
    'Замовник може звернутись до Виконавця щодо отримання послуг, зазначених у п. 2 цього Додатку після закінчення надання освітніх послуг згідно програми Виконавцем у повному обсязі , але не пізніше 3-х місяців з моменту завершення надання освітніх послуг).',
    'У випадку звернення Замовника щодо  послуг кар’єрного супроводу пізніше, ніж через 3 місяці з моменту закінчення надання освітніх послуг згідно програми Виконавцем - Виконавець надає кар’єрний супровід в обсязі згідно пункту 3.',
  ],
};

export const APPENDIX_THREE_POINT_FIVE = {
  title:
    '5. В разі двох відмов Замовника від пропозицій Виконавця щодо співбесід, стажування, тестових завдань, посади, вважається, що Виконавцем виконані умови. В цьому разі Виконавець припиняє надання послуг кар’єрного супроводу.',
};
