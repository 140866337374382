import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Stack, Tab, Tabs, useTheme } from '@mui/material';

import { contractSelectors } from 'store/ducks/contract';
import InvoicesList from 'views/common/InvoicesList';
import TasksList from 'views/pages/tasks/components/TasksList';

import { TabPanel, a11yProps } from '../../../../utils/tabsFunc';
import { TabPanelsWrapper } from 'views/common/StyledComponents';

const ContractDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tabsValue, setTabsValue] = useState(0);

  const invoices = useSelector(contractSelectors.getContractInvoices());
  const invoicesLoading = useSelector(contractSelectors.contractInvoicesLoading()) && !invoices;

  const tasks = useSelector(contractSelectors.getContractTasks());
  const tasksLoading = useSelector(contractSelectors.contractTasksLoading()) && !tasks;

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const sortedByDueDateList = invoices?.slice().sort((a, b) => {
    const dateA = new Date(a.dueDate);
    const dateB = new Date(b.dueDate);
    return dateA - dateB;
  });

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden' }}>
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
        <Tab
          sx={{
            width: `calc(50% - ${theme.spacing(1)})`,
          }}
          label={t('types.invoice.namePlural')}
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            width: `calc(50% - ${theme.spacing(1)})`,
          }}
          label={t('types.task.namePlural')}
          {...a11yProps(1)}
        />
      </Tabs>

      <TabPanelsWrapper>
        <TabPanel value={tabsValue} index={0}>
          <InvoicesList
            list={sortedByDueDateList}
            totalElements={invoices.length}
            columnVisibilityModel={{
              contractor: false,
            }}
            isLoading={invoicesLoading}
            hideFooter
            isNested
          />
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <TasksList isNested contractTasks={tasks} contractTasksLoading={tasksLoading} />
        </TabPanel>
      </TabPanelsWrapper>
    </Stack>
  );
};

export default memo(ContractDetails);
