import { Box, Button, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { dictionarySelectors } from 'store/ducks/dictionary';
import ProfessionForm from './ProfessionForm';
import { memo } from 'react';
import useModal from 'hooks/useModal';
import Modal from 'views/common/modal/Modal';
import ProfessionsList from './ProfessionsList';
import { useTranslation } from 'react-i18next';

const Professions = () => {
  const professions = useSelector(dictionarySelectors.getProfessions());
  const [modalOpen, openModal, closeModal] = useModal();
  const { t } = useTranslation();

  return (
    <>
      <Stack sx={{ height: '100%', p: 2 }} justifyContent="space-between">
        <Box sx={{ height: '80%' }}>
          <Box sx={{ maxHeight: '75%', overflow: 'auto' }}>
            <ProfessionsList list={professions} totalElements={professions.length} hideFooter />
          </Box>
        </Box>
        <Button onClick={openModal} sx={{ maxWidth: 150, alignSelf: 'center', m: 2 }} variant="contained">
          {t('base.buttons.addNew')}
        </Button>
        <Modal
          title={t('types.settings.dictionaries.listSingle.newProfession')}
          questionIcon
          open={modalOpen}
          onClose={closeModal}
        >
          <ProfessionForm closeModal={closeModal} t={t} />
        </Modal>
      </Stack>
    </>
  );
};

export default memo(Professions);
