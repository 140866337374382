import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import SessionForm from './components/SessionForm';
import Error from 'views/common/Error';

const NewSession = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
      <SessionForm navigate={navigate} t={t} />;
    </ErrorBoundary>
  );
};

export default memo(NewSession);
