import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceProgram } from './slice';

const fetchPrograms = createAsyncThunk('program/fetchPrograms', async (data) => {
  return api.get(`/programs${data}`);
});
const fetchProgram = createAsyncThunk('program/fetchProgram', async (id) => {
  return api.get(`/programs/${id}`);
});
const createProgram = createAsyncThunk('program/createProgram', (data) => {
  return api.post('/programs', data);
});

const updateProgram = createAsyncThunk('program/updateProgram', (data, { dispatch }) => {
  return api.put(`/programs/${data.id}`, data).then((res) => {
    dispatch(replaceProgram({ data: res }));
    return res;
  });
});

const fetchProgramLessons = createAsyncThunk('program/fetchProgramLessons', async (id) => {
  return api.get(`/programs/${id}/lessons`);
});

const fetchProgramAssignments = createAsyncThunk('program/fetchProgramAssignments', async (id) => {
  return api.get(`/programs/${id}/assignments`);
});

const createProgramModule = createAsyncThunk('program/createProgramModule', (data) => {
  return api.post(`/programs/${data.programId}/modules`, data.module);
});

const updateProgramModule = createAsyncThunk('program/updateProgramModule', (data) => {
  return api.put(`/programs/${data.programId}/modules`, data.module);
});

export default {
  fetchPrograms,
  createProgram,
  fetchProgram,
  updateProgram,
  fetchProgramLessons,
  fetchProgramAssignments,
  createProgramModule,
  updateProgramModule,
};
