import { userSelectors } from '../store/ducks/user';
import store from '../store';
import { ALLOWED_PERMISSION } from './constants/permissions';

export const hasPermission = (permission) => {
  const user = userSelectors.getCurrentUser()(store.getState());
  return (
    user.roles.some((role) => role.name === 'ROLE_SUPERADMIN') ||
    user.authorities.includes(permission) ||
    permission === ALLOWED_PERMISSION
  );
};

/** --- Check permission dependencies in permission table --- */

export const validatePermissionDependencies = (data, permissionDependencies) => {
  const missingAuthorities = [];

  for (const role of data) {
    const roleAuthorities = role.authorities;
    const keysFoundInRole = Object.keys(permissionDependencies).filter((key) => roleAuthorities.includes(key));

    for (const key of keysFoundInRole) {
      const values = permissionDependencies[key];
      const missingValues = values.filter((value) => !roleAuthorities.includes(value));
      if (missingValues.length > 0) {
        missingAuthorities.push({
          role: role.role.label,
          authorityKey: key,
          missing: missingValues,
        });
      }
    }
  }
  return missingAuthorities;
};

/** --- This function is split the authority by underScore and check if the authority consist from one word or from two. 
 *    uses for case with LESSON_MATERIALS
 */
export const splitByUnderScoreAndCheckForSecondWordInEntity = (string) => {
  const res = string.split('_');
  return res[2] ? res[1] + ' ' + res[2] : res[1];
};