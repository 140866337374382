export const PERMISSIONS = {
  person: { read: 'READ_PERSON', create: 'CREATE_PERSON', update: 'UPDATE_PERSON' },
  contract: { read: 'READ_CONTRACT', create: 'CREATE_CONTRACT', update: 'UPDATE_CONTRACT' },
  invoice: { read: 'READ_INVOICE', create: 'CREATE_INVOICE', update: 'UPDATE_INVOICE' },
  program: { read: 'READ_PROGRAM', create: 'CREATE_PROGRAM', update: 'UPDATE_PROGRAM' },
  assignment: { read: 'READ_ASSIGNMENT', create: 'CREATE_ASSIGNMENT', update: 'UPDATE_ASSIGNMENT' },
  session: { read: 'READ_SESSION', create: 'CREATE_SESSION', update: 'UPDATE_SESSION' },
  lesson: { read: 'READ_LESSON', create: 'CREATE_LESSON', update: 'UPDATE_LESSON' },
  lessonMaterial: { create: 'CREATE_LESSON_MATERIAL', update: 'UPDATE_LESSON_MATERIAL' },
  task: { create: 'CREATE_TASK', update: 'UPDATE_TASK' },
  group: { read: 'READ_GROUP', create: 'CREATE_GROUP', update: 'UPDATE_GROUP' },
  grade: { read: 'READ_GRADE' },
  dictionary: { create: 'CREATE_DICTIONARY' },
};

export const PERMISSION_DEPENDENCIES = {
  [PERMISSIONS.contract.create]: [PERMISSIONS.program.read, PERMISSIONS.group.read, PERMISSIONS.contract.read],
  [PERMISSIONS.contract.update]: [PERMISSIONS.program.read, PERMISSIONS.group.read, PERMISSIONS.contract.read],
  [PERMISSIONS.invoice.create]: [PERMISSIONS.contract.read, PERMISSIONS.invoice.read],
  [PERMISSIONS.invoice.update]: [PERMISSIONS.contract.read, PERMISSIONS.invoice.read],
  [PERMISSIONS.group.create]: [PERMISSIONS.program.read, PERMISSIONS.group.read],
  [PERMISSIONS.group.update]: [PERMISSIONS.program.read, PERMISSIONS.group.read],
  [PERMISSIONS.session.create]: [PERMISSIONS.group.read, PERMISSIONS.lesson.read, PERMISSIONS.session.read],
  [PERMISSIONS.session.update]: [PERMISSIONS.group.read, PERMISSIONS.lesson.read, PERMISSIONS.session.read],
  [PERMISSIONS.lesson.create]: [PERMISSIONS.program.read, PERMISSIONS.lesson.read],
  [PERMISSIONS.lesson.update]: [PERMISSIONS.program.read, PERMISSIONS.lesson.read],
  [PERMISSIONS.lessonMaterial.update]: [
    PERMISSIONS.program.read,
    PERMISSIONS.lesson.read,
    PERMISSIONS.lessonMaterial.update,
  ],
  [PERMISSIONS.lessonMaterial.create]: [
    PERMISSIONS.program.read,
    PERMISSIONS.lesson.read,
    PERMISSIONS.lessonMaterial.create,
  ],
  [PERMISSIONS.assignment.create]: [PERMISSIONS.program.read, PERMISSIONS.lesson.read, PERMISSIONS.assignment.read],
  [PERMISSIONS.assignment.update]: [PERMISSIONS.program.read, PERMISSIONS.lesson.read, PERMISSIONS.assignment.read],
};

export const ALLOWED_PERMISSION = 'ALWAYS_ALLOWED';
