import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { replaceLesson } from './slice';

const fetchLessons = createAsyncThunk('lesson/fetchLessons', async (data) => {
  return api.get(`/lessons${data}`);
});
const fetchLesson = createAsyncThunk('lesson/fetchLesson', async (id) => {
  return api.get(`/lessons/${id}`);
});
const createLesson = createAsyncThunk('lesson/createLesson', (data) => {
  return api.post('/lessons', data);
});

const updateLesson = createAsyncThunk('lesson/updateLesson', (data, { dispatch }) => {
  return api.put(`/lessons/${data.id}`, data).then((res) => {
    dispatch(replaceLesson({ data: res }));
    return res;
  });
});

const fetchLessonAssignments = createAsyncThunk('lesson/fetchLessonAssignments', async (id) => {
  return api.get(`/lessons/${id}/assignments`);
});

export default {
  fetchLessons,
  createLesson,
  fetchLesson,
  updateLesson,
  fetchLessonAssignments,
};
