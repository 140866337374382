const getSessions = () => (state) => state.session.list.data;
const sessionsLoading = () => (state) => state.session.list.isLoading;
const getSessionsError = () => (state) => state.session.list.error;
const getSession = () => (state) => state.session.item.data;
const sessionLoading = () => (state) => state.session.item.isLoading;
const getSessionError = () => (state) => state.session.item.error;

export default {
  getSessions,
  sessionsLoading,
  getSessionsError,
  getSession,
  sessionLoading,
  getSessionError,
};
