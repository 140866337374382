import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, FormGroup, Menu, Stack, useTheme } from '@mui/material';
import { memo, useState } from 'react';
import FilterByPerson from './FilterByPerson';
import FilterByPeriod from './FilterByPeriod';
import { useTranslation } from 'react-i18next';

const FilterComplex = ({ person, options, query, action, handleGenerateReport }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGenerate = () => {
    handleGenerateReport(query);
    handleClose();
  };

  return (
    <>
      <Button
        variant="outlined"
        id="complex-filter-button"
        aria-controls={open ? 'complex-filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickOpen}
      >
        <FilterAltOutlinedIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
        {t('base.buttons.filter')}
      </Button>
      <Menu
        id="complex-filter-menu"
        anchorEl={anchorEl}
        sx={{ mt: 1 }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'complex-filter-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FormGroup>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" p={2} gap={2}>
            <FilterByPerson options={options} person={person} action={action} />
            <FilterByPeriod />
            <Stack direction="row" gap={2} mt={2} width="100%">
              <Button sx={{ width: '100%' }} onClick={handleClose}>
                {t('base.buttons.cancel')}
              </Button>
              <Button variant="contained" sx={{ width: '100%' }} onClick={handleGenerate}>
                {t('base.buttons.generate')}
              </Button>
            </Stack>
          </Stack>
        </FormGroup>
      </Menu>
    </>
  );
};

export default memo(FilterComplex);
