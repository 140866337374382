import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from 'store/utils/loadDataPaginatedState';

const logSlice = createSlice({
  name: 'logSlice',
  initialState: {
    list: loadDataPaginatedState(),
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchLogs, 'list');
  },
});

export const {} = logSlice.actions;
export default logSlice.reducer;
