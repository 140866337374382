import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { DateTime } from 'luxon';

import { PageWrapper } from 'views/common/StyledComponents';
import TasksColumn from './TasksColumn';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { taskSelectors, taskThunks } from 'store/ducks/task';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import HeaderList from 'views/common/HeaderList';
import Loader from 'views/common/Loader';
import { Stack } from '@mui/material';

const TasksGridView = ({ pageView, togglePageView, filter, handleFilterChange, hasPermission }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const tasks = useSelector(taskSelectors.getActiveTasks());
  const tasksLoading = useSelector(taskSelectors.activeTasksLoading());

  const query = searchParams.toString();

  const today = DateTime.local().startOf('day');
  const toDoTodayList = tasks
    .filter((task) => {
      return !task.dueDate || task.dueTime
        ? DateTime.fromISO(task.dueDate).equals(today) && DateTime.fromISO(task.dueTime) > DateTime.local()
        : DateTime.fromISO(task.dueDate).equals(today);
    })
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity)
      );
    });

  const toDoTomorrowList = tasks
    .filter((task) => DateTime.fromISO(task.dueDate).equals(today.plus({ days: 1 })))
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(b.dueDate).valueOf() : Infinity)
      );
    });

  const overdueList = tasks
    .filter((task) => {
      return task.dueTime
        ? DateTime.fromISO(task.dueDate).equals(today) && DateTime.fromISO(task.dueTime) < DateTime.local()
        : DateTime.fromISO(task.dueDate) < today;
    })
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(b.dueDate).valueOf() : Infinity)
      );
    });
  const toDoFutureList = tasks
    .filter((task) => DateTime.fromISO(task.dueDate) > today.plus({ days: 1 }))
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(b.dueDate).valueOf() : Infinity)
      );
    });

  const TASKS_COLUMNS = [
    { title: t('types.task.overdue'), tasksList: overdueList },
    { title: t('types.task.today'), tasksList: toDoTodayList },
    { title: t('types.task.tomorrow'), tasksList: toDoTomorrowList },
    { title: t('types.task.future'), tasksList: toDoFutureList },
  ];

  useEffect(() => {
    let params = {};

    if (filter?.byMe) {
      params.byMe = true;
    }
    if (filter) {
      params.my = filter.my;
    }

    setSearchParams(params);
  }, [setSearchParams, filter]);

  useEffect(() => {
    query && store.dispatch(taskThunks.fetchActiveTasks(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <HeaderList
          title={t('types.task.namePlural')}
          pageView={pageView}
          togglePageView={togglePageView}
          buttonTitle={'+ ' + t('base.buttons.createTask')}
          to="/tasks/new"
          filter={filter}
          handleFilter={handleFilterChange}
          hasPermission={hasPermission}
        />
      </ErrorBoundary>
      {tasksLoading ? (
        <Loader />
      ) : (
        <Stack direction="row" height="calc(100% - 76px)" gap={2} width="100%" justifyContent="space-evenly">
          {TASKS_COLUMNS.map((column) => (
            <TasksColumn key={column.title} column={column} list={column.tasksList} />
          ))}
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(TasksGridView);
