import { Paragraph } from 'docx';

export const createParagraph = (text) => {
  return new Paragraph({
    text: text,
  });
};

export const createParagraphWithIndent = (text) => {
  return new Paragraph({
    indent: {
      left: 150,
    },
    text: text,
  });
};
