import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { reportActions, reportSelectors, reportThunks } from 'store/ducks/report';
import InvoicesList from 'views/common/InvoicesList';

const OverdueInvoicesReport = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const invoices = useSelector(reportSelectors.getOverdueInvoicesReport());
  const isLoading = useSelector(reportSelectors.overdueInvoicesReportLoading());

  const managers = useSelector(dictionarySelectors.getManagers());
  const [, setProps] = useOutletContext();

  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';
  const managerQuery = useSelector(reportSelectors.getFilterByManager());

  useEffect(() => {
    query && store.dispatch(reportThunks.fetchOverdueInvoices(`?${query}`));
  }, [query]);

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      overdueInvoicesReport: true,
    }));

    return () => {
      setProps((prevProps) => ({
        ...prevProps,
        overdueInvoicesReport: false,
      }));
      store.dispatch(reportActions.addFilterByManager(null));
    };
  }, [setProps, managers]);

  return (
    <InvoicesList
      list={invoices.content}
      isLoading={isLoading}
      columnVisibilityModel={{ number: false, status: false, paymentDate: false, amountPaid: false }}
      totalElements={invoices.totalElements}
      totalPages={invoices.totalPages}
      setSearchParams={setSearchParams}
      pageQuery={pageQuery}
      pageSizeQuery={pageSizeQuery}
      searchQuery={''}
      sortByQuery={sortByQuery}
      sortDirectionQuery={sortDirectionQuery}
      managerQuery={managerQuery}
    />
  );
};

export default memo(OverdueInvoicesReport);
