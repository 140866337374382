import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Tab, Tabs } from '@mui/material';

import AssignmentsList from 'views/common/AssignmentsList';
import { TabPanelsWrapper } from 'views/common/StyledComponents';

import { TabPanel, a11yProps } from '../../../../utils/tabsFunc';

const SessionDetails = ({ list, totalElements, isLoading }) => {
  const { t } = useTranslation();

  const [tabsValue, setTabsValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden' }}>
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
        <Tab sx={{ width: '100%' }} label={t('types.assignment.namePlural')} {...a11yProps(0)} />
      </Tabs>

      <TabPanelsWrapper>
        <TabPanel value={tabsValue} index={0}>
          <AssignmentsList
            list={list}
            totalElements={totalElements}
            columnVisibilityModel={{
              contractor: false,
            }}
            isLoading={isLoading}
            hideFooter
            isNested
          />
        </TabPanel>
      </TabPanelsWrapper>
    </Stack>
  );
};

export default memo(SessionDetails);
