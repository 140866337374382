import { Table, TableRow, TableCell, Paragraph, WidthType, AlignmentType, TextRun, VerticalAlign } from 'docx';
import { DateTime } from 'luxon';

export const createPaymentSchedule = () => {
  return new Table({
    columnWidths: [2252, 6758],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 2252,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [new TextRun({ text: 'Строк Оплати' })],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            width: {
              size: 6758,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [new TextRun({ text: 'Сума, що підлягає оплаті, грн.' })],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 2252,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                spacing: {
                  after: 50,
                },
                children: [
                  new TextRun({
                    text: `__.__.${DateTime.now().year} р.`,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.TOP,
          }),
          new TableCell({
            width: {
              size: 6758,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                spacing: {
                  after: 50,
                },
                children: [
                  new TextRun({
                    text: `___________ грн. (________________________________ 00 коп)`,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.TOP,
          }),
        ],
      }),
    ],
  });
};
