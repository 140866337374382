import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideBar from 'views/common/sidebar/SideBar';

const PageLayout = () => {
  return (
    <Box display="flex" width="100%">
      {/* --- aside menu component --- */}
      <SideBar />
      <Container>
        <Outlet />
      </Container>
    </Box>
  );
};

export default PageLayout;
