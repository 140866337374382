import { Paragraph, AlignmentType, TextRun, SectionType } from 'docx';
import { DateTime } from 'luxon';
import { createContractDate } from './createContractDate';
import { createLegalEntity } from './createLegalEntity';
import { createCustomerPart } from './createCustomerPart';
import {
  APPENDIX_THREE_POINT_FIVE,
  APPENDIX_THREE_POINT_FOUR,
  APPENDIX_THREE_POINT_THREE,
  APPENDIX_THREE_POINT_TWO,
} from 'services/generateContract/contractConditions';
import { createParagraph, createParagraphWithIndent } from './createParagraph';
import { createBankDetails } from './createBankDetails';

export const createAppendixThree = (customer, contract) => {
  return {
    properties: {
      type: SectionType.CONTINUOUS,
    },
    children: [
      createContractDate(contract.date),
      createLegalEntity(contract.legalEntity),
      createCustomerPart(customer),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        indent: {
          firstLine: 200,
        },
        spacing: {
          after: 100,
        },
        children: [
          new TextRun({
            text: `Виконавець та Замовник надалі разом іменовані як «Сторони», а кожен окремо – «Сторона», склали та підписали цей Додаток №3 (далі – «Додаток») до Договору ${
              contract.number
            } про надання послуг від ${DateTime.fromISO(contract.date).toFormat(
              'dd.MM.yyyy'
            )} р. (далі – «Договір»), про нижчевикладене:`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: 'Кар’єрний супровід - сукупність дій Виконавця, спрямована на допомогу Замовнику у працевлаштуванні, що включає, але не обмежується рецензуванням резюме та супровідних листів, активним пошуком та наданням Замовнику інформації про наявні вакансії, консультуванням щодо типових питань співбесід та психологічної допомоги під час підготовки до співбесід тощо.',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: 'Всі визначені терміни, які використовуються у цьому Додатку, мають значення, присвоєні їм у Договорі, якщо інше прямо не передбачено у цьому Додатку.',
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `1. Сторони погодились, що після успішного завершення Замовником програми занять ${contract?.program?.name}, за Договором, Виконавець надає послуги кар’єрного супроводу згідно отриманих Замовником за Договором знань, умінь і навичок з метою забезпечення працевлаштування Замовника згідно пропозицій на ринку праці, але виключно у тому випадку, якщо Замовником виконана сукупність наступних умов:`,
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        indent: {
          firstLine: 150,
          left: 100,
        },
        children: [
          new TextRun({
            text: `-  Замовник отримав у підсумку не менше ніж 80 (вісімдесят) балів із 100 (ста) можливих, передбачених системою оцінювання прогресу осіб, що проходять програму занять ${contract?.program?.name}`,
          }),
        ],
      }),
      /** --- POINT 2--- */
      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.JUSTIFIED,
        children: [new TextRun({ text: APPENDIX_THREE_POINT_TWO.title })],
      }),
      ...APPENDIX_THREE_POINT_TWO.subPoints
        .map((paragraph) => {
          const arr = [];

          arr.push(createParagraphWithIndent(paragraph));
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),

      /** --- POINT 3--- */
      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.JUSTIFIED,
        children: [new TextRun({ text: APPENDIX_THREE_POINT_THREE.title })],
      }),
      ...APPENDIX_THREE_POINT_THREE.subPoints
        .map((paragraph) => {
          const arr = [];

          arr.push(createParagraphWithIndent(paragraph));
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),
      /** --- POINT 4--- */
      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.JUSTIFIED,
        children: [new TextRun({ text: APPENDIX_THREE_POINT_FOUR.title })],
      }),
      ...APPENDIX_THREE_POINT_FOUR.subPoints
        .map((paragraph) => {
          const arr = [];

          arr.push(createParagraph(paragraph));
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),
      /** --- POINT 5--- */
      new Paragraph({
        spacing: {
          before: 150,
        },
        alignment: AlignmentType.JUSTIFIED,
        children: [new TextRun({ text: APPENDIX_THREE_POINT_FIVE.title })],
      }),
      /** --- BANK DETAILS --- */
      new Paragraph({
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 200,
        },
        children: [
          new TextRun({
            text: `АДРЕСИ, РЕКВІЗИТИ ТА ПІДПИСИ СТОРІН:`,
            bold: true,
          }),
        ],
      }),
      createBankDetails(customer, contract.legalEntity, false),
    ],
  };
};
