import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { memo, useState } from 'react';
import { TabPanel, a11yProps } from 'utils/tabsFunc';
import LegalEntities from './legalEntities/LegalEntities';
import Educations from './educations/Educations';
import Professions from './professions/Professions';
import Universities from './universities/Universities';
import { useTranslation } from 'react-i18next';

const DictionariesPage = () => {
  const [tabsValue, setTabsValue] = useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <Stack direction="row" spacing={2} sx={{ height: '50vh' }}>
      <Stack
        sx={{
          backgroundColor: 'extra.white',
          borderRadius: 2,
          width: '25%',
        }}
      >
        <Typography textAlign="center" variant="h6" color="primary.main" p={3}>
          {t('types.settings.dictionaries.namePlural')}
        </Typography>
        <Tabs
          value={tabsValue}
          onChange={handleChange}
          orientation="vertical"
          variant="scrollable"
          aria-label="main data"
          sx={{ alignItems: 'flex-start', gap: 3 }}
        >
          <Tab
            sx={{
              alignItems: 'flex-start',
              borderRadius: 1,
              backgroundColor: 'transparent',
              '&.Mui-selected': { backgroundColor: 'transparent' },
            }}
            label={t('types.settings.dictionaries.listPlural.legalEntities')}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              alignItems: 'flex-start',
              backgroundColor: 'transparent',
              '&.Mui-selected': { backgroundColor: 'transparent' },
            }}
            label={t('types.settings.dictionaries.listPlural.educations')}
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              alignItems: 'flex-start',
              backgroundColor: 'transparent',
              '&.Mui-selected': { backgroundColor: 'transparent' },
            }}
            label={t('types.settings.dictionaries.listPlural.professions')}
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              alignItems: 'flex-start',
              backgroundColor: 'transparent',
              '&.Mui-selected': { backgroundColor: 'transparent' },
            }}
            label={t('types.settings.dictionaries.listPlural.universities')}
            {...a11yProps(3)}
          />
        </Tabs>
      </Stack>
      <Stack
        sx={{
          backgroundColor: 'extra.white',
          borderRadius: 2,
          width: '70%',
        }}
      >
        <TabPanel sx={{ height: '100%' }} value={tabsValue} index={0}>
          <LegalEntities />
        </TabPanel>
        <TabPanel sx={{ height: '100%' }} value={tabsValue} index={1}>
          <Educations />
        </TabPanel>
        <TabPanel sx={{ height: '100%' }} value={tabsValue} index={2}>
          <Professions />
        </TabPanel>
        <TabPanel sx={{ height: '100%' }} value={tabsValue} index={3}>
          <Universities />
        </TabPanel>
      </Stack>
    </Stack>
  );
};

export default memo(DictionariesPage);
