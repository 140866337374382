import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const accessSlice = createSlice({
  name: 'accessSlice',
  initialState: {
    list: loadDataInitialState([]),
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchAccess, 'list');
    loadDataExtraReducer(builder, thunks.createAccess, 'list');
  },
});

export const {} = accessSlice.actions;
export default accessSlice.reducer;
