import { Stack, Tab, Tabs, useTheme } from '@mui/material';
import { memo, useState } from 'react';
import { TabPanel, a11yProps } from 'utils/tabsFunc';
import AssignmentGradeCriteria from './AssignmentGradeCriteria';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './formik-quill-custom.css';
import { TabPanelsWrapper } from 'views/common/StyledComponents';
import { useTranslation } from 'react-i18next';

const AssignmentDetails = ({ assignmentLoading, assignment }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [tabsValue, setTabsValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden' }}>
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
        <Tab
          sx={{
            width: `calc(50% - ${theme.spacing(1)})`,
          }}
          label={t('base.labels.description')}
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            width: `calc(50% - ${theme.spacing(1)})`,
          }}
          label={t('types.assignment.gradeCriteria')}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanelsWrapper>
        <TabPanel value={tabsValue} index={0}>
          <ReactQuill theme="snow" value={assignment?.description} modules={{ toolbar: false }} readOnly={true} />
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <AssignmentGradeCriteria
            list={assignment.criteria}
            totalElements={assignment.criteria.length}
            isLoading={assignmentLoading}
            assignmentId={assignment.id}
            hideFooter
            isNested
          />
        </TabPanel>
      </TabPanelsWrapper>
    </Stack>
  );
};

export default memo(AssignmentDetails);
