const getTasks = () => (state) => state.task.list.data;
const tasksLoading = () => (state) => state.task.list.isLoading;
const getTasksError = () => (state) => state.task.list.error;

const getActiveTasks = () => (state) => state.task.active.data;
const activeTasksLoading = () => (state) => state.task.active.isLoading;
const getActiveTasksError = () => (state) => state.task.active.error;

const getTask = () => (state) => state.task.item.data;
const taskLoading = () => (state) => state.task.item.isLoading;
const getTaskError = () => (state) => state.task.item.error;


export default {
  getTasks,
  tasksLoading,
  getTasksError,
  getTask,
  taskLoading,
  getTaskError,
  getActiveTasks,
  activeTasksLoading,
  getActiveTasksError,
};
