import {
  Table,
  TableRow,
  TableCell,
  BorderStyle,
  Paragraph,
  WidthType,
  AlignmentType,
  TextRun,
  VerticalAlign,
} from 'docx';
import filters from 'filters';

export const createContractDate = (date) => {
  return new Table({
    columnWidths: [4505, 4505],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
              bottom: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
              left: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
              right: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
            },
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [new TextRun({ text: ' м.Київ' })],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
              bottom: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
              left: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
              right: {
                style: BorderStyle.NIL,
                size: 1,
                color: '#ffffFf',
              },
            },
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    text: `${filters.date(date, 'dd.MM.yyyy')}`,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
    ],
  });
};
