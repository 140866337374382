import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const sessionSlice = createSlice({
  name: 'sessionSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
  },
  reducers: {
    replaceSession(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
    clearItem(state) {
      state.item = loadDataInitialState(null);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchSessions, 'list');
    loadDataExtraReducer(builder, thunks.fetchSession, 'item');
    loadDataExtraReducer(builder, thunks.createSession, 'item');
    loadDataExtraReducer(builder, thunks.updateSession, 'item');
  },
});

export const { replaceSession, clearItem } = sessionSlice.actions;
export default sessionSlice.reducer;
