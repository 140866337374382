import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const assignmentSlice = createSlice({
  name: 'assignmentSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
  },
  reducers: {
    replaceAssignment(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchAssignments, 'list');
    loadDataExtraReducer(builder, thunks.fetchAssignment, 'item');
  },
});

export const { replaceAssignment } = assignmentSlice.actions;
export default assignmentSlice.reducer;
