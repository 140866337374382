import { DateTime } from 'luxon';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { reportActions, reportSelectors, reportThunks } from 'store/ducks/report';
import SessionsList from 'views/common/SessionsList';

const SessionsDurationReport = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessions = useSelector(reportSelectors.getSessionsDurationReport());
  const isLoading = useSelector(reportSelectors.sessionsDurationReportLoading()) && !sessions;

  const trainers = useSelector(dictionarySelectors.getTrainers());
  const [, setProps] = useOutletContext();

  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';
  const trainerQuery = useSelector(reportSelectors.getFilterByTrainer());
  const periodQuery = useSelector(reportSelectors.getFilterByPeriod());

  useEffect(() => {
    store.dispatch(reportActions.setQuery(query));
  }, [query]);

  /** --- Function for handling click for generating report in HeaderReports component --- */
  const handleGenerateReport = (query) => {
    store.dispatch(reportThunks.fetchSessionsWithFilters(`?${query}`));
    store.dispatch(reportThunks.fetchSessionsDuration(`?${query}`));
  };

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      sessionsDurationReport: true,
      handleGenerateReport: handleGenerateReport,
    }));

    return () => {
      setProps((prevProps) => ({
        ...prevProps,
        sessionsDurationReport: false,
        handleGenerateReport: null,
      }));
      store.dispatch(reportActions.addFilterByTrainer(null));
      store.dispatch(
        reportActions.addFilterByPeriod({
          from: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
          to: DateTime.local().endOf('month').toFormat('yyyy-MM-dd'),
        })
      );
      store.dispatch(reportActions.clearSessionsDuration());
      store.dispatch(reportActions.clearSessionReport());
    };
  }, [setProps, trainers]);

  return (
    <>
      <SessionsList
        list={sessions.content}
        isLoading={isLoading}
        totalElements={sessions.totalElements}
        totalPages={sessions.totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={''}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        trainerQuery={trainerQuery}
        periodQuery={periodQuery}
      />
    </>
  );
};

export default memo(SessionsDurationReport);
