import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Stack, Tab, Tabs, useTheme } from '@mui/material';

import store from 'store';
import { personSelectors, personThunks } from 'store/ducks/person';

import ContractsList from 'views/common/ContractsList';
import InvoicesList from 'views/common/InvoicesList';
import GroupsList from 'views/common/GroupsList';
import GradesList from 'views/common/GradesList';
import { TabPanelsWrapper } from 'views/common/StyledComponents';

import filters from 'filters';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { TabPanel, a11yProps } from '../../../../utils/tabsFunc';

/* --- This component displays the Tabs with invoices, grades, groups ---  */

const PersonDetails = ({ personId }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [tabsValue, setTabsValue] = useState(0);
  const contracts = useSelector(personSelectors.getPersonContracts());
  const contractsLoading = useSelector(personSelectors.personContractsLoading()) && !contracts;
  const invoices = useSelector(personSelectors.getPersonInvoices());
  const invoicesLoading = useSelector(personSelectors.personInvoicesLoading()) && !invoices;
  const groups = useSelector(personSelectors.getPersonGroups());
  const groupsLoading = useSelector(personSelectors.personGroupsLoading());
  const grades = useSelector(personSelectors.getPersonGrades());
  const gradesLoading = useSelector(personSelectors.personGradesLoading());

  const contractPermission = hasPermission(PERMISSIONS.contract.read);
  const invoicePermission = hasPermission(PERMISSIONS.invoice.read);
  const groupPermission = hasPermission(PERMISSIONS.group.read);
  const gradePermission = hasPermission(PERMISSIONS.grade.read);

  useEffect(() => {
    if (personId) {
      contractPermission && store.dispatch(personThunks.fetchPersonContracts(personId));
      invoicePermission && store.dispatch(personThunks.fetchPersonInvoices(personId));
      groupPermission && store.dispatch(personThunks.fetchPersonGroups(personId));
      gradePermission && store.dispatch(personThunks.fetchPersonGrades(personId));
    }
  }, [personId, contractPermission, invoicePermission, groupPermission, gradePermission]);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const groupsList =
    !groupsLoading &&
    groups.map((option) => {
      return { ...option.group, since: filters.date(option.createdAt) };
    });

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden' }}>
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
        <Tab
          sx={{ width: `calc(25% - ${theme.spacing(1)})` }}
          label={t('types.contract.namePlural')}
          {...a11yProps(0)}
          disabled={!contractPermission}
        />
        <Tab
          sx={{ width: `calc(25% - ${theme.spacing(2)})` }}
          label={t('types.invoice.namePlural')}
          {...a11yProps(1)}
          disabled={!invoicePermission}
        />
        <Tab
          sx={{ width: `calc(25% - ${theme.spacing(2)})` }}
          label={t('types.group.namePlural')}
          {...a11yProps(2)}
          disabled={!groupPermission}
        />
        <Tab
          sx={{ width: `calc(25% - ${theme.spacing(1)})` }}
          label={t('types.grade.namePlural')}
          {...a11yProps(3)}
          disabled={!gradePermission}
        />
      </Tabs>

      <TabPanelsWrapper>
        <TabPanel value={tabsValue} index={0}>
          {!contractsLoading && (
            <ContractsList
              list={contracts}
              totalElements={contracts.length}
              columnVisibilityModel={{
                contractor: false,
              }}
              isLoading={contractsLoading}
              hideFooter
              isNested
            />
          )}
        </TabPanel>

        <TabPanel value={tabsValue} index={1}>
          {!invoicesLoading && (
            <InvoicesList
              list={invoices}
              totalElements={invoices.length}
              isLoading={invoicesLoading}
              columnVisibilityModel={{
                contractor: false,
              }}
              hideFooter
              isNested
            />
          )}
        </TabPanel>

        <TabPanel value={tabsValue} index={2}>
          {!groupsLoading && (
            <GroupsList list={groupsList} totalElements={groups.length} isLoading={groupsLoading} hideFooter isNested />
          )}
        </TabPanel>
        <TabPanel value={tabsValue} index={3}>
          {!gradesLoading && (
            <GradesList
              list={grades}
              isLoading={gradesLoading}
              totalElements={grades.length}
              columnVisibilityModel={{
                student: false,
              }}
              hideFooter
              isNested
            />
          )}
        </TabPanel>
      </TabPanelsWrapper>
    </Stack>
  );
};

export default memo(PersonDetails);
