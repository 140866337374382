import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import store from 'store';
import { assignmentSelectors, assignmentThunks } from 'store/ducks/assignment';

import HeaderList from 'views/common/HeaderList';
import AssignmentsList from '../../common/AssignmentsList';
import { PageWrapper } from 'views/common/StyledComponents';
import Error from 'views/common/Error';

const AssignmentsPage = () => {
  const { t } = useTranslation();
  const assignments = useSelector(assignmentSelectors.getAssignments());
  const assignmentsLoading = useSelector(assignmentSelectors.assignmentsLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(assignmentThunks.fetchAssignments(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <HeaderList title={t('types.assignment.namePlural')} to="/assignments/new" isSearchbarShow />
      </ErrorBoundary>
      <AssignmentsList
        list={assignments.content}
        isLoading={assignmentsLoading}
        totalElements={assignments.totalElements}
        totalPages={assignments.totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
      />
    </PageWrapper>
  );
};

export default memo(AssignmentsPage);
