const getContacts = () => (state) => state.contact.list.data;
const contactsLoading = () => (state) => state.contact.list.isLoading;
const getContactsError = () => (state) => state.contact.list.error;

export default {
  getContacts,
  contactsLoading,
  getContactsError,

};
