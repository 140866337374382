import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const fetchNotifications = createAsyncThunk('notification/fetchNotifications', async (query) => {
  return api.get(`/notifications${query}`);
});

const fetchUnreadNotifications = createAsyncThunk('notification/fetchUnreadNotifications', async () => {
  return api.get(`/notifications/unread`);
});

const resetNotifications = createAsyncThunk('notification/resetNotifications', async () => {
  return api.post(`/notifications`);
});

export default {
  fetchNotifications,
  resetNotifications,
  fetchUnreadNotifications,
};
