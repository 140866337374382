import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Stack } from '@mui/material';

import store from 'store';
import { lessonThunks } from 'store/ducks/lesson';
import { sessionActions, sessionSelectors, sessionThunks } from 'store/ducks/session';

import Loader from 'views/common/Loader';
import Error from 'views/common/Error';
import SessionForm from './components/SessionForm';

import useModal from 'hooks/useModal';
import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';

const SessionCard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();
  const session = useSelector(sessionSelectors.getSession());
  const isLoading = useSelector(sessionSelectors.sessionLoading()) && !session;

  useEffect(() => {
    store.dispatch(sessionThunks.fetchSession(id));
  }, [id]);

  useEffect(() => {
    session?.lesson &&
      hasPermission(PERMISSIONS.assignment.read) &&
      store.dispatch(lessonThunks.fetchLessonAssignments(session.lesson?.id));
  }, [session?.lesson]);

  /** --- Update single session --- */
  const handleUpdateSession = async (values, setSubmitting) => {
    const res = store.dispatch(sessionThunks.updateSession(values));

    if (!res.error) {
      toast.success(t('messages.success.toast.updateSession'));
      setSubmitting(false);
      closeModal();
    }
  };

  /** --- Move Dates for future sessions --- */
  const handleUpdateBulkSessions = async (values, setSubmitting) => {
    const query = `?newDate=${encodeURIComponent(values?.date)}`;
    const sessionId = session?.id;
    const data = { sessionId: sessionId, query: query };

    const res = await store.dispatch(sessionThunks.moveBulkSessions(data));

    if (res.meta.requestStatus === 'fulfilled') {
      const sessionResp = await store.dispatch(sessionThunks.updateSession(values));
      if (!sessionResp.error) {
        toast.success(t('messages.success.toast.bulkUpdate'));
        closeModal();
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      store.dispatch(sessionActions.clearItem());
    };
  }, []);

  return (
    <Stack justifyContent="center">
      {isLoading && <Loader />}
      {!isLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <SessionForm
            session={session}
            modalOpen={modalOpen}
            closeModal={closeModal}
            openModal={openModal}
            handleUpdateSession={handleUpdateSession}
            handleUpdateBulkSessions={handleUpdateBulkSessions}
            t={t}
          />
        </ErrorBoundary>
      )}
    </Stack>
  );
};

export default memo(SessionCard);
