import { createTheme, darken, lighten } from '@mui/material/styles';

import { outlinedInputClasses } from '@mui/material';

export const theme = (colors, logo, logoShort) =>
  createTheme({
    palette: {
      primary: {
        main: colors.primaryBaseErp,
        lighter: lighten(colors.primaryBaseErp, 0.85),
        light: lighten(colors.primaryBaseErp, 0.45),
        contrastText: colors.white,
      },
      secondary: {
        main: colors.secondaryBaseErp,
        lightest: lighten(colors.secondaryBaseErp, 0.95),
        light: lighten(colors.successBase, 0.85),
        contrastText: colors.white,
      },
      extra: {
        changed: colors.changed,
        white: colors.white,
        grey: colors.grey,
        gradient: `linear-gradient(100.95deg, ${colors.primaryBaseErp} 15.41%, ${colors.secondaryBaseErp} 100%)`,
      },
      background: {
        default: lighten(colors.secondaryBaseErp, 0.85),
        paper: colors.white,
      },
      text: {
        main: colors.textBase,
        light: lighten(colors.textBase, 0.5),
        dark: darken(colors.textBase, 0.6),
      },
      info: {
        main: colors.infoBase,
      },
      warning: {
        main: colors.warningBase,
      },
      success: {
        main: colors.successBase,
        light: lighten(colors.successBase, 0.7),
      },
      error: {
        main: colors.errorBase,
        light: lighten(colors.errorBase, 0.6),
        dark: darken(colors.errorBase, 0.15),
      },
      divider: lighten(colors.primaryBaseErp, 0.85),
    },
    typography: {
      allVariants: {
        textTransform: 'none',
      },
      fontFamily: ['Manrope', 'sans-serif'].join(','),

      h1: {
        fontSize: 30,
        fontWeight: 700,
      },
      h2: {
        fontSize: 28,
        fontWeight: 700,
      },
      h3: {
        fontSize: 26,
        fontWeight: 700,
      },
      h4: {
        fontSize: 22,
        fontWeight: 700,
      },
      h5: {
        fontSize: 20,
        fontWeight: 700,
      },
      h6: {
        fontSize: 18,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 20,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 18,
        fontWeight: 500,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },

      capture: {
        fontSize: 12,
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 8,
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*::-webkit-scrollbar': {
            width: '6px',
          },
          '*::-webkit-scrollbar:horizontal': {
            height: '6px',
          },
          '*::-webkit-scrollbar-track': {
            background: 'transparent',
            borderRadius: 20,
          },
          '*::-webkit-scrollbar-thumb': {
            background: lighten(colors.secondaryBaseErp, 0.6),
            borderRadius: 20,
            transition: 'background 0.5s ease',
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: colors.secondaryBaseErp,
            borderRadius: 20,
            transition: 'background 0.5s ease',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            fontSize: 16,
            fontWeight: 500,
            boxShadow: 'none',
            minHeight: '44px',
            minWidth: '150px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: darken(colors.textBase, 0.6),
            paddingLeft: 16,
            paddingRight: 16,
            '&:hover': {
              backgroundColor: 'transparent',
              color: colors.primaryBaseErp,
              transition: 'color 0.3s',
            },
            '&.Mui-selected': {
              backgroundColor: lighten(colors.primaryBaseErp, 0.85),
              borderRadius: 8,
              transition: 'background-color 0.3s, color 0.3s',
              '&:hover': { color: colors.primaryBaseErp, backgroundColor: lighten(colors.primaryBaseErp, 0.85) },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            '&.MuiDrawer-paper': {
              background: `linear-gradient(100.95deg, ${colors.primaryBaseErp} 15.41%, ${colors.secondaryBaseErp} 100%)`,
              borderRadius: '0 16px 16px 0',
              border: 'none',
              padding: '16px 8px 16px 8px',
            },
          },
        },
      },

      MuiAutocomplete: {
        styleOverrides: {
          option: {
            backgroundColor: colors.white,
            borderRadius: 4,
            margin: '4px 8px',
            color: darken(colors.textBase, 0.6),
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: 10,
            fontWeight: 400,
            position: 'absolute',
            top: 44,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: lighten(colors.textBase, 0.5),
            '&.MuiInputLabel-root': {
              top: 4,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: colors.textBase,
            '&::before': {
              borderBottom: `1px solid ${lighten(colors.primaryBaseErp, 0.85)}`,
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: `1px solid ${lighten(colors.primaryBaseErp, 0.45)}`,
            },
            '&.Mui-focused:after': {
              borderBottom: `1px solid ${colors.primaryBaseErp}`,
            },
            '& input::placeholder': {
              color: lighten(colors.primaryBaseErp, 0.45),
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: lighten(colors.primaryBaseErp, 0.85),
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: lighten(colors.primaryBaseErp, 0.45),
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${colors.primaryBaseErp}`,
            },
            '& input::placeholder': {
              color: lighten(colors.primaryBaseErp, 0.45),
            },
            '& input': {
              padding: '10px 0 10px 16px',
            },
          },
        },
      },

      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontSize: 16,
            color: colors.textBase,
            border: 'none',
          },
          columnHeader: {
            '&:focus-within': {
              outline: 'none',
            },
          },
          columnHeaders: {
            borderRadius: 8,
            backgroundColor: lighten(colors.successBase, 0.85),
            borderBottom: 'none',
            color: darken(colors.textBase, 0.6),
            fontWeight: 600,
          },
          row: {
            borderRadius: 8,
            alignItems: 'center',
            '&.Mui-odd': {
              backgroundColor: lighten(colors.secondaryBaseErp, 0.95),
            },
          },
          cell: {
            borderBottom: 'none',
            lineHeight: 2,
          },
          footerContainer: {
            borderTop: 'none',
            borderRadius: 8,
            justifyContent: 'center',
            backgroundColor: lighten(colors.successBase, 0.85),
            minHeight: 48,
          },
          checkboxInput: {
            color: lighten(colors.primaryBaseErp, 0.85),
          },
          virtualScroller: {
            minHeight: 24,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: lighten(colors.textBase, 0.5),
          },
          select: {
            color: colors.primaryBaseErp,
          },
          selectIcon: {
            color: colors.primaryBaseErp,
          },
          actions: {
            color: colors.primaryBaseErp,
          },
          toolbar: {
            minHeight: 44,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            '&.MuiTablePagination-toolbar': {
              minHeight: 48,
            },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(colors.secondaryBaseErp, 0.95),
            fontSize: 16,
            maxWidth: '100%',
            padding: '4px 16px',
            borderRadius: '16px 16px 0px 0px',
            '&.Mui-selected': {
              backgroundColor: colors.white,
              fontWeight: 700,
            },
            '&:hover': {
              backgroundColor: colors.white,
              fontWeight: 700,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: 'transparent',
          },
          flexContainer: {
            gap: 16,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 20,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: 20,
            fontWeight: 500,
            letterSpacing: 0,
            padding: 0,
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: colors.primaryBaseErp,
            maxHeight: 300,
          },
          '&.MuiButtonBase-root': {
            color: colors.primaryBaseErp,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: colors.primaryBaseErp,
            fontWeight: 500,
            fontSize: 14,
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            color: darken(colors.textBase, 0.6),
            fontWeight: 600,
            fontSize: 14,
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: colors.primaryBaseErp,
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontSize: 16,
          },

          switchViewIcon: {
            color: colors.primaryBaseErp,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: darken(colors.textBase, 0.6),
            border: 'none',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '0 16px',
            fontSize: 14,
            color: colors.textBase,
          },
          head: {
            padding: '8px 16px',
            color: darken(colors.textBase, 0.6),
            border: 'none',
            backgroundColor: lighten(colors.successBase, 0.85),
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: darken(colors.textBase, 0.6),
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: 'rounded',
      },
      styleOverrides: {
        root: {
          backgroundColor: lighten(colors.primaryBaseErp, 0.45),
          border: 'none',
        },
      },
    },
    logo,
    logoShort,
  });
