import { Button, Stack, useTheme } from '@mui/material';
import { memo } from 'react';
import ReportsMenu from './ReportsMenu';
import FilterByPerson from './FilterByPerson';
import FilterByPeriod from './FilterByPeriod';
import { useSelector } from 'react-redux';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { reportActions, reportSelectors } from 'store/ducks/report';
import FilterComplex from './FilterComplex';
import TotalSessionsDuration from './TotalSessionsDuration';
import { tableSelectors } from 'store/ducks/table';
import { exportToExcel } from 'utils/helpers';
import { ReactComponent as ExportIcon } from '../../../../theme/icons/exportIcon.svg';
import { useTranslation } from 'react-i18next';

const HeaderReports = ({ overdueInvoicesReport, sessionsDurationReport, invoicesForPeriod, handleGenerateReport }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const manager = useSelector(reportSelectors.getFilterByManager());
  const managers = useSelector(dictionarySelectors.getManagers());
  const trainer = useSelector(reportSelectors.getFilterByTrainer());
  const trainers = useSelector(dictionarySelectors.getTrainers());
  const query = useSelector(reportSelectors.getQuery());
  const sessionsDuration = useSelector(reportSelectors.getSessionsDuration());

  const selectedData = useSelector(tableSelectors.getSelected());

  const handleExport = () => {
    exportToExcel(selectedData);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      px={2}
      bgcolor={'extra.white'}
      sx={{ borderBottomLeftRadius: theme.spacing(2), borderBottomRightRadius: theme.spacing(2) }}
    >
      <ReportsMenu />

      <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
        {overdueInvoicesReport && (
          <FilterByPerson options={managers} person={manager} action={reportActions.addFilterByManager} />
        )}
        {sessionsDurationReport && (
          <>
            {sessionsDuration.data && <TotalSessionsDuration sessionsDuration={sessionsDuration.data} />}
            <FilterComplex
              person={trainer}
              options={trainers}
              action={reportActions.addFilterByTrainer}
              handleGenerateReport={handleGenerateReport}
              query={query}
            />
          </>
        )}
        {invoicesForPeriod && <FilterByPeriod />}
        <Button
          disabled={selectedData?.length === 0 ? true : false}
          startIcon={
            <ExportIcon
              fill={selectedData?.length === 0 ? theme.palette.action.disabled : theme.palette.primary.main}
            />
          }
          onClick={handleExport}
        >
          {t('base.buttons.export')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default memo(HeaderReports);
