import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const fetchLogs = createAsyncThunk('log/fetchLogs', async (query) => {
  return api.get(`/logs${query}`);
});

export default {
  fetchLogs,
};
