import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const taskSlice = createSlice({
  name: 'taskSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
    report: loadDataPaginatedState(),
    active: loadDataInitialState([]),
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.content.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    updateItem(state, action) {
      state[action.payload.collection].data = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchTasks, 'list');
    loadDataExtraReducer(builder, thunks.fetchActiveTasks, 'active');
    loadDataExtraReducer(builder, thunks.fetchTask, 'item');
    loadDataExtraReducer(builder, thunks.createTask, 'item');
    loadDataExtraReducer(builder, thunks.updateTask, 'item');
  },
});

export const { addItem, replaceItem, updateItem, deleteItem } = taskSlice.actions;
export default taskSlice.reducer;
