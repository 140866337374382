import { debounce } from '@mui/material/utils';
import { useEffect, useRef, useState } from 'react';
import store from 'store';

/**
 * A custom hook that provides search functionality for Autocomplete components.
 * It is intended to be used when creating a new entity card to search for related data options.
 *
 * @param {object} entity - The entity object that is used to determine whether the search should be triggered.
 * If `entity` is not provided (null or undefined), the search will be triggered when the `searchValue` changes.
 * @param {string} valueSearchEntity - The value to search for in the Autocomplete component.
 * This value is typically derived from the current state of the form field that uses the Autocomplete.
 * @param {function} fetchThunk - The Redux thunk function that fetches the data from the server based on the search value.
 * This function should handle the asynchronous fetching of the data and update the state accordingly.
 *
 * @returns {array} An array containing the setSearchValue function to set the search value manually.
 * You can use this function to trigger the search programmatically based on custom conditions.
 */

export function useAutocompleteSearch (entity, valueSearchEntity, fetchThunk, clearStoreAction) {
  const [searchValue, setSearchValue] = useState('');

  /** Create a ref to hold the debounced function  */
  const debouncedFetchRef = useRef();

  useEffect(() => {
    /** Define the debounced function inside the useEffect to have access to the latest fetchThunk and searchValue. */

    debouncedFetchRef.current = debounce((value) => store.dispatch(fetchThunk(`?q=${value}`)), 400);

    /** Cleanup the debounced function on unmount  */
    return () => {
      debouncedFetchRef.current.clear();
    };
  }, [fetchThunk, clearStoreAction]);

  useEffect(() => {
    if (searchValue && searchValue !== valueSearchEntity) {
      debouncedFetchRef?.current(searchValue);
    }
  }, [searchValue]);

  return [setSearchValue];
}
