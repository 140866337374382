import { Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { STATUSES_COLOR } from 'utils/constants/constants';
import DataTable from 'views/common/DataTable';
import { DataTableWrapper } from 'views/common/StyledComponents';
import CopyInputAdornment from 'views/form/CopyInputAdornment';

const AssignmentsList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      flex: 0.2,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/assignments/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    {
      field: 'program',
      headerName: t('base.labels.program'),
      filterable: false,
      flex: 0.5,
      renderCell: ({ row: { program } }) => {
        return <Typography>{program.name}</Typography>;
      },
    },
    { field: 'number', headerName: t('base.labels.number'), filterable: false, flex: 0.15 },
    { field: 'name', headerName: t('base.labels.name'), filterable: false, flex: 0.4 },
    {
      field: 'type',
      headerName: t('base.labels.type'),
      filterable: false,
      flex: 0.4,
      renderCell: ({ row: { type } }) => {
        return <Typography>{type.label}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      flex: 0.3,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return <Typography color={statusColor}>{status.label}</Typography>;
      },
    },
    {
      field: 'documents',
      headerName: t('base.labels.documents'),
      filterable: false,
      flex: 0.3,
      renderCell: ({ row: { documents } }) => {
        return (
          <Stack justifyContent="space-between" direction="row" spacing={2}>
            {documents.map((doc) => {
              return (
                <Typography key={doc} color={'info.main'}>
                  {doc}{' '}
                </Typography>
              );
            })}
          </Stack>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      flex: 0.3,
      renderCell: (params) => {
        return <CopyInputAdornment value={params.row.documents} tooltipTitle="Copy docs link" />;
      },
    },
  ];
  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)', overflowY: 'auto' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
      />
    </DataTableWrapper>
  );
};

export default memo(AssignmentsList);
