import { AlignmentType, Paragraph, SectionType, TextRun } from 'docx';
import { DateTime } from 'luxon';

export const createAppendixHeader = (appexNumber, contract) => {
  return {
    properties: {
      type: SectionType.NEXT_PAGE,
    },
    children: [
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [new TextRun({ text: appexNumber, bold: true })],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [new TextRun({ text: `до Договору № ${contract?.number} `, bold: true })],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        spacing: {
          after: 50,
        },
        children: [
          new TextRun({
            text: `про надання послуг від  ${DateTime.fromISO(contract?.date).toFormat('dd.MM.yyyy')} `,
            bold: true,
          }),
        ],
      }),
    ],
  };
};
