import { memo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import InvoiceForm from './components/InvoiceForm';
import Error from 'views/common/Error';

const NewInvoice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const cloneData = location.state;

  return (
    <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
      <InvoiceForm navigate={navigate} cloneData={cloneData} t={t} />
    </ErrorBoundary>
  );
};

export default memo(NewInvoice);
