import { memo } from 'react';
import { withFormik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button, Stack } from '@mui/material';

import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { programThunks } from 'store/ducks/program';

import { FormColumnWrapper, FormWrapper } from 'views/common/StyledComponents';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';

const ProgramModulesForm = ({ module, closeModal }) => {
  const { t } = useTranslation();

  const enums = useSelector(dictionarySelectors.getEnums());

  return (
    <Form id="moduleForm">
      <FormWrapper>
        <FormColumnWrapper sx={{ minWidth: 350, backgroundColor: 'transparent', m: 2 }}>
          <FormikTextField name="number" label={t('base.labels.number')} placeholder={t('base.placeholders.number')} />
          <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
          <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.activeStatuses} />
          <Stack direction="row" alignItems="center" gap={2} justifyContent="center" mt={3}>
            <Button variant="outlined" onClick={closeModal} sx={{ maxWidth: 150 }}>
              {t('base.buttons.cancel')}
            </Button>
            <Button type="submit" variant="contained" sx={{ maxWidth: 150 }}>
              {module ? t('base.buttons.change') : t('base.buttons.create')}
            </Button>
          </Stack>
        </FormColumnWrapper>
      </FormWrapper>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ module = {} }) => ({
      ...module,
      id: module?.id,
      number: module?.number || '',
      name: module?.name || '',
      status: module?.status || null,
    }),

    handleSubmit: async (values, { props, setSubmitting }) => {
      const programModule = { module: values, programId: props.programId };

      const res = props.module
        ? store.dispatch(programThunks.updateProgramModule(programModule))
        : store.dispatch(programThunks.createProgramModule(programModule));

      if (!res.error) {
        toast.success(
          props.module ? props.t('messages.success.toast.updateModule') : props.t('messages.success.toast.createModule')
        );

        setSubmitting(false);
        props.closeModal();
      }
    },
    enableReinitialize: true,
  })(ProgramModulesForm)
);
