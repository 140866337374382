import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Account from './components/Account';
import PersonalInformation from './components/PersonalInformation';
import LoginDetails from './components/LoginDetails';
import General from './components/General';
import { FormColumnTitle, FormColumnWrapper, PageWrapper } from 'views/common/StyledComponents';

const ProfilePage = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper sx={{ height: 'calc(100dvh - 79px)' }}>
      <Stack direction="row" gap={2} mt={2} width="100%" minHeight="360px" maxHeight="424px">
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.account')}</FormColumnTitle>
          <Account />
        </FormColumnWrapper>
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.personalInfo')}</FormColumnTitle>
          <PersonalInformation />
        </FormColumnWrapper>
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.login')}</FormColumnTitle>
          <LoginDetails />
        </FormColumnWrapper>
        <FormColumnWrapper sx={{ width: '25%' }}>
          <FormColumnTitle>{t('types.settings.profile.general')}</FormColumnTitle>
          <General />
        </FormColumnWrapper>
      </Stack>
    </PageWrapper>
  );
};

export default memo(ProfilePage);
