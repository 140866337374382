import {
  SettingsOutlined,
  CastForEducationOutlined,
  MenuBookOutlined,
  HistoryEduOutlined,
  TextIncreaseOutlined,
  HomeWorkOutlined,
  RequestQuoteOutlined,
  QueryStatsOutlined,
  PlaylistAddCheckOutlined,
  NoteAltOutlined,
  GroupsOutlined,
  PersonOutlineOutlined,
} from '@mui/icons-material';
import { ALLOWED_PERMISSION, PERMISSIONS } from './permissions';

export const SIDEBAR_MENU = [
  {
    /* --- always available for reading */
    key: 'tasks',
    permission: ALLOWED_PERMISSION,
    to: '/tasks',
    icon: <PlaylistAddCheckOutlined />,
  },
  { key: 'persons', permission: PERMISSIONS.person.read, to: '/persons', icon: <PersonOutlineOutlined /> },
  { key: 'contracts', permission: PERMISSIONS.contract.read, to: '/contracts', icon: <NoteAltOutlined /> },
  { key: 'invoices', permission: PERMISSIONS.invoice.read, to: '/invoices', icon: <RequestQuoteOutlined /> },
  { key: 'groups', permission: PERMISSIONS.group.read, to: '/groups', icon: <GroupsOutlined /> },
  { key: 'sessions', permission: PERMISSIONS.session.read, to: '/sessions', icon: <HistoryEduOutlined /> },
  { key: 'grades', permission: PERMISSIONS.grade.read, to: '/grades', icon: <TextIncreaseOutlined /> },
  { key: 'programs', permission: PERMISSIONS.program.read, to: '/programs', icon: <MenuBookOutlined /> },
  { key: 'lessons', permission: PERMISSIONS.lesson.read, to: '/lessons', icon: <CastForEducationOutlined /> },
  { key: 'assignments', permission: PERMISSIONS.assignment.read, to: '/assignments', icon: <HomeWorkOutlined /> },
  {
    /* --- always available for reading */
    key: 'reports',
    permission: ALLOWED_PERMISSION,
    to: '/reports',
    icon: <QueryStatsOutlined />,
  },
  { key: 'settings', permission: ALLOWED_PERMISSION, to: '/settings', icon: <SettingsOutlined /> },
];
