import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

const uploadImage = createAsyncThunk('upload/uploadImage', (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post('/uploads/image', formData, {
    'Content-Type': 'multipart/form-data',
  });
});

const uploadFile = createAsyncThunk('uploads/files', (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post('/files', formData, {
    'Content-Type': 'multipart/form-data',
  });
});

export default {
  uploadImage,
  uploadFile,
};
