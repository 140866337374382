import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import store from 'store';
import { invoiceSelectors, invoiceThunks } from 'store/ducks/invoice';

import Loader from 'views/common/Loader';
import Error from 'views/common/Error';
import InvoiceForm from './components/InvoiceForm';

import useModal from 'hooks/useModal';

const InvoiceCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();
  const invoice = useSelector(invoiceSelectors.getInvoice());
  const isLoading = useSelector(invoiceSelectors.invoiceLoading()) && !invoice;

  const [cloneData, setCloneData] = useState({});

  useEffect(() => {
    store.dispatch(invoiceThunks.fetchInvoice(id));
  }, [id]);

  useEffect(() => {
    invoice &&
      setCloneData({ amountDue: invoice.amountDue, contract: invoice.contract, paymentMethod: invoice.paymentMethod });
  }, [invoice]);

  const handleClone = () => {
    navigate('/invoices/new', { state: cloneData });
  };

  return (
    <Stack justifyContent="center">
      {isLoading && <Loader />}
      {!isLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <InvoiceForm
            invoice={invoice}
            modalOpen={modalOpen}
            closeModal={closeModal}
            openModal={openModal}
            cloneBtn={t('types.invoice.name')}
            handleClone={handleClone}
            t={t}
          />
        </ErrorBoundary>
      )}
    </Stack>
  );
};

export default memo(InvoiceCard);
