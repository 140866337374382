import { memo, useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import store from 'store';
import { personSelectors, personThunks } from 'store/ducks/person';

import Error from 'views/common/Error';
import HeaderList from 'views/common/HeaderList';
import PersonsList from 'views/common/PersonsList';
import { PageWrapper } from 'views/common/StyledComponents';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const PersonsPage = () => {
  const { t } = useTranslation();
  const persons = useSelector(personSelectors.getPersons());
  const personsLoading = useSelector(personSelectors.personsLoading());
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(personThunks.fetchPersons(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <HeaderList
          title={t('types.person.namePlural')}
          to="/persons/new"
          buttonTitle={'+ ' + t('base.buttons.createPerson')}
          isSearchbarShow
          hasPermission={hasPermission(PERMISSIONS.person.create)}
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <PersonsList
          list={persons.content}
          isLoading={personsLoading}
          totalElements={persons.totalElements}
          totalPages={persons.totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
          columnVisibilityModel={{
            from: false,
            to: false,
          }}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(PersonsPage);
