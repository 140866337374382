import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
// import { replaceAssignment } from './slice';

const fetchAssignments = createAsyncThunk('assignment/fetchAssignments', async (data) => {
  return api.get(`/assignments${data}`);
});
const fetchAssignment = createAsyncThunk('assignment/fetchAssignment', async (id) => {
  return api.get(`/assignments/${id}`);
});

export default {
  fetchAssignments,
  fetchAssignment,
};
