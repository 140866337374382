import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from 'store/utils/loadDataPaginatedState';

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: {
    list: loadDataPaginatedState(),
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchNotifications, 'list');
  },
});

export const {} = notificationSlice.actions;
export default notificationSlice.reducer;
